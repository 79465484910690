const personDataFormInitialValues = {
  surname: '',
  name: '',
  patronymic: '',
  RNOKPP: '',
  sex: '',
  birthday: null,
  dateOfIssue: null,
  mobilePhone: '',
  workPhoneNumber: '',
  place: {
    city: null,
    street: null,
  },
  street: '',
  house: '',
  apartments: '',
  selectedPassport: 'ID_CARD',
  idCardNumber: '',
  series: '',
  passportSeries: '',
  passportNumber: '',
  authorityCode: '',
  citizenship: '',
  position: '',
  startWorkDate: null,
  resours: '',
  methodInvite: '',
  noCode: false,
};

const resourceOptions = [
  { value: 'website', label: 'Web-сайти' },
  { value: 'messanger', label: 'Месенджери' },
  { value: 'offline', label: 'Офлайн' },
];

const methodInviteOptionsMap = {
  website: [
    { value: 'work.ua', label: 'work.ua' },
    { value: 'robota.ua', label: 'robota.ua' },
    { value: 'jooble', label: 'Jooble' },
    { value: 'olx', label: 'OLX' },
    { value: 'PidBir', label: 'PidBir' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
  ],
  messanger: [
    { value: 'Telegram', label: 'Telegram групи' },
    { value: 'Viber', label: 'Viber групи' },
  ],
  offline: [
    { value: 'Оголошення в магазині', label: 'Оголошення в магазині' },
    { value: 'Чек-оголошення', label: 'Чек-оголошення' },
    { value: 'Рекомендації', label: 'Рекомендації' },
    {
      value: 'Дзвінок менеджера з персоналу',
      label: 'Дзвінок менеджера з персоналу',
    },
    { value: 'Таргетована реклама', label: 'Таргетована реклама' },
    {
      value: 'Аудіо-оголошення в магазині',
      label: 'Аудіо-оголошення в магазині',
    },
    {
      value: 'Агенція з працевлаштування',
      label: 'Агенція з працевлаштування',
    },
    {
      value: 'Заклади навчання (технікум, інститут, тощо)',
      label: 'Заклади навчання (технікум, інститут, тощо)',
    },
    {
      value: 'Оголошення на вулиці (паперові)',
      label: 'Оголошення на вулиці (паперові)',
    },
    { value: 'Флаєри', label: 'Флаєри' },
    { value: 'Центр зайнятості', label: 'Центр зайнятості' },
  ],
};

export { personDataFormInitialValues, resourceOptions, methodInviteOptionsMap };
