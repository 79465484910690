import { ADD_WORKER_URL } from '../../../constants/urls';
import { getCookie } from '../../../utilits/cookies';
import loadEmployers from './loadEmployers';

const addEmployer =
  (body, alert, loading, close, isRent = true, isLessee = false) =>
  async dispatch => {
    const token = getCookie('token');
    loading(true);
    try {
      const response = await fetch(ADD_WORKER_URL, {
        body: JSON.stringify({ ...body, isRent: isRent, isLessee: isLessee }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      });
      const data = await response.json();
      if (response.ok) {
        if (data.message === 'Работодатель добавлен' || data.message === 'Пользователь восстановлен') {
          !isLessee && alert(data.message, 'success');
          dispatch(loadEmployers(alert));
          if (!isRent) {
            close();
          }
          return data;
        } else {
          if (!isRent) {
            alert(data.message, 'error');
            dispatch(loadEmployers(alert));
          }
          return data;
        }
      } else {
        throw Error(data.message || 'Щось пішло не так');
      }
    } catch (e) {
      alert(e.message, 'error');
    } finally {
      loading(false);
    }
  };

export default addEmployer;
