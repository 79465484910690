import instanceController from "../../instanceController";
import showAlert from "../alerts/showAlert";
import {GET_USER_AND_UPDATE_FINGER} from "../../../constants/urls";

const getUserAndUpdateFinger = (serial, objectId) => async (dispatch) => {
  try {
    const response = await instanceController.post(GET_USER_AND_UPDATE_FINGER, {objectId, serial: serial})
    return response.data
  } catch (e) {
    if (e.response) {
      dispatch(showAlert(e.response.data.message, 'error'))
    }
  }
}

export default getUserAndUpdateFinger
