import { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/uk';

dayjs.extend(customParseFormat);
dayjs.locale('uk');

const EnhancedDatePicker = ({
  id,
  label,
  placeholder = 'ДД.ММ.РРРР',
  disableFuture = false,
  disablePast = false,
  width = '',
  formik,
  disabled = false,
  readOnly = false,
}) => {
  const { values, setFieldValue, errors, touched } = formik;
  const [localInputValue, setLocalInputValue] = useState(values[id] || '');

  const formatBirthday = (birthdayStr) => {
    if (!birthdayStr) return '';
    const possibleFormats = [
      'D M YYYY',
      'DD MM YYYY',
      'D-M-YYYY',
      'DD-MM-YYYY',
      'D.M.YYYY',
      'DD.MM.YYYY',
    ];
    const m = dayjs(birthdayStr, possibleFormats, true);
    return m.isValid() ? m.format('DD.MM.YYYY') : birthdayStr;
  };

  useEffect(() => {
    const currentBirthday = values[id];
    if (currentBirthday && !/^\d{2}\.\d{2}\.\d{4}$/.test(currentBirthday)) {
      const formatted = formatBirthday(currentBirthday);
      if (formatted !== currentBirthday) {
        setFieldValue(id, formatted);
      }
    }
  }, [id, setFieldValue, values]);

  useEffect(() => {
    if (values[id] !== localInputValue) {
      setLocalInputValue(values[id] || '');
    }
  }, [values[id]]);

  const parseDate = (str) => dayjs(str, 'DD.MM.YYYY', true);
  const isValidDateString = (str) => parseDate(str).isValid();
  const formatDate = (str) =>
    isValidDateString(str) ? parseDate(str).format('DD.MM.YYYY') : str;

  const datePickerValue = isValidDateString(values[id])
    ? parseDate(values[id])
    : null;

  const handleDateChange = (newDate) => {
    if (newDate && newDate.isValid()) {
      const formattedDate = newDate.format('DD.MM.YYYY');
      setFieldValue(id, formattedDate);
      setLocalInputValue(formattedDate);
    }
  };

  const handleInputChange = (e) => {
    setLocalInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (localInputValue.trim() === '') {
      setFieldValue(id, '');
    } else if (isValidDateString(localInputValue)) {
      const formatted = formatDate(localInputValue);
      setFieldValue(id, formatted);
      setLocalInputValue(formatted);
    }
  };

  const valueForWarning = values[id]
    ? isValidDateString(values[id])
      ? parseDate(values[id])
      : null
    : null;
  const getAge = (birthDate) => dayjs().diff(birthDate, 'year');
  const isBirthday = id === 'birthday';
  const age = valueForWarning && isBirthday ? getAge(valueForWarning) : null;
  const isAgeWarning = isBirthday && age !== null && (age < 18 || age > 65);
  let warningMessage = '';
  if (isAgeWarning) {
    warningMessage = age < 18 ? 'Вік менше 18 р.' : 'Вік більше 65 р.';
  }

  const isError = touched[id] && Boolean(errors[id] || errors[id] === '');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="uk"
    >
      <Box sx={{ width: width || '100%', maxWidth: '300px' }}>
        <DatePicker
          value={datePickerValue}
          onChange={handleDateChange}
          disabled={disabled}
          readOnly={readOnly}
          views={['year', 'month', 'day']}
          disableFuture={disableFuture}
          disablePast={disablePast}
          inputFormat="DD.MM.YYYY"
          slotProps={{
            textField: {
              id,
              color: isAgeWarning ? 'warning' : isError ? 'error' : 'success',
              size: 'small',
              label,
              placeholder,
              error: isError,
              helperText: isError ? (
                errors[id]
              ) : warningMessage ? (
                <Typography
                  variant="caption"
                  sx={{ color: 'warning.main' }}
                >
                  {warningMessage}
                </Typography>
              ) : (
                ' '
              ),
              sx: {
                width: width || '100%',
                '& .MuiInputLabel-root': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: !values[id] ? 'calc(100% - 40px)' : '100%',
                  '&.Mui-focused': { maxWidth: '100%' },
                },
                '& .MuiOutlinedInput-root': {
                  borderColor: isError
                    ? '#f44336'
                    : isAgeWarning
                    ? '#ffcc00'
                    : '',
                },
                '& input': {
                  color: isError
                    ? 'error'
                    : isAgeWarning
                    ? 'warning'
                    : 'inherit',
                },
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={localInputValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default EnhancedDatePicker;
