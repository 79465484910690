import { SET_CURRENT_WORKERS, REMOVE_CURRENT_WORKERS } from '../types';

const initialState = {
  currentWorkers: {},
};

export const currentWorkersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_WORKERS:
      return { ...action.payload };
    case REMOVE_CURRENT_WORKERS:
      return {};

    default:
      return state;
  }
};
