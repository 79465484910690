import * as Yup from 'yup';
import dayjs from 'dayjs';

const rnokppRegex = /^\d{10}$/;
const letterWithSymbolsRegex = /^[A-Za-zА-Яа-яІіЇїЄєҐґ'’\-–—]*$/;
const ukPhoneRegex = /^\+380\d{9}$/;
const houseAndApartmentRegex =
  /^[A-Za-zА-Яа-яІіЇїЄєҐґ0-9][A-Za-zА-Яа-яІіЇїЄєҐґ0-9\s\-]*$/;

const PositionsMap = {
  'Касир торгівельного залу': true,
  'Касир торговельного залу': true,
};

const isMatchingPosition = (position) =>
  Object.keys(PositionsMap).some((key) => position?.includes(key));

export const validationSchema = Yup.object().shape({
  surname: Yup.string()
    .test('starts-with-letter', 'Повинно починатися з літери', (value) => {
      if (!value) return true;
      return /^[A-Za-zА-Яа-яІіЇїЄєҐґ]/.test(value);
    })
    .matches(letterWithSymbolsRegex, 'Лише літери, дефіси, апострофи й тире')
    .required('Вкажіть прізвище'),
  name: Yup.string()
    .test('starts-with-letter', 'Повинно починатися з літери', (value) => {
      if (!value) return true;
      return /^[A-Za-zА-Яа-яІіЇїЄєҐґ]/.test(value);
    })
    .matches(letterWithSymbolsRegex, 'Лише літери, дефіси, апострофи й тире')
    .required("Вкажіть ім'я"),
  patronymic: Yup.string()
    .test('starts-with-letter', 'Повинно починатися з літери', (value) => {
      if (!value) return true;
      return /^[A-Za-zА-Яа-яІіЇїЄєҐґ]/.test(value);
    })
    .matches(
      letterWithSymbolsRegex,
      'Може містити лише літери, дефіси, апострофи й тире'
    )
    .nullable(),
  noCode: Yup.boolean(),
  RNOKPP: Yup.string()
    .nullable()
    .when('noCode', {
      is: (val) => val === false || val === undefined,
      then: Yup.string().required(' ').matches(rnokppRegex, { message: ' ' }),
      otherwise: Yup.string().nullable(),
    }),
  sex: Yup.string().oneOf(['Ч', 'Ж'], '').required(''),
  birthday: Yup.string()
    .transform((value) => {
      const parsedDate = dayjs(value, 'DD.MM.YYYY', true);
      return parsedDate.isValid() ? parsedDate.format('DD.MM.YYYY') : null;
    })
    .nullable()
    .required('')
    .test(
      'is-valid-date',
      '',
      (value) => value && dayjs(value, 'DD.MM.YYYY', true).isValid()
    ),
  mobilePhone: Yup.string()
    .matches(ukPhoneRegex, '+380XXXXXXXXX')
    .required('Вкажіть номер телефону'),
  workPhoneNumber: Yup.string()
    .nullable()
    .test(
      'is-valid-phone',
      '+380XXXXXXXXX',
      (value) => !value || value === '+380' || ukPhoneRegex.test(value)
    )
    .notOneOf([Yup.ref('mobilePhone')], 'Робочий та особистий номери однакові'),
  place: Yup.object({
    city: Yup.string().nullable().required('Оберіть населений пункт зі списку'),
    street: Yup.string()
      .nullable()
      .required('Оберіть вулицю після населеного пункту'),
    id: Yup.number().nullable(),
    city_processed: Yup.string().nullable(),
    city_region: Yup.string().nullable(),
    country: Yup.string().nullable(),
    oblast: Yup.string().nullable(),
    region: Yup.string().nullable(),
    street_processed: Yup.string().nullable(),
  }).required(''),
  house: Yup.string()
    .test(
      'starts-with-letter-or-digit',
      'Початок з літери або цифри',
      (value) => {
        if (!value) return true;
        return /^[A-Za-zА-Яа-яІіЇїЄєҐґ0-9]/.test(value);
      }
    )
    .matches(houseAndApartmentRegex, 'Літери або цифри')
    .nullable(),
  apartments: Yup.string()
    .test('starts-with-letter-or-digit', 'Літери або цифри', (value) => {
      if (!value) return true;
      return /^[A-Za-zА-Яа-яІіЇїЄєҐґ0-9]/.test(value);
    })
    .matches(houseAndApartmentRegex, 'Літери або цифри')
    .nullable(),
  selectedPassport: Yup.string()
    .oneOf(['ID_CARD', 'OLD_PASSPORT'], 'Неправильний тип паспорту')
    .required('Оберіть тип паспорту'),
  idCardNumber: Yup.string().when('selectedPassport', {
    is: 'ID_CARD',
    then: Yup.string().min(9, '').required(''),
    otherwise: Yup.string().nullable(),
  }),
  passportSeries: Yup.string().when('selectedPassport', {
    is: 'OLD_PASSPORT',
    then: Yup.string().min(2, '').required(''),
    otherwise: Yup.string().nullable(),
  }),
  passportNumber: Yup.string().when('selectedPassport', {
    is: 'OLD_PASSPORT',
    then: Yup.string().min(6, '').required(''),
    otherwise: Yup.string().nullable(),
  }),
  dateOfIssue: Yup.string()
    .transform((value) => {
      const parsedDate = dayjs(value, 'DD.MM.YYYY', true);
      return parsedDate.isValid() ? parsedDate.format('DD.MM.YYYY') : null;
    })
    .nullable()
    .required('')
    .test(
      'is-valid-date',
      '',
      (value) => value && dayjs(value, 'DD.MM.YYYY', true).isValid()
    ),
  authority: Yup.string()
    .when('selectedPassport', {
      is: 'ID_CARD',
      then: Yup.string().required(''),
    })
    .when('selectedPassport', {
      is: 'OLD_PASSPORT',
      then: Yup.string()
        .test('starts-with-letter-or-digit', 'Початок з літери', (value) => {
          if (!value) return true;
          return /^[A-Za-zА-Яа-яІіЇїЄєҐґ]/.test(value);
        })
        .matches(houseAndApartmentRegex, 'Без скорочень')
        .required(''),
    }),
  nationality: Yup.string().required('Оберіть країну'),
  posada: Yup.string().required('Оберіть посаду зі списку'),
  curatorId: Yup.mixed()
    .nullable()
    .when('posada', {
      is: (posada) => isMatchingPosition(posada),
      then: Yup.string().required(''),
      otherwise: Yup.mixed().nullable(),
    }),
  workForm: Yup.string()
    .transform((value) => {
      const parsedDate = dayjs(value, 'DD.MM.YYYY', true);
      return parsedDate.isValid() ? parsedDate.format('DD.MM.YYYY') : null;
    })
    .nullable()
    .required('')
    .test(
      'is-valid-date',
      '',
      (value) => value && dayjs(value, 'DD.MM.YYYY', true).isValid()
    ),
  resours: Yup.string()
    .required('Оберіть джерело інформації')
    .oneOf(['website', 'messanger', 'offline'], 'Невірне значення для ресурсу'),
  methodInvite: Yup.string()
    .required('Метод є обов’язковим')
    .test('is-valid-method', 'Невірне значення для методу', function (value) {
      const { resours } = this.parent;
      if (!resours) return false;
      const validMethods = {
        website: [
          'work.ua',
          'robota.ua',
          'jooble',
          'olx',
          'PidBir',
          'Facebook',
          'Instagram',
        ],
        messanger: ['Telegram', 'Viber'],
        offline: [
          'Оголошення в магазині',
          'Чек-оголошення',
          'Рекомендації',
          'Дзвінок менеджера з персоналу',
          'Таргетована реклама',
          'Аудіо-оголошення в магазині',
          'Агенція з працевлаштування',
          'Заклади навчання (технікум, інститут, тощо)',
          'Оголошення на вулиці (паперові)',
          'Флаєри',
          'Центр зайнятості',
        ],
      };
      return validMethods[resours]?.includes(value);
    }),
});
