//libs and hooks
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { connect, useSelector } from 'react-redux';
//components
import { SpaceBetween } from '../../styledComponents/Layout';
import { TextGrey } from '../../styledComponents/Text';
import SearchInput from '../../components/Inputs/SearchInput/SearchInput';
import Table from '../../components/tables/Table';
import TableRow from '../../components/tables/TableRow/TableRow';
import TableCell from '../../components/tables/TableCell/TableCell';
import EmployersCompanyInfo from '../../components/modals/EmployersInfo/EmployersCompanyInfo';
import EmployersInfo from '../../components/modals/EmployersInfo/EmployersInfo';
import AddEmployerModal from '../../components/modals/modalComponents/AddEmployerModal/AddEmployerModal';
import DropdownMore from '../../components/dropdowns/DropdownMore/DropdownMore';
import TableGreyButton from '../../components/buttons/TableGreyButton/TableGreyButton';
import TableNote from '../../components/tables/TableNote/TableNote';
import GreenButton from '../../components/buttons/GreenBuuton.js/GreenButton';
//icons
import docIcon from '../../images/icons/docs.svg';
import editIcon from '../../images/icons/edit.svg';
import deleteIcon from '../../images/icons/delete.svg';
import transferIcon from '../../images/icons/transfer.svg';
//styles
import './Kadri1.css';
//constants
//utilits
import DropdownButton from '../../components/dropdowns/Dropdown/DropdownButton.js/DropdownButton';
import TransferEmployer from '../../components/modals/TransferEmployer/TransferEmployer';

import loadEmployers from '../../redux/actions/employers/loadEmployers';
import deleteEmployer from '../../redux/actions/employers/deleteEmployer';
import getDateAndTime from '../../utilits/getDateAndTime';
import transferAccept from '../../redux/actions/employers/transferAccept';
import { WarningPopUp } from '../PopUps/WarningPopup';
import addressPlace from '../../utilits/addressPlace';

export let Kadri1 = ({ pressedItem, setPressedItem, ...props }) => {
  const workersData = useSelector((state) => {
    return state.employers;
  });
  const [isShowAll, setIsShowAll] = useState(!!localStorage.getItem('checked'));
  const [closeIcon, setCloseIcon] = useState(false);
  const alert = useAlert();
  const GRID_TEMPLATE = '64fr 293fr 170fr 297fr 342fr 290fr 144fr 94fr';
  const NEW_GRID_TEMPLATE = '64fr 293fr 170fr 297fr 342fr 384fr 94fr';
  const [modalIsActive, setModalIsActive] = useState(false);
  const [transferModalActive, setTransferModalActive] = useState(false);
  const [modalIsActiveSecond, setModalIsActiveSecond] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();
  const [buttonsModal, setButtonsModal] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const openEditWorkerModal = (index) => {
    if (workersData[index].type === 2) {
      toggleModalIsActiveFirst(index);
      return;
    }
    toggleModalIsActiveSecond(index);
  };

  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };

  const toggleButtonsModal = () => {
    setButtonsModal(!buttonsModal);
  };

  const getEmployers = () => {
    setSearchInput('');
    props.loadEmployers(alertFunc, isShowAll);
  };

  const removeEmployer = async (rnokpp) => {
    props.deleteEmployer(
      { rnokpp: `${rnokpp}`, userType: props.user.userType },
      alertFunc
    );
  };

  // useEffect(() => {
  //   if (workersData === null) {
  //     getEmployers()
  //   }
  // }, [])
  useEffect(() => {
    if (isShowAll) {
      localStorage.setItem('checked', '0');
      getEmployers(true);
    } else {
      localStorage.removeItem('checked');
      getEmployers();
    }
  }, [isShowAll]);

  const search = (e) => {
    toggleSetCloseIcon(e);
    setSearchInput(e.target.value);
  };

  const redirectToItem = (id) => {
    setPressedItem(id);
    history.push(`/home/kadri/${id}`);
  };
  const toggleSetCloseIcon = (e) => {
    if (e.target.value.length !== 0) {
      setCloseIcon(true);
    } else {
      setCloseIcon(false);
    }
    setSearchInput(e.target.value);
  };

  const clearInput = () => {
    setSearchInput('');
  };

  const toggleSearchModal = () => {
    setSearchModal(!searchModal);
  };

  const toggleModalIsActiveFirst = (index) => {
    setModalData(workersData[index]);
    setModalIsActive(!modalIsActive);
  };

  const toggleModalIsActiveSecond = (index) => {
    setModalData(workersData[index]);
    setModalIsActiveSecond(!modalIsActiveSecond);
  };

  const employerTransferAccept = (id) => {
    props.transferAccept(id, alertFunc);
  };
  const employerTransferReject = (id) => {
    props.transferAccept(id, alertFunc, true); // reject transfer
  };

  const searchFilter = (workersArr) => {
    return workersArr.filter((el) => {
      const inputsToSearch = [
        el.userInfO.full_name?.toLowerCase(),
        el.userInfO.short_name?.toLowerCase(),
        el.rnokpp.toString(),
        el.userInfO.location.toLowerCase(),
        el.accountant && el.accountant.toLowerCase(),
      ];
      return inputsToSearch.some((elem) => {
        return elem?.includes(searchInput.toLowerCase());
      });
    });
  };
  const getWorkersDataDOM = () => {
    const workersDataToShow =
      searchInput.length >= 1 ? searchFilter(workersData) : workersData;

    return workersDataToShow.map((elem, index) => {
      return elem.transfers && elem.transfers.status === 'waiting' ? (
        <TableRow
          isWaiting={true}
          key={elem._id}
          gridTemplate={NEW_GRID_TEMPLATE}
          onClick={null}
        >
          <TableCell justify="center">{index + 1}</TableCell>
          <TableCell>
            {elem.userInfO.short_name === ''
              ? elem.userInfO.full_name
              : elem.userInfO.short_name ?? elem.userInfO.full_name}
          </TableCell>
          <TableCell>{elem.rnokpp}</TableCell>
          <TableCell>{elem.userInfO.location}</TableCell>
          <TableCell>
            {elem.object &&
              addressPlace(
                { ...elem.object.place },
                elem.object.houseNumber
              ).join(', ')}
          </TableCell>
          <TableCell>{elem.accountant}</TableCell>
          <TableNote>
            <div>
              <TableGreyButton
                onClick={() => {
                  employerTransferAccept(elem.transfers.worker);
                }}
              >
                Прийняти
              </TableGreyButton>
              <TableGreyButton
                onClick={() => {
                  employerTransferReject(elem.transfers.worker);
                }}
              >
                Відхилити
              </TableGreyButton>
            </div>
            <p>{`Передано користувачем - ${
              elem.transfers.fromName
            } ${getDateAndTime(new Date(elem.transfers.date))}`}</p>
          </TableNote>
        </TableRow>
      ) : (
        <div
          key={elem._id}
          style={{ position: 'relative' }}
        >
          <div onClick={() => redirectToItem(elem._id)}>
            <TableRow
              isPressed={pressedItem === elem._id}
              gridTemplate={GRID_TEMPLATE}
              active={index === activeRowIndex}
            >
              <TableCell justify="center">{index + 1}</TableCell>
              <TableCell>
                {elem.userInfO.short_name === ''
                  ? elem.userInfO.full_name
                  : elem.userInfO.short_name ?? elem.userInfO.full_name}
              </TableCell>
              <TableCell>{elem.rnokpp}</TableCell>
              <TableCell>{elem.userInfO.location}</TableCell>
              <TableCell>
                {elem.object &&
                  addressPlace(
                    { ...elem.object.place },
                    elem.object.houseNumber
                  ).join(', ')}
              </TableCell>
              <TableCell />
              <TableCell>{elem.accountant}</TableCell>
            </TableRow>
          </div>
          <div
            style={{
              position: 'absolute',
              right: 20,
              top: 10,
            }}
          >
            <TableCell justify="center">
              <DropdownMore
                onClick={() => {
                  setActiveRowIndex(elem._id);
                }}
              >
                {elem.object && (
                  <DropdownButton
                    icon={docIcon}
                    onClick={() => {
                      history.push('/home/rent', {
                        location: 'kadr',
                        obj: elem.object,
                      });
                    }}
                  >
                    Документи
                  </DropdownButton>
                )}
                <DropdownButton
                  icon={editIcon}
                  onClick={() => {
                    openEditWorkerModal(index);
                  }}
                >
                  Редагувати
                </DropdownButton>
                {props.user.userType === 'headAccountant' && (
                  <DropdownButton
                    icon={transferIcon}
                    onClick={() => {
                      setTransferModalActive(!transferModalActive);
                      setActiveRowIndex(elem._id);
                    }}
                  >
                    Передати
                  </DropdownButton>
                )}
                <DropdownButton
                  icon={deleteIcon}
                  onClick={() => {
                    setDeleteItem(elem);
                  }}
                >
                  Видалити
                </DropdownButton>
              </DropdownMore>
            </TableCell>
          </div>
        </div>
      );
    });
  };
  let mainStatuses = [
    'headAccountant',
    'administrator',
    'lawyer',
    'networkManager',
  ];
  return (
    <div style={{ marginTop: '26px' }}>
      <SpaceBetween>
        <TextGrey>Перелік роботодавців</TextGrey>
        <SearchInput
          placeholder="Пошук"
          onChange={(e) => {
            search(e);
          }}
          value={searchInput}
          clearInputFunc={clearInput}
        />
        <div style={{ position: 'relative' }}>
          <GreenButton onClick={toggleSearchModal}>
            <span>+ Додати роботодавця</span>
          </GreenButton>
          {searchModal && (
            <AddEmployerModal
              refreshList={getEmployers}
              toggleFunc={toggleSearchModal}
            />
          )}
        </div>
      </SpaceBetween>
      <Table>
        <TableRow
          type="header"
          gridTemplate={GRID_TEMPLATE}
        >
          <TableCell justify="center">№</TableCell>
          <TableCell>ПІБ/Назва</TableCell>
          <TableCell>Код</TableCell>
          <TableCell>Адреса</TableCell>
          <TableCell>Об’єкт діяльності</TableCell>
          <TableCell onClick={() => setIsShowAll(!isShowAll)}>
            {mainStatuses.indexOf(props.user.userType) !== -1 && (
              <>
                <input
                  checked={isShowAll}
                  onChange={() => {}}
                  style={{ marginRight: 10 }}
                  id="check"
                  type="checkbox"
                />
                Показати всі записи
              </>
            )}
          </TableCell>
          <TableCell>У роботі</TableCell>
          <TableCell justify="center">Більше</TableCell>
        </TableRow>
        {workersData && getWorkersDataDOM()}
      </Table>

      {(modalIsActive ||
        modalIsActiveSecond ||
        transferModalActive ||
        deleteItem) && <div className="bg__layer"></div>}
      <div className="kadri__modals">
        {modalIsActive && (
          <EmployersInfo
            data={modalData}
            toggleFunc={toggleModalIsActiveFirst}
            getWorkersFunc={getEmployers}
          />
        )}
        {modalIsActiveSecond && (
          <EmployersCompanyInfo
            data={modalData}
            toggleFunc={toggleModalIsActiveSecond}
            getWorkersFunc={getEmployers}
          />
        )}
        {transferModalActive && (
          <TransferEmployer
            toggleFunc={() => {
              setTransferModalActive(false);
            }}
            selectedId={activeRowIndex}
          />
        )}
      </div>
      {deleteItem && (
        <WarningPopUp
          onDelete={() => {
            removeEmployer(deleteItem.rnokpp);
            setDeleteItem(null);
          }}
          toggleWarningModal={() => setDeleteItem(null)}
          name={deleteItem.userInfO.short_name ?? deleteItem.userInfO.full_name}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

Kadri1 = connect(mapStateToProps, {
  transferAccept,
  deleteEmployer,
  loadEmployers,
})(Kadri1);
