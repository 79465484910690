// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".personalEditTwo_personal_edit__sHZIq .table__row {\n    margin-bottom: 10px !important;\n}\n\n.personalEditTwo_personal_edit__date__QGJmR {\n    display: flex;\n    align-items: center;\n}\n\n.personalEditTwo_personal_edit__date__QGJmR >label {\n    max-width: 104px;\n}\n\n.personalEditTwo_personal_edit__date__QGJmR >label:not(:first-child) {\n    margin-left: 8px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Kadri/KadriItem/KadriCards/CardsPersonEdit/assets/css/personalEditTwo.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".personal_edit :global(.table__row) {\n    margin-bottom: 10px !important;\n}\n\n.personal_edit__date {\n    display: flex;\n    align-items: center;\n}\n\n.personal_edit__date :global(>label) {\n    max-width: 104px;\n}\n\n.personal_edit__date :global(>label:not(:first-child)) {\n    margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personal_edit": "personalEditTwo_personal_edit__sHZIq",
	"personal_edit__date": "personalEditTwo_personal_edit__date__QGJmR"
};
export default ___CSS_LOADER_EXPORT___;
