import {useCallback, useEffect, useState} from "react"

import saveToken from "../redux/actions/saveToken"
import {deleteCookie, getCookie, setCookie} from "../utilits/cookies"
import {store} from '../index';
import {DELETED_DATE} from "../redux/types";

export const useAuth = () => {
  // const { getCookie, setCookie, deleteCookie } = useCookie()

  const [authData, setAuthData] = useState({
    token: null,
    isAuth: false
  })

  const token = authData.token
  const isAuth = authData.isAuth

  const login = useCallback((jwtToken) => {
    setAuthData({
      token: jwtToken,
      isAuth: true
    })
    setCookie('token', jwtToken)
    store.dispatch(saveToken(jwtToken))
  }, [])

  const logout = useCallback(() => {
    setAuthData({
      token: null,
      isAuth: false
    })
    store.dispatch({type: DELETED_DATE})
    localStorage.removeItem("status")
    deleteCookie('token')
    deleteCookie('user')
    store.dispatch(saveToken(null))
  }, [])

  useEffect(() => {
    const jwtToken = getCookie('token')
    if (jwtToken) {
      login(jwtToken)
    }
  }, [login])

  return {token, isAuth, login, logout}
}
