import moment from 'moment';
import plural from 'ru-plurals';

export const get_experience = async (employer) => {
  const currentDate = moment();
  const year = plural('рік', 'роки', 'років');
  const month = plural('місяць', 'місяці', 'місяців');
  const day = plural('день', 'дні', 'днів');
  try {
    if (employer.length === 1) {
      const emp = employer[0];
      const keys = Object.keys(emp.postInfo);

      const isDeleted = keys.includes('uvalLastDay');

      if (isDeleted) return { posada_exp: null, all_exp: null };

      const isDate = keys.includes('dateAccept');

      const dateAccept = isDate
        ? emp.postInfo.dateAccept
        : emp.postInfo.more.startWorkDay;

      let date = moment(dateAccept, moment.ISO_8601, true);
      if (!date.isValid()) {
        date = moment(dateAccept, 'DD.MM.YYYY');
      }

      if (!date.isValid() || /^\d+$/.test(dateAccept)) {
        date = moment(Number(dateAccept));
      }

      const duration = moment.duration(currentDate.diff(date));
      console.log('🚀 ~ KadriPageConditionsWork ~ duration:', duration);

      const days = duration.days();
      const months = duration.months();
      const years = duration.years();

      console.log(days, months, years);
      const posada_exp = `${years === 0 ? '' : year(years)} ${
        months === 0 ? '' : month(months)
      } ${days === 0 ? '0' : day(days)}`;
      console.log(posada_exp);
      return { posada_exp: posada_exp, all_exp: posada_exp };
    } else if (employer.length > 1) {
      const deletedEmployer = employer.find((e) =>
        Object.keys(e.postInfo).includes('uvalLastDay')
      );
      const acceptEmployer = employer.find(
        (e) => !Object.keys(e.postInfo).includes('uvalLastDay')
      );

      let firstDate = deletedEmployer.createdAt;

      let firstDateMoment = moment(firstDate, moment.ISO_8601, true);

      if (!firstDateMoment.isValid()) {
        firstDateMoment = moment(firstDate, 'DD.MM.YYYY');
      }

      if (!firstDateMoment.isValid() || /^\d+$/.test(firstDate)) {
        firstDateMoment = moment(Number(firstDate));
      }

      let acceptDate = acceptEmployer.postInfo.more.startWorkDay
        ? acceptEmployer.postInfo.more.startWorkDay
        : acceptEmployer.postInfo.dateAccept;

      let acceptDateMoment = moment(acceptDate, moment.ISO_8601, true);
      if (!acceptDateMoment.isValid()) {
        acceptDateMoment = moment(acceptDate, 'DD.MM.YYYY');
      }

      if (!acceptDateMoment.isValid() || /^\d+$/.test(acceptDate)) {
        acceptDateMoment = moment(Number(acceptDate));
      }
      const allDuration = moment.duration(currentDate.diff(firstDateMoment));
      const newPosadaDuration = moment.duration(
        currentDate.diff(acceptDateMoment)
      );

      const allYears = allDuration.years();
      const allMonths = allDuration.months();
      const allDays = allDuration.days();

      const newYears = newPosadaDuration.years();
      const newMonths = newPosadaDuration.months();
      const newDays = newPosadaDuration.days();

      const posada_exp = `${newYears === 0 ? '' : year(newYears)} ${
        newMonths === 0 ? '' : month(newMonths)
      } ${newDays === 0 ? '0' : day(newDays)}`;
      console.log('🚀 ~ constposada_exp=', posada_exp);

      const all_exp = `${allYears === 0 ? '' : year(allYears)} ${
        allMonths === 0 ? '' : month(allMonths)
      } ${allDays === 0 ? '0' : day(allDays)}`;
      console.log('🚀 ~ constall_exp=`${allYears===0?', all_exp);
      console.log();
      return { posada_exp, all_exp };
    } else {
      return { posada_exp: null, all_exp: null };
    }
  } catch (err) {
    console.log('🚀 ~ err: get_work_expirience.js', err);
    return { posada_exp: null, all_exp: null };
  }
};
