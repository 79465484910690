import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/uk';
import instance from '../../../../../../redux/instance';
import { getDocKadri, viewFilesKadri } from '../../../../../../api/kadriDoc';
import { getInfoKadrConditions } from '../../../../../../api/kadriConditions';
import { get_experience } from '../../../../../../utilits/get_experiance';
import { EMPLOYER_GET_DATA } from '../../../../../../constants/urls';
import {
  get_current_employer,
  get_number_vacation,
} from '../../../../../../utilits/getVacationNumber';
import {
  CONTRACT_TYPE,
  EMPLOYMENT_TYPE,
  INDULGENCE_TYPE,
  PERSONNEL_NUMBER_TYPE,
  POSITION_WITHOUT_CURATOR,
  STATUS_TYPE,
} from './constants';
import _ from 'lodash';
import { getExperienceFromWorkerDocs } from '../../../../../../utilits/getExperienceFromWorkerDocs';

export const useWorkingConditionsData = ({ workerId, activeWorkers }) => {
  const currentWorkerData = useSelector((state) => state.currentWorker);
  const currentWorkerId = currentWorkerData?._id || null;

  const [workingConditionsData, setWorkingConditionsData] = useState(null);

  const params = useMemo(
    () => ({ currentWorkerId, workerId, activeWorkers }),
    [currentWorkerId, workerId, activeWorkers]
  );

  const prevParamsRef = useRef(null);
  const prevDataRef = useRef(null);

  useEffect(() => {
    setWorkingConditionsData(null);
    prevDataRef.current = null;
    prevParamsRef.current = null;
  }, [currentWorkerId, workerId]);

  useEffect(() => {
    if (!currentWorkerId || !workerId) return;

    if (prevParamsRef.current && _.isEqual(prevParamsRef.current, params)) {
      return;
    }
    prevParamsRef.current = params;

    let isMounted = true;

    const fetchWorkingConditionsData = async () => {
      try {
        const docRes = await getDocKadri(currentWorkerId);
        if (!isMounted || !Array.isArray(docRes)) return;

        const acceptanceDoc = docRes.find(
          (doc) =>
            doc?.info?.type === 'Наказ' &&
            doc?.info?.typeName === 'Наказ про прийняття на роботу'
        );
        const dismissalOrder = docRes.find(
          (doc) =>
            doc?.type === 'Пакет документів на звільнення' &&
            doc?.info?.typeName === 'Звільнення'
        );

        const [kadrRes, employerDataResponse] = await Promise.all([
          getInfoKadrConditions(currentWorkerId, workerId),
          instance.post(EMPLOYER_GET_DATA, { id: currentWorkerId }),
        ]);
        const employerData = employerDataResponse.data;

        const query = employerData.employer?.workerID
          ? { workerID: employerData.employer.workerID }
          : { _id: employerData?.employer?._id };
        const botInfoResponse = await instance.post(
          '/employer/get_bot_info',
          query
        );
        const botData = botInfoResponse.data;

        const { all_exp, posada_exp } = await get_experience(botData || []);
        const current_emp = get_current_employer(botData);
        const date = current_emp?.postInfo?.more
          ? current_emp.postInfo.more.startWorkDay
          : current_emp.postInfo?.acceptDate;
        const vacationCount = get_number_vacation(date, current_emp.vacation);

        const newData = {};

        const rawCurrent =
          employerData?.employer?.postInfo?.currentStatus || '';
        const rawStatus = employerData?.employer?.postInfo?.status || '';
        const rawCurrentLower = rawCurrent.toLowerCase();
        const rawStatusLower = rawStatus.toLowerCase();

        const unregList = [
          'неоформлений',
          'неоформлений',
          'неоформленный',
          'unregistered',
        ];
        const isAnyUnregistered =
          unregList.includes(rawCurrentLower) ||
          unregList.includes(rawStatusLower);

        if (isAnyUnregistered) {
          newData.status = STATUS_TYPE.unregistered;
          if (
            isMounted &&
            (!prevDataRef.current || !_.isEqual(prevDataRef.current, newData))
          ) {
            prevDataRef.current = newData;

            setWorkingConditionsData(newData);
          }
          return;
        }

        if (employerData) {
          newData.status =
            STATUS_TYPE[
              employerData.postInfo?.currentStatus ||
                employerData.postInfo?.status
            ] || '';
          newData.employmentType =
            employerData.postInfo?.format ||
            employerData.postInfo?.more?.localWork ||
            '';
          newData.contractType = employerData.postInfo?.more?.osnova || '';
          const baseMonthDate =
            employerData.postInfo?.more?.baseMoth ||
            employerData.postInfo?.more?.startWorkDay;
          newData.baseMoth = baseMonthDate
            ? moment(baseMonthDate).locale('uk').format('MMMM YYYY')
            : '';
          newData.startWorkDay =
            employerData.postInfo?.more?.startWorkDay ||
            employerData.postInfo?.more?.dayStart ||
            '';
          newData.jobTitle =
            employerData.postInfo?.more?.posada ||
            employerData.postInfo?.status ||
            '';
          newData.workExperience = employerData?.postInfo?.more?.workExperience;
          newData.workExperienceCompany =
            employerData?.postInfo?.more?.workExperienceCompany;
          newData.tableNumber = employerData.postInfo?.numberTabel;
        }

        const curatorId = employerData.employer?.curatorId;
        if (curatorId && activeWorkers) {
          const curator = activeWorkers.find((emp) => emp._id === curatorId);
          newData.curator = curator
            ? `${curator.surname} ${curator.name} ${curator.patronymic}`
            : POSITION_WITHOUT_CURATOR;
        } else {
          newData.curator = POSITION_WITHOUT_CURATOR;
        }

        if (kadrRes) {
          newData.salary = kadrRes.salary
            ? `${Number(kadrRes.salary).toFixed(2)} (${
                kadrRes.percent
              }% мін. ЗП)`
            : '';
          newData.tableNumber =
            kadrRes.more?.tableNumber ||
            PERSONNEL_NUMBER_TYPE.noPersonnelNumber;
          if (kadrRes.more?.startWorkDay) {
            newData.baseMoth = moment(kadrRes.more.startWorkDay)
              .locale('uk')
              .format('MMMM YYYY');
          } else if (kadrRes.info?.dataaccept) {
            newData.baseMoth = moment(kadrRes.info.dataaccept, 'DD.MM.YYYY')
              .locale('uk')
              .format('MMMM YYYY');
          } else if (kadrRes.more?.dayStart) {
            newData.baseMoth = moment(kadrRes.more.dayStart)
              .locale('uk')
              .format('MMMM YYYY');
          }
          newData.vacationBalance = vacationCount.toString();
        }

        if (acceptanceDoc) {
          const contractType = CONTRACT_TYPE[acceptanceDoc?.info?.typeString];
          if (contractType === CONTRACT_TYPE.Строковий) {
            const expendStartTime =
              acceptanceDoc?.info?.expstart || acceptanceDoc?.info?.exp?.start;
            const expendEndTime =
              acceptanceDoc?.info?.expend || acceptanceDoc?.info?.exp?.end;
            newData.contractType = `${contractType} (${expendStartTime} — ${expendEndTime})`;
          } else if (contractType) {
            newData.contractType = contractType;
          }
          const workType =
            EMPLOYMENT_TYPE[
              acceptanceDoc?.info?.side?.name || acceptanceDoc?.info?.sideofwork
            ] || '';
          const workHours = acceptanceDoc?.info?.side?.hours
            ? `(${acceptanceDoc.info.side.hours} годин)`
            : '';
          newData.employmentType = `${workType} ${workHours}`.trim();
          const jobTitle =
            acceptanceDoc?.info?.posada?.name ||
            acceptanceDoc?.info?.posadaname ||
            '';
          if (jobTitle) {
            newData.jobTitle = jobTitle.toLowerCase();
          }
          if (acceptanceDoc?.info?.table) {
            newData.tableNumber = acceptanceDoc.info.table;
          }
          const indulgenceSize =
            acceptanceDoc?.info?.pilga ||
            (acceptanceDoc?.info?.pilga &&
              acceptanceDoc?.info?.pilga?.pilgaprocent) ||
            '';
          newData.indulgence =
            indulgenceSize && indulgenceSize.trim() !== ''
              ? `${indulgenceSize}%`
              : INDULGENCE_TYPE.noIndulgence;
          const dateOfHire =
            acceptanceDoc?.info?.dataaccept ||
            moment(acceptanceDoc?.createdAt).format('DD.MM.YYYY');
          newData.dateOfHire = dateOfHire || '';
          const startWorkDay =
            acceptanceDoc?.info?.firstday ||
            moment(kadrRes?.more?.startWorkDay).format('DD.MM.YYYY');
          newData.startWorkDay = startWorkDay || '';
          const hireOrderNumber =
            acceptanceDoc?.info?.nomer ||
            acceptanceDoc?.info?.numberDogovor ||
            '';
          newData.hireOrderNumber = hireOrderNumber || '';
        } else {
          newData.indulgence = INDULGENCE_TYPE.noIndulgence;
        }

        if (dismissalOrder) {
          newData.status = STATUS_TYPE.deleted;
          newData.lastWorkingDay = dismissalOrder?.info?.lastDay;
          newData.dismissalType =
            dismissalOrder?.info?.typeDismissal || 'невідомо';
          const docNomer = dismissalOrder?.info?.nomer || 'невідомо';
          newData.dismissalOrderNumber = docNomer;
          newData.dismissalOrderDate = dismissalOrder?.info?.dateNakaz;
        }

        try {
          let acceptanceUrl = null;
          let dismissalUrl = null;
          if (acceptanceDoc) {
            const acceptView = await viewFilesKadri(
              acceptanceDoc._id,
              acceptanceDoc.path,
              acceptanceDoc.filename
            );
            acceptanceUrl = acceptView.generateUrl;
          }
          if (dismissalOrder) {
            const dismissView = await viewFilesKadri(
              dismissalOrder._id,
              dismissalOrder.path,
              dismissalOrder.filename
            );
            dismissalUrl = dismissView.generateUrl;
          }
          if (acceptanceDoc && acceptanceUrl && newData.hireOrderNumber) {
            const textForLink = newData.hireOrderNumber;
            newData.hireOrderNumber = { url: acceptanceUrl, text: textForLink };
          }
          if (dismissalOrder && dismissalUrl && newData.dismissalOrderNumber) {
            const textForLink = newData.dismissalOrderNumber;
            newData.dismissalOrderNumber = {
              url: dismissalUrl,
              text: textForLink,
            };
          }
        } catch (error) {
          console.error(
            'useWorkingConditionsData :: viewFilesKadri error >>>',
            error
          );
        }

        newData.workExperience = posada_exp;
        newData.workExperienceCompany = all_exp;
        const workingStatus = employerData?.employer
          ? STATUS_TYPE[
              employerData?.employer?.postInfo?.currentStatus ||
                employerData?.employer?.postInfo?.status
            ]
          : '';
        if (workingStatus) {
          newData.status = workingStatus;
        }

        prevDataRef.current = newData;
        setWorkingConditionsData(newData);
      } catch (error) {
        console.error('useWorkingConditionsData :: error >>>', error);
      }
    };

    fetchWorkingConditionsData();
    return () => {
      isMounted = false;
    };
  }, [activeWorkers, currentWorkerId, workerId]);

  return workingConditionsData;
};
