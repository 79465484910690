import React from 'react';

import PersonDataForm from '../molecules/PersonDataForm';

const CadrePagePersonal = ({
  id,
  formik,
  currentWorkerData,
  isEdit,
  setIsEdit,
}) => (
  <PersonDataForm
    id={id}
    formik={formik}
    currentWorkerData={currentWorkerData}
    isEdit={isEdit}
    setIsEdit={setIsEdit}
  />
);

export default CadrePagePersonal;
