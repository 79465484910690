const mergeDataAndExp = (data, exp) => {
  const expMap = exp.reduce((acc, item) => {
    const key = `${item.surname}|${item.name}|${item.birthday}`;
    acc[key] = item;
    return acc;
  }, {});

  const merged = data.map((obj) => {
    const key = `${obj.surname}|${obj.name}|${obj.birthday}`;
    const found = expMap[key];

    if (found) {
      return {
        ...obj,
        all_exp: found.all_exp,
        posada_exp: found.posada_exp,
      };
    }

    return obj;
  });

  return merged;
};

export { mergeDataAndExp };
