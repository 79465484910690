import { LOAD_CURRENT_WORKER, REMOVE_CURRENT_WORKER } from '../types';

const initialCurrentWorker = null;

export const currentWorkerReducer = (state = initialCurrentWorker, action) => {
  switch (action.type) {
    case LOAD_CURRENT_WORKER:
      return action.payload || null;
    case REMOVE_CURRENT_WORKER:
      return null;
    default:
      return state;
  }
};
