import React from 'react';
import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import SaveIcon from '@mui/icons-material/Save';

const ActionButton = ({ isEdited, isSubmitting, disabled = false }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      type="submit"
      disabled={disabled}
      sx={{
        fontSize: '14px',
        minHeight: '36px',
      }}
    >
      {isEdited ? 'Зберегти' : 'Далі'}
    </Button>
  );
};

export default ActionButton;
