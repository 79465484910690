import instance from '../instance';
import { getDocumentsJournal } from '../cadrDocuments/cadrDocuments-thunks';

export const deletedDocument =
  (id, year = null) =>
  async dispatch => {
    try {
      const response = await instance.post('/employer/kadr/delldoc', { id });
      year && dispatch(getDocumentsJournal(year));
      return response.data;
    } catch (e) {
      console.error('deletedDocument :: e >>>', e);
    }
  };
export const viewDocument = (id, path, filename) => async () => {
  try {
    const response = await instance.post('/googleStorage/view', { id: id, path: path, filename: filename });
    return response.data;
  } catch (e) {
    console.error('viewDocument :: e >>>', e);
  }
};
