import { FIELDS_CONFIG, STATUS_TYPE } from './constants';

export const prepareFields = (data) => {
  const valueOrDash = (value) => (value ? value : '—');
  const fields = FIELDS_CONFIG.map(({ id, label }) => ({
    id,
    label,
    value: valueOrDash(data ? data[id] : null),
  }));

  const isUnregistered = !Object.keys(data || {}).length;

  if (data?.status === STATUS_TYPE.deleted) {
    fields.push(
      {
        id: 'dismissalOrderDate',
        label: 'Дата наказу про звільнення',
        value: valueOrDash(data.dismissalOrderDate),
      },
      {
        id: 'dismissalOrderNumber',
        label: '№ наказу про звільнення',
        value: valueOrDash(data.dismissalOrderNumber),
      },
      {
        id: 'lastWorkingDay',
        label: 'Останній робочий день',
        value: valueOrDash(data.lastWorkingDay),
      },
      {
        id: 'dismissalType',
        label: 'Причина звільнення',
        value: valueOrDash(data.dismissalType),
      }
      // {
      //   id: 'dismissalOrderLink',
      //   label: 'Наказ про звільнення',
      //   value: valueOrDash(data.dismissalOrderLink),
      // }
    );
  }
  if (isUnregistered) {
    const statusField = [{ ...fields[0], value: STATUS_TYPE.unregistered }];
    return statusField;
  }

  return fields;
};
