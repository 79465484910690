//user
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const DELETED_DATE = 'DELETED_DATE';
export const LOAD_USER_DATA_FROM_COOKIES = 'LOAD_USER_DATA_FROM_COOKIES';
export const EDIT_USER_NAME = 'EDIT_USER_NAME';
export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';
//user
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

//statistic
export const LOAD_STATISTIC_REGION = 'LOAD_STATISTIC_REGION';

//alerts
export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
//controller
export const LOAD_CONTROLLER = 'LOAD_CONTROLLER';
export const LOAD_LIST_OBJECT = 'LOAD_LIST_OBJECT';
export const LOAD_CONTROLLER_VISITOR = 'LOAD_CONTROLLER_VISITOR';
export const LOAD_CONTROLLER_IP = 'LOAD_CONTROLLER_IP';
export const LOAD_INFO_CONTROLLER = 'LOAD_INFO_CONTROLLER';
export const LOAD_HISTORY_CONTROLLER = 'LOAD_HISTORY_CONTROLLER';
export const LOAD_CONTROLLER_USER = 'LOAD_CONTROLLER_USER';
//object
export const LOAD_OBJECT = 'LOAD_OBJECT';
export const UPLOAD_OBJECT_DATA = 'UPLOAD_OBJECT_DATA';
//workers
export const LOAD_EMPLOYERS = 'LOAD_EMPLOYERS';
//workers
export const LOAD_WORKERS = 'LOAD_WORKERS';

export const SET_CURRENT_WORKERS = 'SET_CURRENT_WORKERS';
export const REMOVE_CURRENT_WORKERS = 'REMOVE_CURRENT_WORKERS';

//current workers
export const LOAD_CURRENT_WORKER = 'LOAD_CURRENT_WORKER';
export const REMOVE_CURRENT_WORKER = 'REMOVE_CURRENT_WORKER';

// education types
export const BASE_EDUCATION = 'BASE_EDUCATION';
export const SECOND_EDUCATION = 'SECOND_EDUCATION';
export const PROF_EDUCATION = 'PROF_EDUCATION';

//passports
export const ID_CARD = 'ID_CARD';
export const OLD_PASSPORT = 'OLD_PASSPORT';

export let docTypes = ['docx', 'pdf', 'jpg', 'jpeg', 'png'];
