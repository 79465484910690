import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Box,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import debounce from 'lodash/debounce';

import EnhancedInput from '../atoms/EnhancedInput';
import EnhancedDatePicker from '../atoms/EnhancedDatePicker';
import EnhancedSelect from '../atoms/EnhancedSelect';
import CombinedInputToggle from '../atoms/CombinedInputToggle';
import EnhancedAddressAutocomplete from '../atoms/EnhancedAddressAutocomplete';
import EnhancedCountryAutocomplete from '../atoms/EnhancedCountryAutocomplete';
import CombinedSelect from '../atoms/CombinedSelect';
import ActionButton from '../atoms/ActionButton';
import { decodeRnokpp, formatUkrainianPhoneNumber } from '../lib/utils';
import loadCurrentWorker from '../../../../../../../../redux/actions/currentWorker/loadCurrentWorker';
import { transformWorkerData } from '../lib/utils/transformWorkerData';
import {
  formatEmployerItem,
  radixSort,
  sortBySurname,
} from '../../../../KadriCardsSearch';
import { getPositions } from '../../../../../../../../redux/cadrDocuments/cadrDocuments-thunks';

const PositionsMap = {
  'Касир торгівельного залу': true,
  'Касир торговельного залу': true,
};

export const isMatchingPosition = (position) =>
  Object.keys(PositionsMap).some((key) => position?.includes(key));

const StatusMap = {
  unregistered: 'неоформлено',
  vacation: 'відпустка',
  deleted: 'звільнено',
  work: 'працює',
  cancellation: 'відмінено',
  Неоформлений: 'неоформлено',
  unRegistered: 'Неоформленный',
};

export const filterAndSortOptions = (data, query, searchField) => {
  const trimmed = query.trim();
  if (trimmed.length < 3) return [];

  if (searchField === 'surname') {
    if (/\d/.test(trimmed)) return [];
    const filtered = data.filter(
      (el) =>
        el.surname && el.surname.toLowerCase().includes(trimmed.toLowerCase())
    );
    return sortBySurname(filtered, trimmed);
  }

  if (searchField === 'RNOKPP') {
    if (!/^\d+$/.test(trimmed)) return [];
    const filtered = data.filter(
      (el) => el.RNOKPP && el.RNOKPP.toString().includes(trimmed)
    );
    const startsWithTrimmed = filtered.filter((el) =>
      el.RNOKPP.toString().startsWith(trimmed)
    );
    const containsTrimmed = filtered.filter(
      (el) => !el.RNOKPP.toString().startsWith(trimmed)
    );
    return [...radixSort(startsWithTrimmed), ...containsTrimmed];
  }

  const isNumeric = /^\d+$/.test(trimmed);
  let filtered = data.filter((el) =>
    isNumeric
      ? el.RNOKPP && el.RNOKPP.toString().includes(trimmed)
      : el.surname && el.surname.toLowerCase().includes(trimmed.toLowerCase())
  );

  if (isNumeric) {
    const startsWithTrimmed = filtered.filter((el) =>
      el.RNOKPP.toString().startsWith(trimmed)
    );
    const containsTrimmed = filtered.filter(
      (el) => !el.RNOKPP.toString().startsWith(trimmed)
    );
    filtered = [...radixSort(startsWithTrimmed), ...containsTrimmed];
  } else {
    filtered = sortBySurname(filtered, trimmed);
  }

  return filtered;
};

const PersonDataForm = ({
  id,
  formik,
  currentWorkerData,
  isEdit,
  setIsEdit,
}) => {
  const [forceUpdate, setForceUpdate] = useState(0);

  const location = useLocation();

  const readOnly = Boolean(
    location.pathname === '/home/personal' &&
      currentWorkerData &&
      currentWorkerData.postInfo?.status !== StatusMap.unRegistered &&
      isEdit
  );

  const dispatch = useDispatch();

  const positions = useSelector(
    (state) => state.cadrDocumentsReducer.positions
  );
  const data = useSelector((state) =>
    state.employersReducer.employerAll ? state.employersReducer.employerAll : []
  );
  const workers = useSelector((state) => state.workers);

  const onRNOKPPChange = (event, newValue) => {
    if (newValue && typeof newValue === 'object') {
      dispatch(loadCurrentWorker(newValue._id))
        .then((res) => {
          const transformedData = transformWorkerData(res);
          formik.resetForm({ values: transformedData });
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    }
  };

  useEffect(() => {
    if (currentWorkerData) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    getPositions(id);
  }, [id]);

  useEffect(() => {
    if (!isMatchingPosition(formik.values?.posada)) {
      formik.setFieldValue('curatorId', '');
    }
  }, [formik.values.posada]);

  const [surnameOptions, setSurnameOptions] = useState([]);
  const [rnokppOptions, setRnokppOptions] = useState([]);

  const isIDCard = formik.values.selectedPassport === 'ID_CARD';
  const isUnregistered = Boolean(
    !currentWorkerData &&
      currentWorkerData?.postInfo?.status === StatusMap.unregistered
  );

  useEffect(() => {
    const formattedMobilePhone = formatUkrainianPhoneNumber(
      formik.values.mobilePhone
    );
    if (formattedMobilePhone !== formik.values.mobilePhone) {
      formik.setFieldValue('mobilePhone', formattedMobilePhone);
    }
    const formattedWorkPhoneNumber =
      formik.values?.workPhoneNumber &&
      formatUkrainianPhoneNumber(formik.values.workPhoneNumber);
    if (
      formattedWorkPhoneNumber &&
      formattedWorkPhoneNumber !== formik.values.workPhoneNumber
    ) {
      formik.setFieldValue('workPhoneNumber', formattedWorkPhoneNumber);
    }
  }, []);

  useEffect(() => {
    const passportValue = formik.values.passport || '';
    if (isIDCard) {
      formik.setFieldValue('passport', passportValue);
      formik.setFieldValue('idCardNumber', passportValue);
    } else {
      const series = passportValue.slice(0, 2).toUpperCase();
      const number = passportValue.slice(2);
      formik.setFieldValue('passportSeries', series);
      formik.setFieldValue('passportNumber', number);
    }
  }, [formik.values.selectedPassport, formik.values.passport, isIDCard]);

  useEffect(() => {
    setForceUpdate((prev) => prev + 1);
  }, [currentWorkerData]);

  const availableCurators = workers
    ?.filter(
      (worker) =>
        worker?.postInfo?.currentStatus !== 'deleted' &&
        worker?.postInfo?.status !== StatusMap.unRegistered &&
        worker?._id !== currentWorkerData?._id
    )
    .map((worker) => ({
      value: worker._id,
      label: `${worker.surname} ${worker.name}`,
    }));

  const handleToggle = (event, newValue) => {
    if (newValue) {
      formik.setFieldValue('selectedPassport', newValue);
      formik.setFieldValue('passport', '');
      formik.setFieldValue('authority', '');
      formik.setFieldValue('dateOfIssue', '');
    }
    if (formik.values.selectedPassport === 'ID_CARD') {
      formik.setFieldValue('idCardNumber', '');
    } else {
      formik.setFieldValue('passportSeries', '');
      formik.setFieldValue('passportNumber', '');
    }
  };

  const handlePasteUkrainianPhoneNumber = (event) => {
    event.preventDefault();
    const pastedData = (event.clipboardData || window.clipboardData).getData(
      'Text'
    );
    const fieldId = event.target.id;
    const formattedNumber = formatUkrainianPhoneNumber(pastedData);
    formik.setFieldValue(fieldId, formattedNumber);
  };

  const handleRnokppChange = debounce((rnokpp) => {
    if (rnokpp.length === 10 && /^\d{10}$/.test(rnokpp)) {
      const { birthday, sex } = decodeRnokpp(rnokpp);
      if (!formik.values.birthday) {
        formik.setFieldValue('birthday', birthday);
      }
      if (!formik.values.sex) {
        formik.setFieldValue('sex', sex);
      }
    }
  }, 500);

  const handlePassportChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  const onRnokppChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('RNOKPP', value);
    if (!formik.values.noCode) {
      handleRnokppChange(value);
    }
  };

  const renderPhoneField = (id, label) => (
    <Box sx={{ width: '100%', maxWidth: '300px' }}>
      <MuiTelInput
        id={id}
        size="small"
        fullWidth
        defaultCountry="UA"
        onlyCountries={['UA']}
        disableDropdown
        label={label}
        value={formik.values[id]}
        onPaste={handlePasteUkrainianPhoneNumber}
        slotProps={{
          htmlInput: {
            readOnly,
          },
        }}
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '12px',
            '& .MuiInputAdornment-root': {
              '& img': {
                borderRadius: '2px',
              },
            },
          },
        }}
        onChange={(value) => {
          if (!value.startsWith('+380')) {
            value = '+380';
          }
          console.log('value', value);

          const formattedValue = value.replace(/\s/g, '');
          if (formattedValue.length <= 13) {
            formik.setFieldValue(id, formattedValue);
          }
        }}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        helperText={
          formik.touched[id] && formik.errors[id] ? (
            <Typography
              variant="caption"
              sx={{ color: 'error.main' }}
            >
              {formik.errors[id]}
            </Typography>
          ) : (
            ' '
          )
        }
      />
    </Box>
  );

  const renderOption = (props, option, { inputValue, index }) => {
    const uniqueKey = option._id
      ? option._id
      : `${option.surname}-${option.RNOKPP}-${index}`;
    return (
      <li
        {...props}
        key={uniqueKey}
      >
        {formatEmployerItem(option, inputValue)}
      </li>
    );
  };

  return (
    <Container>
      <Box
        key={forceUpdate}
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ paddingTop: '42px', paddingLeft: '16px' }}
      >
        <Stack
          direction="row"
          spacing={2}
          marginBottom={1}
        >
          <Autocomplete
            sx={{
              width: 300,
              '& .MuiOutlinedInput-root': {
                height: 38,
                padding: '0 12px',
              },
              '& .MuiAutocomplete-input': {
                padding: '0px',
                lineHeight: '38px',
              },
            }}
            id="surname-autocomplete"
            options={surnameOptions}
            readOnly={readOnly}
            // disabled={readonly}
            inputValue={formik.values.surname || ''}
            getOptionLabel={(option) =>
              typeof option === 'string'
                ? option
                : `${option.surname} ${option.name} ${option.patronymic} (${
                    option.RNOKPP
                  }) ${option.postInfo?.currentStatus || ''}`
            }
            onInputChange={(event, newInputValue) => {
              formik.setFieldValue('surname', newInputValue);
              if (newInputValue.length > 2) {
                const newOptions = filterAndSortOptions(
                  data,
                  newInputValue,
                  'surname'
                );
                setSurnameOptions(newOptions);
              } else {
                setSurnameOptions([]);
              }
            }}
            onChange={(event, newValue) => {
              if (newValue && typeof newValue === 'object') {
                dispatch(loadCurrentWorker(newValue?._id))
                  .then((res) => {
                    const transformedData = transformWorkerData(res);

                    formik.resetForm({ values: transformedData });
                  })
                  .catch((err) => {
                    console.error('Error:', err);
                  });
              }
            }}
            freeSolo
            size="small"
            renderOption={renderOption}
            renderInput={(params) => (
              <EnhancedInput
                {...params}
                id="surname"
                label="Прізвище"
                formik={formik}
                capitalizeFirst
                showWarning
                readOnly={readOnly}
                placeholder="Лише літери, дефіс, тире або пробіл"
                ref={params.InputProps.ref}
                setOptions={setSurnameOptions}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />

          <EnhancedInput
            id="name"
            label="Ім'я"
            formik={formik}
            capitalizeFirst
            showWarning
            placeholder="Лише літери, дефіс, тире або пробіл"
            readOnly={readOnly}
          />
          <EnhancedInput
            id="patronymic"
            label="По батькові"
            formik={formik}
            capitalizeFirst
            showWarning
            readOnly={readOnly}
            placeholder="Лише літери, дефіс, тире або пробіл"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          marginBottom={1}
        >
          <CombinedInputToggle
            id="RNOKPP"
            label="РНОКПП"
            type="number"
            placeholder="Лише цифри"
            formik={{
              ...formik,
              onChange: (e) => onRnokppChange(e, formik),
            }}
            isToggled={Boolean(formik.values.noCode)}
            onToggleChange={(checked) => {
              formik.setFieldValue('noCode', checked);
              if (checked) {
                formik.setFieldValue('RNOKPP', '');
              }
            }}
            setOptions={setRnokppOptions}
            readOnly={readOnly}
            maxLength={10}
            searchOptions={rnokppOptions}
            onSearchInputChange={(event, newInputValue) => {
              if (newInputValue.length > 2) {
                const newOptions = filterAndSortOptions(
                  data,
                  newInputValue,
                  'RNOKPP'
                );
                setRnokppOptions(newOptions);
              } else {
                setRnokppOptions([]);
              }
            }}
            onChange={onRNOKPPChange}
            renderOption={renderOption}
          />
          <Stack
            direction="row"
            spacing={2}
            width="300px"
          >
            <EnhancedSelect
              id="sex"
              label="Стать"
              formik={formik}
              options={[
                { value: 'Ч', label: 'Чоловік' },
                { value: 'Ж', label: 'Жінка' },
              ]}
              readOnly={readOnly}
            />
            <EnhancedDatePicker
              id="birthday"
              label="Дата народження"
              placeholder="дд-мм-рррр"
              disableFuture
              formik={formik}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          marginBottom={1}
          width="616px"
        >
          {renderPhoneField('mobilePhone', 'Особистий номер телефону')}
          {renderPhoneField('workPhoneNumber', 'Робочий номер телефону')}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          marginBottom={1}
        >
          <EnhancedAddressAutocomplete
            id="place"
            label="Населений пункт"
            formik={formik}
            readOnly={readOnly}
          />

          <Stack
            direction="row"
            spacing={2}
            width="300px"
          >
            <EnhancedInput
              id="house"
              label="Будинок"
              placeholder="1, a, 1a, a1, 1-a, a-1"
              formik={formik}
              maxLength={6}
              readOnly={readOnly}
            />
            <EnhancedInput
              id="apartments"
              label="Квартира"
              placeholder="1, a, 1a, a1, 1-a, a-1"
              formik={formik}
              maxLength={6}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          marginBottom={1}
        >
          <Stack
            direction="row"
            width="300px"
            marginBottom={1}
          >
            <ToggleButtonGroup
              value={isIDCard ? 'ID_CARD' : 'OLD_PASSPORT'}
              exclusive
              onChange={handleToggle}
              sx={{ maxHeight: '36px' }}
              disabled={readOnly}
            >
              <ToggleButton
                value="ID_CARD"
                sx={{
                  boxShadow: isIDCard
                    ? '0px 6px 15px rgba(0, 0, 0, 0.3)'
                    : 'none',
                }}
              >
                ID-картка
              </ToggleButton>
              <ToggleButton
                value="OLD_PASSPORT"
                size="small"
                sx={{
                  boxShadow: !isIDCard
                    ? '0px 6px 15px rgba(0, 0, 0, 0.3)'
                    : 'none',
                }}
              >
                Паспорт старого зразка
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            width="300px"
          >
            {isIDCard ? (
              <EnhancedInput
                id="idCardNumber"
                label="Номер ID картки"
                placeholder="Лише цифри"
                formik={{
                  ...formik,
                  onChange: (e) => {
                    const value = e.target.value;
                    formik.setFieldValue('idCardNumber', value);
                    formik.setFieldValue('passport', value);
                  },
                }}
                error={formik.errors.idCardNumber}
                maxLength={9}
                type="number"
                showCounter
                readOnly={readOnly}
              />
            ) : (
              <Stack
                direction="row"
                spacing={1}
              >
                <EnhancedInput
                  id="passportSeries"
                  label="Серія"
                  placeholder="АА"
                  formik={{
                    ...formik,
                    onChange: (e) =>
                      formik.setFieldValue(
                        'passportSeries',
                        e.target.value
                          .toUpperCase()
                          .replace(/[^A-ZА-ЯІЇЄҐ]/g, '')
                          .replace(/[^\u0400-\u04FF\s]/g, '')
                      ),
                  }}
                  sx={{ width: '52px', textAlign: 'center' }}
                  maxLength={2}
                  capitalizeAll
                  showCounter
                  allowCyrillicOnly
                  readOnly={readOnly}
                />
                <EnhancedInput
                  id="passportNumber"
                  label="Номер"
                  placeholder="000000"
                  maxLength={6}
                  showCounter
                  type="number"
                  formik={{
                    ...formik,
                    onChange: (e) =>
                      formik.setFieldValue('passportNumber', e.target.value),
                  }}
                  readOnly={readOnly}
                  sx={{ textAlign: 'center' }}
                />
              </Stack>
            )}
            <EnhancedDatePicker
              id="dateOfIssue"
              label="Дата видачі"
              disableFuture
              formik={formik}
              width={!isIDCard && '142px'}
              readOnly={readOnly}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            width="300px"
          >
            {isIDCard ? (
              <EnhancedInput
                id="authority"
                label="Орган, що видав (код)"
                placeholder="Лише цифри"
                showCounter
                type="number"
                maxLength={4}
                formik={formik}
                readOnly={readOnly}
              />
            ) : (
              <EnhancedInput
                id="authority"
                label="Орган, що видав (назва)"
                placeholder="Лише літери"
                formik={formik}
                readOnly={readOnly}
              />
            )}
            <EnhancedCountryAutocomplete
              id="nationality"
              label="Громадянство"
              formik={formik}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
        >
          <Stack
            direction="row"
            width="300px"
          >
            <CombinedSelect
              label="Звідки дізнались про посаду"
              formik={formik}
              readOnly={readOnly}
            />
          </Stack>
          <Stack width="300px">
            <EnhancedSelect
              id="posada"
              label="Посада"
              formik={formik}
              options={positions
                ?.slice()
                .sort((a, b) =>
                  a.title.localeCompare(b.title, undefined, {
                    sensitivity: 'base',
                  })
                )
                .map((position) => ({
                  value: position.title,
                  label: position.title,
                }))}
              placeholder="Виберіть посаду"
              readOnly={readOnly}
            />
          </Stack>
          <Stack width="142px">
            <EnhancedSelect
              id="curatorId"
              label="Наставник"
              formik={formik}
              options={availableCurators}
              placeholder="Виберіть наставника"
              readOnly={readOnly}
              disabled={
                !isMatchingPosition(formik.values?.posada) ||
                availableCurators?.length === 0
              }
            />
          </Stack>
          <EnhancedDatePicker
            id="workForm"
            width="142px"
            label="Дата виходу на роботу"
            formik={formik}
            disablePast={Boolean(!formik?.values['workForm'])}
            readOnly={readOnly}
          />
        </Stack>

        <Stack
          width="300px"
          marginTop={1}
          display="flex"
          marginLeft="auto"
          marginRight="16px"
        >
          <ActionButton
            isEdited={Boolean(currentWorkerData)}
            disabled={readOnly}
          />
        </Stack>
      </Box>
    </Container>
  );
};

export default PersonDataForm;
