import React, { useEffect, useState } from 'react';
import {
  SButtonLightGreen,
  SButtonLightGrey,
} from '../../../../../styledComponents/Buttons';
import ConditionsHeader from './components/ConditionsHeader';
import { Divider } from '../../../../../styledComponents/Dividers';
import { Formik } from 'formik';
import { InputForm } from '../../../../../styledComponents/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { acceptDismissal } from '../../../../../redux/workers/workers-thunks';
import { useAlert } from 'react-alert';
import getDateAndTime from '../../../../../utilits/getDateAndTime';
import moment from 'moment';
import { deletedDocument } from '../../../../../redux/documentOld/document-thunks';
import { setModalInfo } from '../../../../../redux/modalInfo/modal-actions';
import { getSettings } from '../../../../../redux/settings/settings-thunks';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ukUA } from '@mui/x-date-pickers/locales';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';

export const KadriCardsWorkDoc__3 = ({
  toggleWork__3,
  employerId,
  workerId,
  toggleModalList,
  openEditWorkerModal,
  setTypeModal,
  setIndexTab,
  setTypeId,
}) => {
  const modal = useSelector((state) => state.modal.info);
  const settings = useSelector((state) => state.settingsReducer.settings);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();

  const alertFunc = (message, type) => {
    alert.show(message.status, { type });
    setIsFetching(false);
  };

  const id = window.location.pathname.slice(
    window.location.pathname.lastIndexOf('/') + 1
  );

  useEffect(() => {
    dispatch(getSettings(id)).catch((error) => {
      console.error('Error fetching settings:', error);
      alert.show('Помилка завантаження налаштувань', { type: 'error' });
    });
  }, [dispatch, id, alert]);

  return (
    <>
      {settings && (
        <Formik
          enableReinitialize
          initialValues={{
            lastDay:
              (modal &&
                getDateAndTime(
                  new Date(moment(modal.lastDay, 'DD.MM.YYYY')),
                  true
                )) ||
              '',
            dateNakaz:
              (modal &&
                getDateAndTime(
                  new Date(moment(modal.dateNakaz, 'DD.MM.YYYY')),
                  true
                )) ||
              '',
            order:
              modal?.nomer ??
              (settings?.number1 !== -1 ? settings?.number1 + 1 : ''),
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsFetching(true);
            try {
              const fileId = await dispatch(
                acceptDismissal(
                  {
                    isEdit: modal?.isEdit,
                    id: modal?.employerId ?? employerId,
                    workerId: workerId,
                    number: values.order,
                    info: {
                      lastDay: values.lastDay,
                      dateNakaz: values.dateNakaz,
                      type: 'закінчення строку',
                    },
                  },
                  alertFunc,
                  toggleWork__3
                )
              );

              if (!modal) {
                openEditWorkerModal(employerId);
                setTypeId(fileId);
                setTypeModal('Пакет документів на звільнення');
                setIndexTab();
              } else {
                dispatch(deletedDocument(modal.documentId, modal.year));
                dispatch(setModalInfo(null));
              }
            } catch (error) {
              console.error('Error in submission:', error);
              alert.show('Сталася помилка при збереженні', { type: 'error' });
            } finally {
              setIsFetching(false);
              setSubmitting(false);
            }
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => {
            const isValid = values.lastDay && values.order && values.dateNakaz;

            return (
              <>
                {modal && (
                  <div
                    className="bg__layer"
                    style={{ zIndex: 999 }}
                  />
                )}
                <div className="conditions__workdoc">
                  <ConditionsHeader
                    toggle={() => {
                      toggleWork__3();
                      if (modal) {
                        dispatch(setModalInfo(null));
                      }
                    }}
                  >
                    Умови припинення трудового договору (закінчення строку)
                  </ConditionsHeader>
                  <Divider />
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="uk"
                    localeText={
                      ukUA.components.MuiLocalizationProvider.defaultProps
                        .localeText
                    }
                  >
                    <div className="conditions__content">
                      <div className="conditions__content-left">
                        <p>Останній робочий день</p>
                        <p>Дата наказу</p>
                        <p>Наказ №</p>
                      </div>

                      <div className="conditions__content-right">
                        <div className="conditions__row">
                          <DatePicker
                            label=""
                            placeholder="Останній робочий день"
                            inputFormat="DD.MM.YYYY"
                            mask="__.__.____"
                            value={
                              values.lastDay
                                ? dayjs(values.lastDay, 'DD.MM.YYYY')
                                : null
                            }
                            onChange={(newDate) => {
                              if (newDate && newDate.isValid()) {
                                setFieldValue(
                                  'lastDay',
                                  getDateAndTime(newDate.toDate(), true)
                                );
                              } else {
                                setFieldValue('lastDay', '');
                              }
                            }}
                            slotProps={{
                              popper: {
                                sx: {
                                  zIndex: 999999,
                                },
                              },
                              textField: {
                                variant: 'outlined',
                                size: 'small',
                                label: '',
                                InputLabelProps: {
                                  shrink: false,
                                },
                                sx: {
                                  '& .MuiInputBase-root': {
                                    height: '32px',
                                    width: '124px',
                                  },
                                  // '& .MuiInputBase-input::placeholder': {
                                  //   textAlign: 'center',
                                  // },
                                },
                              },
                            }}
                          />
                        </div>

                        <div className="conditions__row">
                          <DatePicker
                            label=""
                            placeholder="Дата наказу"
                            inputFormat="DD.MM.YYYY"
                            mask="__.__.____"
                            value={
                              values.dateNakaz
                                ? dayjs(values.dateNakaz, 'DD.MM.YYYY')
                                : null
                            }
                            onChange={(newDate) => {
                              if (newDate && newDate.isValid()) {
                                setFieldValue(
                                  'dateNakaz',
                                  getDateAndTime(newDate.toDate(), true)
                                );
                              } else {
                                setFieldValue('dateNakaz', '');
                              }
                            }}
                            slotProps={{
                              popper: {
                                sx: {
                                  zIndex: 999999,
                                },
                              },
                              textField: {
                                variant: 'outlined',
                                size: 'small',
                                label: '',
                                InputLabelProps: {
                                  shrink: false,
                                },
                                sx: {
                                  '& .MuiInputBase-root': {
                                    height: '32px',
                                    width: '124px',
                                  },

                                  // '& .MuiInputBase-input::placeholder': {
                                  //   textAlign: 'center',
                                  // },
                                },
                              },
                            }}
                          />
                        </div>

                        <div className="conditions__row">
                          <InputForm
                            value={values.order}
                            id="order"
                            onChange={handleChange}
                            type="text"
                            style={{
                              width: '124px',
                              height: '32px',
                              textAlign: 'center',
                            }}
                            className="conditions__text-input"
                            disabled={settings?.number1 !== -1}
                          />
                        </div>
                      </div>
                    </div>
                  </LocalizationProvider>

                  <div className="conditions__buttons">
                    {isValid && !isFetching ? (
                      <SButtonLightGreen onClick={handleSubmit}>
                        Зберегти
                      </SButtonLightGreen>
                    ) : (
                      <SButtonLightGrey>Зберегти</SButtonLightGrey>
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};
