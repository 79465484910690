import { getCookie } from '../utilits/cookies';
import axios from 'axios';
import { SEARCH_ADDRESS, SEARCH_ADDRESS_STREET } from '../constants/urls';

export const addressSearch = async (search) => {
  const token = getCookie('token');
  const res = await axios.post(
    SEARCH_ADDRESS,
    { search: search },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};

export const addressStreet = async (place, search, id) => {
  const token = getCookie('token');
  const res = await axios.post(
    SEARCH_ADDRESS_STREET,
    { place, searchStreet: search, id: id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};
