import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    success: {
      light: '#66bb6a',
      main: '#009640',
      dark: '#388e3c',
    },
    primary: {
      light: '#66bb6a',
      main: '#009640',
      dark: '#388e3c',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f8f8f8',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          '& .MuiAutocomplete-noOptions': {
            display: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {},
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: '4px',
          '&:hover': {
            backgroundColor: '#EAF4EC',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          textTransform: 'none',
          borderRadius: '4px',
          lineHeight: '16px',
          flex: 1,
          border: '1px solid #009640',
          color: '#009640',
          '&.Mui-selected': {
            backgroundColor: '#009640',
            color: '#fff',
            borderColor: '#007D3C',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#007D3C',
          },
          '&:hover': {
            backgroundColor: '#EAF4EC',
          },
          '&:not(:first-of-type)': {
            borderLeft: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 8,
          borderR: '4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          paddingTop: '4px',
          paddingBottom: '4px',
          '&:hover': {
            backgroundColor: '#EAF4EC',
          },
          '&.Mui-selected': {
            backgroundColor: '#009640',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#007D3C',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '&.Mui-selected': {
            backgroundColor: '#009640',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#007D3C',
          },
          '&:hover': {
            backgroundColor: '#EAF4EC',
          },
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          borderRadius: '4px',
          padding: 0,
          height: '100%',
          '&.Mui-selected': {
            backgroundColor: '#009640',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#007D3C',
          },
          '&:hover': {
            backgroundColor: '#EAF4EC',
          },
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          borderRadius: '4px',
          padding: 0,
          height: '100%',
          '&.Mui-selected': {
            backgroundColor: '#009640',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#007D3C',
          },
          '&:hover': {
            backgroundColor: '#EAF4EC',
          },
        },
      },
    },
  },
});

export { theme };
