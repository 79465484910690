import axios from 'axios';
import { mainURL } from '../constants/urls';
import { getCookie } from '../utilits/cookies';

// api base url
const token = getCookie('token');

export const instance = axios.create({
  baseURL: mainURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    mode: 'no-cors',
    Authorization: token
  }
});

export default instance;
