import instance from '../../instance';
import { EMPLOYER_EDITSECONDINFO } from '../../../constants/urls';
import loadWorkers from './loadWorkers';
import loadCurrentWorker from '../currentWorker/loadCurrentWorker';

export const editSecondaryWorkerInfo = (alert, body, employerId, openNextTab) => async dispatch => {
  try {
    const response = await instance.post(EMPLOYER_EDITSECONDINFO, body);
    if (response.status === 200) {
      alert(response.data.message, 'success');
      dispatch(loadWorkers(employerId, alert));
      dispatch(loadCurrentWorker(body.id));
      openNextTab();
    } else {
      throw Error(response.data.message || 'Щось пішло не так');
    }
  } catch (e) {
    alert(e.message, 'error');
  }
};
