import instance from '../../instance';
import { EMPLOYER_GET_DATA } from '../../../constants/urls';
import { SET_CURRENT_WORKERS, LOAD_WORKERS } from '../../types';

export const loadAllCurrentWorkers =
  (workerIds = [], isPersonal = false) =>
  async (dispatch) => {
    try {
      const responses = await Promise.all(
        workerIds.map((id) => instance.post(EMPLOYER_GET_DATA, { id }))
      );

      const employers = responses.map((res) => res.data.employer);
      const normalizedData = employers.reduce((acc, worker) => {
        acc[worker._id] = worker;
        return acc;
      }, {});

      dispatch({
        type: SET_CURRENT_WORKERS,
        payload: normalizedData,
      });

      if (isPersonal) {
        dispatch({
          type: LOAD_WORKERS,
          payload: Object.values(normalizedData),
        });
      }
    } catch (error) {
      console.error('loadAllCurrentWorkers :: error >>>', error);
    }
  };
