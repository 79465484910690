import { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

import { prepareFields } from './helpers';
import { MODAL_ID, TAB_MODAL_ID } from '../../../KadriItem';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingLeft: '36px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  columnGap: '16px',
  rowGap: '2px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const renderValue = (value) => {
  if (value && typeof value === 'object' && value.url && value.text) {
    return (
      <Link
        href={value.url}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="info"
      >
        {value.text}
      </Link>
    );
  }
  return value;
};

const CadrePageConditionsWorkCard = () => {
  const data =
    useSelector(
      (state) => state.modal?.[MODAL_ID.EDIT]?.[TAB_MODAL_ID.WORKING_CONDITIONS]
    ) || null;

  const fields = useMemo(() => prepareFields(data), [data]);

  return (
    <Container>
      <GridContainer>
        {fields.map((item) => (
          <Fragment key={item.id}>
            <Typography sx={{ fontWeight: 300, fontSize: '14px' }}>
              {item.label}:
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: '14px', whiteSpace: 'nowrap' }}
            >
              {renderValue(item.value)}
            </Typography>
          </Fragment>
        ))}
      </GridContainer>
    </Container>
  );
};

export default CadrePageConditionsWorkCard;
