export const FIELDS_CONFIG = [
  { id: 'status', label: 'Статус' },
  { id: 'contractType', label: 'Вид договору' },
  { id: 'employmentType', label: 'Місце роботи' },
  { id: 'jobTitle', label: 'Назва посади' },
  { id: 'curator', label: 'Наставник' },
  { id: 'salary', label: 'Посадовий оклад' },
  { id: 'tableNumber', label: 'Табельний номер' },
  { id: 'baseMoth', label: 'Базовий місяць' },
  { id: 'indulgence', label: 'Розмір пільги' },
  { id: 'dateOfHire', label: 'Дата прийняття' },
  { id: 'startWorkDay', label: 'Дата виходу на роботу' },
  { id: 'hireOrderNumber', label: '№ наказу про прийняття' },
  // { id: 'hireOrderLink', label: 'Наказ про прийняття' },
  { id: 'workExperience', label: 'Стаж роботи на посаді' },
  { id: 'workExperienceCompany', label: 'Стаж роботи в компанії' },
  { id: 'vacationBalance', label: 'Накопичені дні відпустки' },
  // { id: 'dismissalApplicationDate', label: 'Дата заяви про звільнення' },
];

export const CONTRACT_TYPE = {
  'Не строковий': 'безстроковий',
  Строковий: 'строковий',
};

export const EMPLOYMENT_TYPE = {
  основне: 'основне',
  'за сумісництвом': 'за сумісництвом',
};

export const INDULGENCE_TYPE = {
  noIndulgence: 'пільга не застосовується',
};

export const PERSONNEL_NUMBER_TYPE = {
  noPersonnelNumber: 'відсутній',
};

export const STATUS_TYPE = {
  deleted: 'звільнений',
  truancy: 'прогул',
  work: 'працює',
  unregistered: 'неоформлений',
  vacation: 'відпустка',
  cancellation: 'відмінено',
  Неоформлений: 'неоформлений',
  unRegistered: 'неоформленный',
};

export const POSITION_WITHOUT_CURATOR = 'посада без наставника';
