import { combineReducers } from 'redux';
import { tokenReducer } from './reducers/tokenReducer';
import { userReducer } from './reducers/userReducer';
import { alertReducer } from './reducers/alertReducer';
import { employerReducer } from './reducers/employerReducer';
import { workerReducer } from './reducers/workerReducer';
import { currentWorkerReducer } from './reducers/currentWorkerReducer';
import { cadrDocumentsReducer } from './cadrDocuments/cadrDocuments-reducer';
import { employersReducer } from './employers/employers-reducer';
import { workersReducer } from './workers/workers-reducer';
import { settingsReducer } from './settings/settings-reducer';
import { objectsReducer } from './object/object-reducer';
import { objectReducer } from './reducers/objectReducer';
import { controllerReduce } from './reducers/controllerReducer';
import { statisticReducer } from './statistic/statistic-reducer';
import { modalReducer } from './modalInfo/modal-reducer';
import { currentWorkersReducer } from './reducers/currentWorkersReducer';

const rootReducer = combineReducers({
  auth: tokenReducer,
  user: userReducer,
  alerts: alertReducer,
  employers: employerReducer,
  workers: workerReducer,
  currentWorker: currentWorkerReducer,
  currentWorkers: currentWorkersReducer,
  object: objectReducer,
  controller: controllerReduce,
  statistic: statisticReducer,
  modal: modalReducer,
  cadrDocumentsReducer,
  objectsReducer,
  employersReducer,
  workersReducer,
  settingsReducer,
});
export default rootReducer;
