import { useState } from 'react';
import PassportIcon from './icons/passportIcon.svg';
import './style.css';
import axios from 'axios';

function ucFirst(str) {
  if (!str) return str;
  str = str.toLowerCase();
  return str[0].toUpperCase() + str.slice(1);
}

const PassportModal = ({ setFieldValue, setIsPassport }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isShow, setIsShow] = useState(true);
  if (!isShow) {
    return <></>;
  }
  const uploadFile = async files => {
    console.log('Uploading file...');
    setIsFetching(true);
    const API_ENDPOINT = `${process.env.REACT_APP_API_PASSPORT}/upload`;
    let formData = new FormData();
    for (let el of files) {
      await formData.append('file', el);
    }
    await axios
      .post(API_ENDPOINT, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        const { page1, page2 } = response.data;
        console.log(response.data, 'response');

        if (page1 !== null) {
          let { surname, name, patronymic, DateOnBirth, sex, Document } = page1;
          setFieldValue('surname', ucFirst(surname));
          setFieldValue('name', ucFirst(name));
          setFieldValue('patronymic', ucFirst(patronymic));
          setFieldValue('birthday', DateOnBirth);
          setFieldValue('sex', sex.includes('F') || sex.includes('Ж') ? 'Ж' : 'Ч');
          setFieldValue('passport', Document);
        }
        if (page2 !== null) {
          let { RNTRC, Authority, DataOfIssue } = page2;
          setFieldValue('dateOfIssue', DataOfIssue);
          setFieldValue('authority', Authority);
          setFieldValue('RNOKPP', RNTRC);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
    setIsShow(false);
    setIsFetching(false);
    setIsPassport(true);
  };
  const handleChangeFile = async event => {
    const files = event.target.files;
    await uploadFile(files);
  };
  const handleShow = () => {
    setIsShow(false);
    setIsPassport(true);
  };

  return (
    <div className="passport">
      {isFetching && (
        <div className={'disabled__overlay'}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="passport__wrapper">
        <div className="passport__box">
          <img src={PassportIcon} alt="" />
          <p>Додайте ID-картку, щоб отримати дані працівника</p>
        </div>
        <div className="passport__file">
          <input onChange={handleChangeFile} type="file" accept=".png, .jpg, .jpeg" multiple />
          <button className="passport__btn-download">ЗАВАНТАЖИТИ</button>
        </div>
        <button className="passport__btn-next" onClick={handleShow}>
          Пропустити
        </button>
      </div>
    </div>
  );
};

export default PassportModal;
