const formatUkrainianPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  const cleanNumber = phoneNumber.replace(/\D/g, '');

  if (cleanNumber.length === 10 && cleanNumber.startsWith('0')) {
    return `+380${cleanNumber.substring(1)}`;
  } else if (cleanNumber.length === 9) {
    return `+380${cleanNumber}`;
  } else if (cleanNumber.length === 12 && cleanNumber.startsWith('380')) {
    return `+${cleanNumber}`;
  } else if (cleanNumber.length === 11 && cleanNumber.startsWith('80')) {
    return `+380${cleanNumber.substring(2)}`;
  }

  return phoneNumber;
};

export { formatUkrainianPhoneNumber };
