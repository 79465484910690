import React, { useState, forwardRef } from 'react';
import { Box, TextField, Typography, Chip } from '@mui/material';
import { unstable_useForkRef as useForkRef } from '@mui/utils';

const EnhancedInput = forwardRef((props, ref) => {
  const {
    id,
    label,
    type = 'text',
    placeholder = '',
    disabled = false,
    readOnly = false,
    formik,
    maxLength,
    minLength = 0,
    capitalizeFirst = false,
    capitalizeAll = false,
    showWarning = false,
    warningCondition = (value) => /[A-Za-z]/.test(value),
    warningMessage = 'Поле містить латинські літери',
    showCounter = false,
    allowCyrillicOnly = false,
    sx = {},
    inputProps = {},
    error,
    setOptions = () => {},
    ...otherProps
  } = props;

  const {
    values,
    setFieldValue,
    errors,
    touched,
    onChange: formikOnChange,
  } = formik;
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    let value = event.target.value;
    if (allowCyrillicOnly) {
      value = value.replace(/[^\u0400-\u04FF\s]/g, '');
    }
    if (type === 'number' && maxLength) {
      value = value.slice(0, maxLength);
    }
    if (capitalizeAll) {
      value = value.toUpperCase();
    } else if (capitalizeFirst && value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (value.startsWith('-')) {
      value = value.replace(/^[-–]+/, '');
    }
    setFieldValue(id, value);
  };

  const handleInput = (event) => {
    if (type === 'number' && maxLength) {
      const value = event.target.value.slice(0, maxLength);
      event.target.value = value;
    }
  };

  const value = values[id] || '';
  const isWarning =
    showWarning && (warningCondition(value) || value.length < minLength);
  const isError =
    error || (touched[id] && Boolean(errors[id] || errors[id] === ''));

  const externalOnChange = inputProps.onChange;
  const combinedOnChange = (event) => {
    if (typeof externalOnChange === 'function') {
      externalOnChange(event);
    }
    handleChange(event);
    if (typeof formikOnChange === 'function') {
      formikOnChange(event);
    }
  };

  const mergedRef = useForkRef(ref, inputProps.ref);

  return (
    <Box
      sx={{
        width: sx.width || '100%',
        maxWidth: '300px',
        position: 'relative',
      }}
    >
      <TextField
        {...otherProps}
        id={id}
        inputRef={mergedRef}
        size="small"
        label={label}
        type={type}
        value={value}
        onChange={combinedOnChange}
        onInput={handleInput}
        placeholder={placeholder}
        disabled={disabled}
        error={!readOnly && isError}
        autoComplete="off"
        fullWidth
        slotProps={{
          htmlInput: {
            readOnly,
            maxLength,
            sx: {
              paddingLeft: sx?.paddingLeft ?? '12px',
              paddingRight: sx?.paddingRight ?? '12px',
              textAlign: sx.textAlign && sx.textAlign,
            },
            style: { userSelect: 'text' },
            onMouseDown: (e) => {
              e.stopPropagation();
            },
            onDoubleClick: (e) => {
              e.stopPropagation();
              e.target.select();
            },
          },
        }}
        sx={{ ...sx }}
        variant="outlined"
        color={isWarning ? 'warning' : isError ? 'error' : 'success'}
        helperText={
          <Box sx={{ minHeight: '20px' }}>
            {isError ? (
              errors[id]
            ) : isWarning ? (
              <Typography
                variant="caption"
                sx={{ color: 'warning.main' }}
              >
                {warningMessage}
              </Typography>
            ) : (
              ' '
            )}
          </Box>
        }
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          setIsFocused(false);
          setOptions([]);
        }}
        inputProps={{
          ...inputProps,
          autoComplete: 'off',
        }}
      />
      {showCounter && isFocused && (
        <Chip
          label={`${String(value).length}/${maxLength}`}
          color={String(value).length === maxLength ? 'success' : 'warning'}
          size="small"
          sx={{
            position: 'absolute',
            top: '24px',
            right: 0,
            fontSize: '8px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
          }}
        />
      )}
    </Box>
  );
});

export default EnhancedInput;
