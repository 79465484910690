import { PopUp } from '../../PopUps/PopUp';
import woman from '../../../assets/user_image.svg';
import close from '../../../assets/close_pop.svg';
import man from '../../../assets/frame_person.svg';
import './UserInfoPopUp.css';
import { useEffect, useState } from 'react';
import { get_worker } from '../api/get_worker';
import moment from 'moment';
import regions from '../../../constants/regions';
import plural from 'ru-plurals';
import { diffDate } from '../../../utilits/diffDate';
import { getDocKadri, viewFilesKadri } from '../../../api/kadriDoc';
import { get_experience } from '../../../utilits/get_experiance';
import instance from '../../../redux/instance';
import { getExperienceFromWorkerDocs } from '../../../utilits/getExperienceFromWorkerDocs';

const statusUser = {
  vacation: 'Відпустка',
  deleted: 'Звільнений',
  work: 'Працює',
  cancellation: 'Відмінений',
};

export const UserInfoPopUp = (params) => {
  const [userData, setUserData] = useState(params.selectUser);
  const [photo, setPhoto] = useState({
    generateUrl: man,
  });
  const [iconClass, setIconClass] = useState('default-icon');

  const [expEmployer, setExpEmployer] = useState();
  useEffect(() => {
    const fetchUser = () => {
      const query = userData.workerID
        ? {
            workerID: userData.workerID,
          }
        : { _id: userData._id };
      get_employer(query).then(async (res) => {
        const exp = await getDateExperience(res.data);

        setExpEmployer(exp);
      });
      get_worker({
        workerSuperior: params.workerId,
        status: userData.postInfo.currentStatus,
        _id: userData._id,
      }).then((worker) => {
        setUserData((prev) => ({ ...prev, ...worker }));
      });
    };

    fetchUser();

    if (userData.photo) {
      viewFilesKadri(
        userData._id,
        userData.photo.path,
        userData.photo.filename
      ).then((res) => {
        setPhoto(res);
        setIconClass('user-icon');
      });
    }
  }, []);

  const get_employer = async (user) => {
    return await instance.post('/employer/get_bot_info', { ...user });
  };
  const getDateExperience = async (employerList) => {
    const { all_exp } = await get_experience(employerList);
    return all_exp;
  };

  const get_status = (currentStatus) => {
    if (currentStatus === 'vacation') {
      const startDate = userData.info ? userData.info.exp.start : '';
      const endDate = userData.info ? userData.info.exp.end : '';
      return `Відпустка з ${startDate}
    по ${endDate}`;
    } else if (currentStatus === 'cancellation') {
      return statusUser[currentStatus];
    } else if (currentStatus === 'deleted') {
      const uvalLastDay = userData.postInfo.uvalLastDay;
      return statusUser[currentStatus] + ` з ${uvalLastDay}`;
    } else {
      return statusUser['work'];
    }
  };

  const get_date_accept = (date) => {
    const final_date = moment(date).format('DD.MM.YYYY');
    if (final_date === 'Invalid date') return date;
    else return final_date;
  };

  const get_street = () => {
    const street =
      userData.object.place.city +
      ', вул. ' +
      userData.object.place.street +
      ', ' +
      userData.object.houseNumber;
    return street;
  };

  const close_handler = () => {
    params.close();
  };

  return userData ? (
    <PopUp>
      <div className="user-info">
        <div className="user-info-block user-info-block-image">
          <div className="user-photo">
            <img
              className={iconClass}
              src={photo.generateUrl}
              alt="user"
            />
          </div>

          <img
            className="qr"
            src={userData.qr}
            alt="qr"
          />
          <span>
            Відскануйте код, щоб додати до контактів у своєму телефоні
          </span>
        </div>
        <div className="user-info-block-text">
          <div className="title">
            <span>Картка працівника</span>
            <div
              onClick={close_handler}
              className="close"
            >
              <img
                src={close}
                alt="close"
              />
            </div>
          </div>
          <div className="text-block">
            <div className="variable-text">
              <div className="right">
                <span>прізвище</span>
              </div>
              <div className="left">
                <span>{userData.surname ? userData.surname : '-'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>ім’я</span>
              </div>
              <div className="left">
                <span>{userData.name ? userData.name : '-'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>по батькові</span>
              </div>
              <div className="left">
                {' '}
                <span>{userData.patronymic ? userData.patronymic : '-'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>посада</span>
              </div>
              <div className="left">
                <span>
                  {userData.postInfo.status === 'deleted'
                    ? 'Посада відсутня'
                    : userData.postInfo.status}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>роботодавець</span>
              </div>
              <div className="left">
                <span>
                  {userData.userInfO ? userData.userInfO.full_name : ''}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>місце роботи</span>
              </div>
              <div className="left">
                <span>
                  {userData.postInfo.format ? userData.postInfo.format : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>регіон</span>
              </div>
              <div className="left">
                <span>
                  {userData.object ? regions[userData.object.region].name : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>адреса місця роботи</span>
              </div>
              <div className="left">
                <span>{userData.object ? get_street() : '-'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>табельний номер</span>
              </div>
              <div className="left">
                <span>
                  {userData.postInfo.more && userData.postInfo.more.tableNumber
                    ? userData.postInfo.more.tableNumber
                    : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>дата прийняття</span>
              </div>
              <div className="left">
                <span>
                  {userData.postInfo.dateAccept
                    ? get_date_accept(userData.postInfo.dateAccept)
                    : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>На посаді:</span>
              </div>
              <div className="left">
                <span>
                  {userData?.posada_exp
                    ? userData?.posada_exp
                    : expEmployer
                    ? expEmployer
                    : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>В компанії:</span>
              </div>
              <div className="left">
                <span>
                  {userData?.all_exp
                    ? userData?.all_exp
                    : expEmployer
                    ? expEmployer
                    : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>дата народження</span>
              </div>
              <div className="left">
                <span>{userData.birthday ? userData.birthday : '-'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>telegram</span>
              </div>
              <div className="left">
                {' '}
                <span>{userData.telegram ? 'Підписан' : 'Не підписан'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>робочий телефон</span>
              </div>
              <div className="left">
                {' '}
                <span>
                  {userData.workPhoneNumber ? userData.workPhoneNumber : '-'}
                </span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>особистий телефон</span>
              </div>
              <div className="left">
                {' '}
                <span>{userData.mobilePhone ? userData.mobilePhone : '-'}</span>
              </div>
            </div>
            <div className="variable-text">
              <div className="right">
                <span>статус</span>
              </div>
              <div className="left">
                <span>
                  {userData.postInfo.currentStatus
                    ? get_status(userData.postInfo.currentStatus)
                    : userData.postInfo.status
                    ? get_status(userData.postInfo.status)
                    : 'Працює'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  ) : (
    <></>
  );
};
