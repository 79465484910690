import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SButtonGrey } from '../../../../../styledComponents/Buttons';

import trash from '../../../../../assets/trash.svg';
import plus from '../../../../../assets/plus.svg';
import minus from '../../../../../assets/minus.svg';
import download from '../../../../../assets/download.svg';

import '../KadriCards.css';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import ReactTooltip from 'react-tooltip';
import PdfViewer from '../../../../../components/iframeView/iframeDoc';
import { WarningPopUp } from '../../../../PopUps/WarningPopup';
import Viewer from 'react-viewer';
import imgDoc from '../../../../../assets/imgDoc.svg';
import {
  deleteFileKadri,
  getDocKadri,
  uploadDocumentsKadri,
  viewFilesKadri,
} from '../../../../../api/kadriDoc';
import { downloadFileObject } from '../../../../../api/objects';
import submenuArrow from '../../../../../assets/submenuArrow.svg';

let documents = [
  'Заява про звільнення',
  'Паспорт',
  'Довідка про присвоєння коду',
  'Заява про прийом на роботу',
  'Заява на соціальну пільгу',
  'Заява про відпустку',
  'Листок непрацездатності',
  'Наказ про прийом на роботу',
  'Пакет документів на прийняття',
  'Договір про матеріальну відповідальність',
  'Трудова книжка',
  'Акт',
  'Наказ',
  'Лист',
  'Фото працівника',
];

const KadriPageDocument = ({ id, typeModal, typeId, values, fullName }) => {
  const [isHovered, setIsHovered] = useState();
  const [type, setType] = useState('');
  const [settingType, setSettingType] = useState(null);
  const inputRef = useRef();
  const listHover = useRef(null);
  const listRef = useRef(null);
  const docActiveList = useRef(null);
  const [documentType, setDocumentType] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [documentData, setDocument] = useState([]);
  const [urlViewed, setUrlViewed] = useState('');
  const [isBottom, setIsBottom] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [imageArray, setImageArray] = useState(null);
  const [indexArray, setActiveIndex] = useState(0);
  const user = useSelector((state) => state.user);
  const alert = useAlert();
  const dropdown = useRef();
  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };
  const onChange = (e, value, text) => {
    e.preventDefault();
    setType(value);
    text && setSettingType(text);
    inputRef.current.click();
  };

  const updateDocument = async () => {
    await getDocKadri(id).then((res) => {
      setDocument(res);
      setDocumentType([...new Set(res.map((el) => el.type))]);
    });

    listHover?.current?.querySelectorAll('ul span')?.forEach((el) => {
      if (el.nextElementSibling) {
        el.nextElementSibling.querySelectorAll('li').forEach((sub) => {
          sub.classList.add('hover');
          sub.addEventListener('click', (e) =>
            onChange(e, 'Заява про звільнення', {
              topType: el.textContent,
              subtype: e.target.textContent,
            })
          );
        });
      } else {
        el.style.cursor = 'pointer';
        el.classList.add('hover');
        el.addEventListener('click', (e) =>
          onChange(e, 'Заява про звільнення', {
            topType: e.target.textContent,
            subtype: null,
          })
        );
      }
    });
  };
  useEffect(updateDocument, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    const isHoveredOnMenu = document.querySelector(
      '.table__dropdown__hover:hover'
    );
    if (!isHoveredOnMenu) {
      setIsHovered(false);
    }
  };

  const IframeViewDocument = () => {
    return !/\.(png|gif|tif|webp|jpg|jpeg)|(.cs)/.test(urlViewed.typeFile) ? (
      !/\.(pdf)/.test(urlViewed.typeFile) ? (
        <PdfViewer url={urlViewed} />
      ) : (
        <a
          href={urlViewed.generateUrl}
          target="_blank"
        >
          <object
            type="application/pdf"
            data={
              urlViewed.generateUrl +
              '#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0'
            }
            width="100%"
            height="400"
            style={{ pointerEvents: 'none' }}
          >
            <embed
              src={
                urlViewed.generateUrl +
                '#view=Fit&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0'
              }
              type="application/pdf"
            />
          </object>
        </a>
      )
    ) : (
      <img
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
        style={{ cursor: 'pointer' }}
        src={urlViewed.generateUrl}
        alt=""
        width="100%"
      />
    );
  };

  const fileUpload = async (e) => {
    e.preventDefault();
    await setFetching(true);
    await uploadDocumentsKadri(
      [...e.target.files].map((file) => ({
        type,
        info: settingType,
        file,
      })),
      id,
      values,
      fullName,
      alertFunc
    );
    await updateDocument();
    await setFetching(false);
  };
  const ListDocument = ({ type, array }) => {
    const [openTab, setOpenTab] = useState(false);
    const toggleOpenTad = (e) => {
      e.preventDefault();
      setOpenTab(!openTab);
    };
    useEffect(async () => {
      if (typeId) {
        const result = documentData.filter((el) => el._id === typeId);
        console.log(result);
        result.length >= 1 && (await viewDocument(dropdown.current, result[0]));
      }
    }, []);
    useEffect(() => {
      if (typeModal === type) {
        setOpenTab(true);
      }
    }, [typeModal]);
    const downloadDocument = async (e, params) => {
      e.preventDefault();
      if (params.path.indexOf('Test/') !== -1) {
        params.path = params.path.replace('Test/', 'Kadri/');
      }
      const res = await downloadFileObject(
        params._id,
        params.path,
        params.filename
      );
      let link = document.createElement('a');
      link.href = res.generateUrl;
      link.click();
      link.remove();
    };
    const viewDocument = async (e, params) => {
      if (e.target) {
        e.preventDefault();
      }
      const res = await viewFilesKadri(
        params._id,
        params.path,
        params.filename
      );
      setUrlViewed(res);

      if (/\.(png|gif|tif|webp|jpg|jpeg)|(.cs)/.test(params.filename)) {
        setImageArray([
          {
            src: res.generateUrl,
            alt: '',
          },
        ]);
      }
      // Нужно как-то по другому реализовать
      if (e.target) {
        e.target
          .closest('.edit_rent-document__right')
          .querySelectorAll('.hover-underline')
          .forEach((element) => (element.style.fontWeight = '400'));
        e.target.style.fontWeight = '700';
      }
    };

    const deleteDocument = async (e, params) => {
      e.preventDefault();
      await deleteFileKadri(params._id, params.path, params.filename, id);
      await updateDocument();
      alertFunc(`Документ ${params.filename} успішно видалено`, {
        type: 'success',
      });
      setUrlViewed(null);
    };

    return (
      <div className="edit_rent-document__column">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            ref={dropdown}
            className="document__row__header"
            onClick={toggleOpenTad}
          >
            <button className={'document__row__btn'}>
              <img src={openTab ? minus : plus} />
            </button>
            <span>
              {type === 'Трудовий договір'
                ? 'Пакет документів на прийняття'
                : type}
            </span>
          </div>
        </div>
        <div
          className={`document__row__content ${openTab && 'document__active'}`}
        >
          {array
            .filter((items) => items.type === type)
            .map((doc, i) => (
              <FileInfoDoc
                filename={doc.filename}
                typeId={typeId}
                key={i}
                doc={doc}
                user={user}
                downloadDocument={(e) => downloadDocument(e, doc)}
                viewDocument={(e) => viewDocument(e, doc)}
                deleteDocument={(e) =>
                  deleteDocument(e, { ...doc, userId: user._id })
                }
              />
            ))
            .reverse()}
        </div>
      </div>
    );
  };
  const data = useMemo(
    () =>
      documentType.map((type, i) => (
        <ListDocument
          key={i}
          array={documentData}
          type={type}
        />
      )),
    [documentType, documentData]
  );

  return (
    <React.Fragment>
      {imageArray && (
        <Viewer
          visible={visible}
          zoomSpeed="0.2"
          defaultScale={1.5}
          minScale="1"
          activeIndex={indexArray}
          onClose={() => {
            setVisible(false);
          }}
          images={imageArray && imageArray}
        />
      )}
      {isFetching && (
        <div className={'disabled__overlay'}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div
        className="edit_rent__wrapper"
        style={{ justifyContent: 'space-between', height: '100%', padding: 20 }}
      >
        <div
          className="edit_rent__document__left"
          style={{
            minWidth: 300,
            maxHeight: 400,
            overflow: 'hidden',
            width: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '20px',
            boxSizing: 'content-box',
            borderRight: '1px solid #E4E4E4',
          }}
        >
          {urlViewed ? (
            <IframeViewDocument />
          ) : (
            <img
              src={imgDoc}
              alt=""
            />
          )}
        </div>
        <div className="edit_rent-document__wrapper">
          <div
            ref={docActiveList}
            className="edit_rent-document__right"
            style={{ width: '100%', paddingLeft: '50px' }}
          >
            {data}
            <div
              ref={listRef}
              className={'pos-rel'}
              style={{ width: 'max-content', marginTop: '20px' }}
            >
              <input
                disabled={false}
                onChange={fileUpload}
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                type="file"
                style={{ display: 'none' }}
                ref={inputRef}
                multiple
              />
            </div>
          </div>
          <div className="document_button_wrapper">
            <SButtonGrey
              className="document_button_add"
              onClick={(e) => {
                e.preventDefault();
                if (listRef.current.offsetTop > 300) setIsBottom(true);
                else setIsBottom(false);
              }}
              disabled={false}
              style={{
                fontSize: '13px',
                padding: '12px 18px',
                color: 'black',
                marginRight: 0,
                whiteSpace: 'no-wrap',
              }}
            >
              Додати документ
            </SButtonGrey>
            <div
              style={{
                width: '250px',
                left: 0,
                bottom: isBottom ? '40px' : '',
                boxShadow: `0px ${
                  isBottom ? '-8' : '8'
                }px 16px 0px rgb(0 0 0 / 20%)`,
                height: '300px',
                overflowX: 'hidden',
                overflowY: 'scroll',
                // // overflowX: "",
              }}
              className={'table__dropdown__content'}
            >
              {documents.map((item, index) => (
                <div className="table__dropdown__column">
                  {item === 'Заява про звільнення' ? (
                    <button
                      key={index}
                      disabled={false}
                      className={'submenu__dropdown__btn'}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {item}
                      <img src={submenuArrow} />
                    </button>
                  ) : (
                    <button
                      key={index}
                      disabled={false}
                      onClick={(e) => onChange(e, item)}
                      className={'submenu__dropdown__btn'}
                    >
                      {item}
                    </button>
                  )}
                  {/* {item === "Заява про звільнення" && (
                    
                  )} */}
                </div>
              ))}
            </div>
            <ul
              style={{
                opacity: isHovered ? 1 : 0,
                pointerEvents: isHovered ? 'all' : 'none',
              }}
              className="table__dropdown__hover"
              ref={listHover}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <li>
                <span>Звільнення за станом здоров’я:</span>
                <ul>
                  <li>алергія</li>
                  <li>вагітність</li>
                  <li>оперативне втручання</li>
                </ul>
              </li>
              <li>
                <span>Умови праці:</span>
                <ul>
                  <li>графік роботи</li>
                  <li>важко працювати</li>
                  <li>територіальне розташування</li>
                  <li>низька ЗП</li>
                </ul>
              </li>
              <li>
                <span>Переїзд</span>
              </li>
              <li>
                <span>Порушення трудової дисципліни:</span>
                <ul>
                  <li>прогул</li>
                  <li>нетверезий стан</li>
                  <li>крадіжка</li>
                </ul>
              </li>
              <li>
                <span>Виїзд за кордон</span>
              </li>
              <li>
                <span>За сімейними обставинами:</span>
                <ul>
                  <li>догляд за членом сім’ї</li>
                  <li>догляд за дітьми.</li>
                </ul>
              </li>
              <li>
                <span>Внутрішнє переведення</span>
              </li>
              <li>
                <span>Зміна ФОПа</span>
              </li>
              <li>
                <span>Конфлікт в колективі</span>
              </li>
              <li>
                <span>Знайшла роботу за фахом</span>
              </li>
              <li>
                <span>Невідповідність посаді</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const FileInfoDoc = ({
  filename,
  deleteDocument,
  downloadDocument,
  viewDocument,
  typeId,
  doc,
  user,
}) => {
  const [deleteItem, setDeletedItem] = useState(false);

  return (
    <div className="document__row__content__item">
      <span
        className={`hover-underline`}
        style={{
          fontWeight: typeId === doc._id && 600,
        }}
        onClick={viewDocument}
      >
        {filename}
      </span>
      {user._id === doc.user && (
        <button
          data-tip
          data-for={`delete${filename}`}
          style={{ marginRight: '6px' }}
          className={'document__row__icon'}
          onClick={(e) => {
            e.preventDefault();
            setDeletedItem(true);
          }}
        >
          <img src={trash} />
        </button>
      )}
      <ReactTooltip
        backgroundColor={'#52616E'}
        effect={'solid'}
        id={`delete${filename}`}
      >
        Видалити
      </ReactTooltip>
      <button
        data-tip
        data-for={`download${filename}`}
        className={'document__row__icon'}
        onClick={downloadDocument}
      >
        <img
          src={download}
          style={{ width: '14px', height: '14px' }}
        />
      </button>
      <ReactTooltip
        backgroundColor={'#52616E'}
        effect={'solid'}
        id={`download${filename}`}
      >
        Завантажити
      </ReactTooltip>

      {deleteItem && (
        <WarningPopUp
          onDelete={deleteDocument}
          toggleWarningModal={(e) => {
            e.preventDefault();
            setDeletedItem(false);
          }}
          isNewText={false}
          name={`Файл <span>${filename}</span> буде видаленно`}
        />
      )}
    </div>
  );
};

export default KadriPageDocument;
