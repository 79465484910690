import { GET_MY_WORKER_URL } from '../../../constants/urls';
import { getCookie } from '../../../utilits/cookies';
import { LOAD_EMPLOYERS } from '../../types';
import instance from '../../instance';

const loadEmployers = (alert, isGetAll) => async (dispatch) => {
  const token = getCookie('token');
  let data;
  try {
    const response = await instance.post(
      GET_MY_WORKER_URL,
      isGetAll ? { All: 'getAll' } : {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    data = await response.data;
    console.log('loadEmployers :: data >>>', data);

    const workers = data.workers.filter((worker) => {
      return worker.status !== 'deleted';
    });
    dispatch({
      type: LOAD_EMPLOYERS,
      payload: workers,
    });
  } catch (e) {
    alert(e.message, 'error');
  }
};

export default loadEmployers;
