import React, { useEffect, useState, useRef } from 'react';

import * as _ from 'lodash';
import axios from 'axios';
import * as moment from 'moment';
import closeGrey from '../../../../../assets/closeGrey.svg';
import edit from '../../../../../assets/edit.svg';
import greenEllipse from '../../../../../assets/green_ellipse.svg';
import arrowDown from '../../../../../assets/keyboard_arrow_down.svg';
import bucketSvg from '../../../../../assets/bucket.svg';
import uploadSvg from '../../../../../assets/upload.svg';
import redEllipse from '../../../../../assets/red_ellipse.svg';
import yellowEllipse from '../../../../../assets/yellow_ellipse.svg';
import { days, months } from '../../../../../utilits/getDateAndTime';
import { SButtonRed } from '../../../../../styledComponents/Buttons';

import { TextGrey } from '../../../../../styledComponents/Text';
import { SelectForm } from '../../../../../styledComponents/Inputs';
import { SButtonGrey } from '../../../../../styledComponents/Buttons';
import { KadriCardsEditAddModal } from './KadriCardsEditAddModal';
import {
  deletePosition,
  getPositions,
} from '../../../../../redux/cadrDocuments/cadrDocuments-thunks';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import editIcon from '../../../../../images/icons/edit.svg';
import MiniButton from '../../../../../components/common/MiniButton/MiniButton';
import TextInput from '../../../../../components/Inputs/TextInput/TextInput';
import deleteIcon from '../../../../../images/icons/delete.svg';
import ReactTooltip from 'react-tooltip';
import week from '../../../../../constants/week';
import SearchInput from '../../../../../components/Inputs/SearchInput/SearchInput';
import Pagination from './Pagination';
import {
  createJksDocument,
  createJksSignature,
  deleteJksDocument,
  getJksReceiver,
  getJksRecordsHistory,
  updateJksDocument,
} from '../../../../../api/users';

function fuzzysearch(needle, haystack) {
  var hlen = haystack.length;
  var nlen = needle.length;
  if (nlen > hlen) {
    return false;
  }
  if (nlen === hlen) {
    return needle === haystack;
  }
  outer: for (var i = 0, j = 0; i < nlen; i++) {
    var nch = needle.charCodeAt(i);
    while (j < hlen) {
      if (haystack.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  return true;
}

const JKS_REQUEST_STATUS = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  FAILED: 'failed',
};
const PAGE_LIMIT = 10;

export let KadriCardsJksModal = ({
  toggleJksModal,
  positions,
  getPositions,
  deletePosition,
  user,
}) => {
  const [addEditModal, setAddEditModal] = useState(false);
  const [editPosition, setEditPosition] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [recordsBeforeSearch, setRecordsBeforeSearch] = useState([]);
  const [collapsedItems, setCollapsedItems] = useState({});

  // record {
  //   documentCode
  //   name
  //   updatedAt
  //   state
  //   receiver
  // }
  const [records, setRecords] = useState([]);

  // jks pass
  const [jksPass, setJksPass] = useState('Bas147852');
  // upload jks
  const fileInputRef = useRef(null);

  const xmlFileInputRef = useRef(null);

  const triggerFileUpload = () => {
    // Simulate a click event on the file input element
    fileInputRef.current.click();
  };

  const triggerXmlFileUpload = () => {
    xmlFileInputRef.current.click();
  };

  const refreshJksRecordsTable = async () => {
    console.log(
      'currentMonth',
      currentMonth,
      moment()
        .year(currentYear)
        .month(currentMonth - 1)
        .format('YYYY-MM-DD')
    );
    const d = await getJksRecordsHistory({
      page: currentPage,
      limit: PAGE_LIMIT,
      date: moment()
        .year(currentYear)
        .month(currentMonth - 1)
        .format('YYYY-MM-DD'),
    });
    const data = d?.data || [];

    setRecords(data);
    setTotalJksRecords(d.totalPages);

    const collapsedItems = records.reduce((result, record, index) => {
      result[index] = false;
      return result;
    }, {});
    setCollapsedItems(collapsedItems);
  };

  const handleJksRecordDelete = async (record) => {
    const isOk = confirm('Підтвердити');

    if (!isOk) return;

    await deleteJksDocument(record.code);
    await refreshJksRecordsTable();
  };

  const handleXmlFileSelected = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async function (event) {
      const xmlString = event.target.result;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

      // Now you can work with the xmlDoc as shown in the previous examples
      let declarElement = xmlDoc.getElementsByTagName('DECLAR')[0];
      let schemaLocation = declarElement.getAttribute(
        'xsi:noNamespaceSchemaLocation'
      );
      console.log(schemaLocation); // Output: F3001003.xsd
      let schemaName = schemaLocation.split('.')[0];
      console.log(schemaName); // Output: F3001003
      const code = schemaName;
      const name = schemaLocation;
      // don't set up a receiver at initial step
      const receiver = null;

      await createJksDocument({ name, code, receiver });
      // await refreshJksRecordsTable();
      xmlFileInputRef.current.value = null;
    };

    reader.readAsText(file);
  };

  const handleFileSelected = async (recordCode, event) => {
    console.log('STARTED');
    const selectedFile = event.target.files[0];
    // Do something with the selected file, like upload it to a server or process it locally.
    const signature = await createJksSignature(selectedFile, jksPass);

    const res = await getJksReceiver(signature, currentYear);

    const receiver = _.get(res, '[0].nameSti', null);

    console.log('receiver', receiver);

    await updateJksDocument({
      receiver,
      code: recordCode,
      signed: true,
    });
    await refreshJksRecordsTable();

    if (!_.isEmpty(fileInputRef?.current?.value)) {
      fileInputRef.current.value = null;
    }
  };

  // jks history
  const [totalJksRecords, setTotalJksRecords] = useState(0);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);

  // date picker
  const [currentMonth, setCurrentMonth] = useState(
    new Date().getUTCMonth() + 1
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  // ****************
  const alert = useAlert();
  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };
  const canAddPosition = ['headAccountant', 'lawyer', 'headHr'].includes(
    user.userType
  );

  const clearInput = () => {
    setSearchInput('');
    setRecords(recordsBeforeSearch);
    setRecordsBeforeSearch([]);
  };

  const search = (e) => {
    const value = e.target.value?.trim();

    setSearchInput(value);

    if (!value) {
      setRecords(recordsBeforeSearch);
      setRecordsBeforeSearch([]);
      return;
    }

    const filtered = records.filter((record) => {
      return fuzzysearch(value, record.name);
    });

    if (!recordsBeforeSearch.length) {
      setRecordsBeforeSearch(records);
    }
    setRecords(filtered);
    // filter records by "Найменування"
  };

  const handlePagination = async (page) => {
    const d = await getJksRecordsHistory({ limit: PAGE_LIMIT, page });
    setCurrentPage(page);
    setRecords(d?.data || []);
  };

  const toggleAddEditModal = () => {
    if (canAddPosition) {
      setAddEditModal(!addEditModal);
    } else {
      alertFunc('Ви не можете створити посаду', 'error');
    }
  };
  const workerId = window.location.pathname.slice(
    window.location.pathname.lastIndexOf('/') + 1
  );

  useEffect(async () => {
    await refreshJksRecordsTable();
  }, [currentMonth, currentYear]);

  useEffect(async () => {
    // getPositions(workerId)
    // const d = await getJksRecordsHistory({limit: PAGE_LIMIT, page: 1});
    // const records = d.data;
    // const records = new Array(10).fill({
    //   name: 'naming',
    //   documentCode: 'ljasdjasjldas',
    //   updatedAt: new Date(),
    //   receiver: 'Blabla',
    //   state: 'PENDING'
    // })
    await refreshJksRecordsTable();

    // const collapsedItems = records.reduce((result, record, index) => {
    //   result[index] = false;
    //   return result;
    // }, {})
    // setCollapsedItems(collapsedItems)
  }, []);

  const getRecordState = (record = {}) => {
    let stateHTML = '';

    if (record.status === JKS_REQUEST_STATUS.FAILED) {
      stateHTML = (
        <div className="jksState">
          <img src={redEllipse} /> <div>Не прийнятий</div>
        </div>
      );
    } else if (record.status === JKS_REQUEST_STATUS.COMPLETED) {
      stateHTML = (
        <div className="jksState">
          <img src={greenEllipse} /> <div>Прийнятий</div>
        </div>
      );
    } else {
      stateHTML = (
        <div className="jksState">
          <img src={yellowEllipse} /> <div>В обробці</div>
        </div>
      );
    }

    return stateHTML;
  };

  return (
    <div className="doc__modal__container">
      <div className="doc__modal__header">
        <div className="modal__header__title">
          <div></div>
          {/* <TextGrey style={{textAlign: "center", marginBottom: "16px"}}>
            Редактор посад
          </TextGrey> */}
          <button
            className={'modal__close'}
            onClick={toggleJksModal}
          >
            <img src={closeGrey} />
          </button>
        </div>
        <div className="modal__header__jksStatistics">
          <div className="jksStatisticsDocuments">
            <h2>Електронний документообіг</h2>
            <div className="jksDocumentCounter">Всього: {totalJksRecords}</div>
          </div>
          <SearchInput
            placeholder="Пошук"
            onChange={(e) => {
              search(e);
            }}
            value={searchInput}
            clearInputFunc={clearInput}
          />

          <div className="jksDatePicker">
            <div className="jks_modal__header__select">
              <SelectForm
                value={currentMonth}
                onChange={(e) => setCurrentMonth(e.target.value)}
              >
                {months.map((month, index) => (
                  <option value={index + 1}>{month}</option>
                ))}
              </SelectForm>
            </div>

            <div className="jks_modal__header__select">
              <SelectForm
                value={currentYear}
                onChange={(e) => setCurrentYear(e.target.value)}
              >
                <option value={new Date().getUTCFullYear() - 1}>
                  {new Date().getUTCFullYear() - 1} рік
                </option>
                <option value={new Date().getUTCFullYear()}>
                  {new Date().getUTCFullYear()} рік
                </option>
              </SelectForm>
            </div>
          </div>

          <div>
            <input
              type="file"
              ref={xmlFileInputRef}
              style={{ display: 'none' }}
              onChange={handleXmlFileSelected}
            />
            <button
              className="addXmlFileButton"
              onClick={triggerXmlFileUpload}
            >
              <img src={uploadSvg} /> Додати
            </button>
          </div>
          <div></div>
        </div>
      </div>
      <div className="journal__modal__table journal__modal__table--scroll">
        <table
          className="table"
          style={{ fontSize: '13px', marginTop: '0' }}
        >
          <thead>
            <tr className="table-row">
              <th>Код документа</th>
              <th>Найменування</th>
              <th>Дата модифікації</th>
              <th>Стан</th>
              <th>Одержувач</th>
              <th style={{ textAlign: 'center' }}></th>
            </tr>
          </thead>
          <tbody className="jksTbody">
            {records.map((record, index) => (
              <>
                <tr
                  className="table-row"
                  key={index}
                >
                  <td>{record?.code}</td>
                  <td>{record?.name}</td>
                  <td>
                    {moment(record?.updatedAt)?.format('DD.MM.YYYY HH:mm')}
                  </td>
                  <td>{getRecordState(record)}</td>
                  <td>{record.receiver}</td>
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <div data-tip data-for={'edit' + position._id}> */}
                    <div
                      data-tip
                      data-for={`edit${index}`}
                    >
                      <MiniButton
                        disabled={!canAddPosition}
                        icon={arrowDown}
                        style={{ fontSize: '32px' }}
                        type="edit"
                        onClick={() => {
                          setCollapsedItems({
                            ...collapsedItems,
                            [index]: !collapsedItems[index],
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
                {collapsedItems[index] && (
                  <section className="signReportSection">
                    {!record.signed && (
                      <>
                        <div>
                          <div>
                            <TextInput
                              width="314px"
                              height="36px"
                              type="text"
                              name="jksPass"
                              onChange={(e) => setJksPass(e.target.value || '')}
                              value={jksPass}
                              placeholder={`Пароль`}
                            />
                          </div>

                          <div>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                handleFileSelected(record.code, event)
                              }
                            />
                            <button
                              disabled={!jksPass}
                              className="signReportButton"
                              onClick={triggerFileUpload}
                            >
                              ПІДПИСАТИ ТА ВІДПРАВИТИ
                            </button>
                          </div>
                        </div>
                        <div></div>

                        <div>
                          <button
                            className="signReportDelete"
                            onClick={() => handleJksRecordDelete(record)}
                          >
                            <img src={bucketSvg} /> <span>Видалити</span>
                          </button>
                        </div>
                      </>
                    )}
                  </section>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={handlePagination}
        itemsLength={records.length}
        totalPagesToShow={5}
        itemsPerPage={10}
      />

      {addEditModal && <div className="bg__layer"></div>}
      {(addEditModal || editPosition) && (
        <KadriCardsEditAddModal
          editPosition={editPosition}
          toggleAddEditModal={() => {
            setEditPosition(null);
            toggleAddEditModal();
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  positions: state.cadrDocumentsReducer.positions,
  user: state.user,
});

KadriCardsJksModal = connect(mapStateToProps, { getPositions, deletePosition })(
  KadriCardsJksModal
);
