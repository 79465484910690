import './style.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { InputForm } from '../../styledComponents/Inputs';
import { addressSearch, addressStreet } from '../../api/addressSearch';
import Highlight, { StrongString } from '../../utilits/hightLight';
import debounce from '../../utilits/debounce';
import { pl } from 'date-fns/locale';

const renderSuggestions = (handleSelect, value, data, count) => {
  return data.map(
    (el, i) =>
      el.description !== '' && (
        <li
          className={`google-places__item ${i === count && 'selected'}`}
          key={i}
          onClick={handleSelect(el)}
        >
          {value && (
            <StrongString search={value}>{el.description}</StrongString>
          )}
        </li>
      )
  );
};
const InputAddress = ({
  setFieldValue,
  setSearchId,
  setValueStreet,
  place,
  placeholder,
}) => {
  const rootEl = useRef(null);
  const isLoad = useRef(true);
  const [select, setSelect] = useState(false);
  const [isHide, setHide] = useState(true);
  const [dataAddress, setDataAddress] = useState([]);
  const [count, setCount] = useState(-1);
  const [value, setValue] = useState('');

  const addDataAddress = useCallback(
    debounce((value) => {
      addressSearch(value).then((res) => {
        setDataAddress(res);
      });
    }, 500),
    []
  );
  // console.log(addressSearch(value).then(res => {
  //   console.log(res)
  // }))
  const handleInput = (e) => {
    setFieldValue('place', null);
    if (e.target.value.length <= 0) {
      setHide(false);
      setSelect(true);
      addDataAddress(e.target.value);
      setValue(e.target.value);
    } else {
      addDataAddress(e.target.value);
      setSelect(true);
      setValue(e.target.value);
      setHide(true);
    }
  };
  const handleClickOutside = (event) => {
    if (rootEl.current && !rootEl.current.contains(event.target)) {
      setHide(false);
      setSelect(true);
    }
  };
  const handleKey = function (e) {
    if (e.keyCode === 38) {
      if (count > 0) {
        setCount(count - 1);
      }
    } else if (e.keyCode === 40) {
      if (count < dataAddress.length - 1) {
        setCount(count + 1);
      }
    }
    if (e.key === 'Enter' || e.keyCode === 13) {
      setFieldValue('place', dataAddress[count].place);
      setHide(false);
    }
  };
  useEffect(() => {
    if (place && isLoad.current) {
      setValue(
        Object.values({
          city_processed: place.city_processed,
          oblast: place.oblast,
          region: place.region,
          region_up: place.region_up,
        })
          .filter((el) => !!el)
          .join(', ')
      );
      setValueStreet(place.street_processed ?? place.street);
      isLoad.current = false;
    }
  }, [place]);
  const handleSelectAddress =
    ({ place, st_moniker }) =>
    () => {
      console.log(place, 'pacle');

      setSearchId(st_moniker);
      setValueStreet('');
      setFieldValue('place', place);
      setValue(
        Object.values({
          city_processed: place.city_processed,
          oblast: place.region,
          region: place.city_region,
        })
          .filter((el) => !!el)
          .join(', ')
      );
      setHide(false);
    };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <>
      {' '}
      <InputForm
        value={value}
        onChange={handleInput}
        onKeyUp={handleKey}
        placeholder={placeholder}
        className={place === null && select && 'error_border'}
      />
      {dataAddress.length >= 1 && dataAddress && (
        <ul
          ref={rootEl}
          className={`google-places__list ${isHide && 'active'}`}
        >
          {renderSuggestions(handleSelectAddress, value, dataAddress, count)}
        </ul>
      )}
      {place === null && select && (
        <div className="error">Виберіть адресу зі списку</div>
      )}
    </>
  );
};

const PlacesAutocomplete = ({ setFieldValue, isObject, place, name }) => {
  const [isHide, setHide] = useState(true);
  const [isError, setError] = useState(false);
  const [count, setCount] = useState(-1);
  const rootEl = useRef(null);
  const [searchId, setSearchId] = useState(null);

  const [dataStreet, setDataStreet] = useState([]);
  const [valueStreet, setValueStreet] = useState('');
  const handleClickOutside = (event) => {
    if (rootEl.current && !rootEl.current.contains(event.target)) {
      setHide(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const addDataStreet = useCallback(
    debounce((value) => {
      place &&
        addressStreet(place, value, searchId).then((res) => {
          setDataStreet(res);
        });
    }, 500),
    [place]
  );

  const handleInput = (e) => {
    if (e.target.value.length <= 1) {
      setHide(false);
      addDataStreet(e.target.value);
      setValueStreet(e.target.value);
      setFieldValue('place', { ...place, street: e.target.value });
    } else {
      setError(false);
      setFieldValue('place', { ...place, street: e.target.value });
      addDataStreet(e.target.value);
      setValueStreet(e.target.value);
      setHide(true);
    }
    setFieldValue('city', e.target.value);
    setFieldValue('street', e.target.value);
  };

  const handleSelectStreet =
    ({ place }) =>
    () => {
      const addressFull = Object.values({
        region_up: place.region_up,
        region: place.region,
        oblast: place.oblast,
        city_processed: place.city_processed,
        street_processed: place.street_processed,
      })
        .filter((el) => !!el)
        .join(', ');
      setFieldValue('place', place);
      setFieldValue('address', addressFull);
      setFieldValue('city', place.city_processed);
      setFieldValue('street', place.street_processed);
      setValueStreet(place.street_processed);
      setHide(false);
      setError(false);
    };
  const handleKey = function (e) {
    if (e.keyCode === 38) {
      if (count > 0) {
        setCount(count - 1);
        setValueStreet(dataStreet[count - 1].description, false);
      }
    } else if (e.keyCode === 40) {
      if (count < dataStreet.length - 1) {
        setCount(count + 1);
        setValueStreet(dataStreet[count + 1].description, false);
      }
    }
    if (e.key === 'Enter' || e.keyCode === 13) {
      setFieldValue(name, dataStreet[count].description);
      setValueStreet(dataStreet[count].description, false);
      setHide(false);
    }
  };

  return (
    <div
      className={'google-places'}
      style={{ display: 'flex' }}
    >
      <div className="google-places__input">
        {!isObject && <span>Адреса</span>}
        <InputAddress
          setSearchId={setSearchId}
          setFieldValue={setFieldValue}
          place={place}
          setValueStreet={setValueStreet}
        />
      </div>
      <div
        className="google-places__input"
        style={{ maxWidth: 200 }}
      >
        {!isObject && <span>вулиця</span>}
        <InputForm
          value={valueStreet}
          onChange={handleInput}
          onKeyUp={handleKey}
          className={isError && 'error_border'}
          onBlur={(e) => {
            if (e.target.value.length <= 1) {
              setError(true);
              setFieldValue('place', {
                ...place,
                street: '',
              });
            } else {
              if (name === 'address') {
                const addressFull = Object.values({
                  region_up: place.region_up,
                  region: place.region,
                  oblast: place.oblast,
                  city_processed: place.city_processed,
                  street_processed: place.street ?? place.street_processed,
                })
                  .filter((el) => !!el)
                  .join(', ');
                setFieldValue('address', addressFull);
              }
              setError(false);
            }
          }}
        />
        {dataStreet.length >= 1 && dataStreet && (
          <ul
            ref={rootEl}
            style={{ maxWidth: 200 }}
            className={`google-places__list ${isHide && 'active'}`}
          >
            {renderSuggestions(
              handleSelectStreet,
              valueStreet,
              dataStreet,
              count
            )}
          </ul>
        )}
        {isError && <div className="error">Введіть вулицю</div>}
      </div>
    </div>
  );
};

export default PlacesAutocomplete;
