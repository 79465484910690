import { getCookie } from '../utilits/cookies';
import {
  CREATE_PERSONAL,
  DELETE_PERSONAL,
  DOC_PERSONAL,
  EMPLOYER_PERSONAL_GETLIST,
  GET_ID_EMPLOYER_LENGTH,
  GET_PERSONAL,
  UPDATE_PERSONAL,
} from '../constants/urls';
import axios from 'axios';

export const getIdEmployerLength = async (data) => {
  try {
    const token = getCookie('token');
    const response = await fetch(GET_ID_EMPLOYER_LENGTH, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error('getIdEmployerLength :: error >>>', error);
  }
};

export const createPersonal = async (data) => {
  try {
    const token = getCookie('token');
    const response = await fetch(CREATE_PERSONAL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error('createPersonal :: error >>>', error);
  }
};

export const getPersonal = async (id) => {
  try {
    const token = getCookie('token');
    const response = await fetch(GET_PERSONAL, {
      method: 'POST',
      body: JSON.stringify({ id }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error('getPersonal :: error >>>', error);
  }
};

export const deletePersonal = async (id) => {
  try {
    const token = getCookie('token');
    const response = await fetch(DELETE_PERSONAL, {
      method: 'POST',
      body: JSON.stringify({ id: id }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error('deletePersonal :: error >>>', error);
  }
};

export const updatePersonal = async (id, update) => {
  try {
    const token = getCookie('token');
    const response = await fetch(UPDATE_PERSONAL, {
      method: 'POST',
      body: JSON.stringify({ id, update }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error('updatePersonal :: error >>>', error);
  }
};

export const getPersonalEmployerAll = async (data, accountant = undefined) => {
  try {
    const token = getCookie('token');
    const response = await axios.post(
      EMPLOYER_PERSONAL_GETLIST,
      { array: data, accountant: accountant },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('getPersonalEmployerAll :: error >>>', error);
  }
};

export const docGenerated = async (data) => {
  try {
    const token = getCookie('token');
    const response = await fetch(DOC_PERSONAL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error('docGenerated :: error >>>', error);
  }
};
