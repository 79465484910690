import { LOAD_USER_DATA } from '../types';
import { GET_MY_INFO_URL } from '../../constants/urls';
const loadUserData = token => async dispatch => {
  let data;
  try {
    const response = await fetch(GET_MY_INFO_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });
    data = await response.json();
    if (response.ok) {
      dispatch({
        type: LOAD_USER_DATA,
        payload: data.user
      });
    } else {
      throw new Error(data.message || 'Щось пішло не так');
    }
  } catch (error) {
    console.error(error);
  }
};

export default loadUserData;
