import React from 'react';

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material';

const EnhancedSelect = ({
  id,
  label,
  options = [],
  formik,
  disabled = false,
  readOnly = false,
}) => {
  const { values, setFieldValue, errors, touched } = formik;

  const isError = touched[id] && Boolean(errors[id] || errors[id] === '');

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '300px',
      }}
    >
      <FormControl
        fullWidth
        size="small"
        color="success"
        error={isError}
        disabled={disabled}
      >
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          id={id}
          value={
            options.find((option) => option.value === values[id])?.value || ''
          }
          onChange={(e) => {
            const selectedOption = options.find(
              (option) => option.value === e.target.value
            );
            setFieldValue(id, selectedOption?.value || '');
          }}
          label={label}
          readOnly={readOnly}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {touched[id] && errors[id] ? errors[id] : ' '}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default EnhancedSelect;
