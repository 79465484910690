import { ToggleButton, Stack, Autocomplete } from '@mui/material';
import EnhancedInput from './EnhancedInput';

const CombinedInputToggle = ({
  id,
  label,
  type,
  helperText = '',
  formik,
  error = false,
  toggleLabel = 'Особа без коду',
  isToggled,
  onToggleChange,
  placeholder,
  maxLength,
  disabled = false,
  readOnly = false,
  searchOptions,
  onSearchInputChange,
  renderOption,
  onChange,
  setOptions = () => {},
}) => (
  <Stack
    direction="row"
    display="flex"
    spacing={2}
  >
    {searchOptions ? (
      <Autocomplete
        freeSolo
        size="small"
        options={searchOptions}
        inputValue={formik.values[id] ? String(formik.values[id]) : ''}
        readOnly={readOnly}
        onInputChange={(event, newInputValue) => {
          if (onSearchInputChange) {
            onSearchInputChange(event, newInputValue);
          }
          formik.setFieldValue(id, newInputValue);
        }}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : `${option.surname} ${option.name} ${option.patronymic} (${
                option.RNOKPP
              }) ${option.postInfo?.currentStatus || ''}`
        }
        sx={{
          width: 142,
          '& .MuiOutlinedInput-root': {
            height: 38,
            padding: '0 12px',
          },
          '& .MuiAutocomplete-input': {
            padding: '0px',
            lineHeight: '38px',
          },
        }}
        onChange={onChange}
        renderOption={renderOption}
        renderInput={(params) => (
          <EnhancedInput
            {...params}
            id={id}
            label={label}
            type={type}
            formik={formik}
            showCounter
            maxLength={maxLength}
            disabled={isToggled || disabled}
            readOnly={readOnly}
            setOptions={setOptions}
            placeholder={placeholder}
            sx={{
              width: '142px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: isToggled && '#f8f8f8',
                '&.Mui-focused': {
                  borderColor: isToggled ? '#007D3C' : '#009640',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                },
                '&:hover': {
                  borderTopRightRadius: !isToggled && '4px',
                  borderBottomRightRadius: !isToggled && '4px',
                },
              },
              '& input': { paddingRight: 0 },
            }}
          />
        )}
      />
    ) : (
      <EnhancedInput
        id={id}
        label={label}
        type={type}
        formik={formik}
        showCounter
        maxLength={maxLength}
        disabled={isToggled}
        readOnly={readOnly}
        placeholder={placeholder}
        sx={{
          width: '142px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: isToggled && '#f8f8f8',
            '&.Mui-focused': {
              borderColor: isToggled ? '#007D3C' : '#009640',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            },
            '&:hover': {
              borderTopRightRadius: !isToggled && '4px',
              borderBottomRightRadius: !isToggled && '4px',
            },
          },
          '& input': { paddingRight: 0 },
        }}
      />
    )}
    <ToggleButton
      value="on"
      selected={isToggled}
      size="small"
      disabled={disabled || readOnly || formik.values[id]}
      onClick={() => onToggleChange(!isToggled)}
      sx={{
        height: '38px',
        width: '142px',
        lineHeight: '16px',
      }}
    >
      {toggleLabel}
    </ToggleButton>
  </Stack>
);

export default CombinedInputToggle;
