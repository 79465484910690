import React, { useEffect, useRef, useState } from 'react';
import styled from './styled.css';

import closeIcon from '../PopUps/icons/close.svg';
import calendarIcon from '../PopUps/icons/calendar.svg';
import clockIcon from '../PopUps/icons/clock.svg';
import rewardIcon from '../PopUps/icons/reward.svg';

import { MButtonGreen, MButtonOrange } from '../../../styledComponents/Buttons';
import { SButtonGreenBorder } from '../../../styledComponents/Buttons';

import DatePickerFragment from '../../../components/datePicker/datePicker';
import { useFormik, Field } from 'formik';
import * as Yup from 'yup';
import { docGenerated } from '../../../api/personal';
import { minfinSetting, pdfConv } from '../../../api/pdf';
import regions from '../../../constants/regions';
import moment from 'moment';
import typeObject from '../../../constants/typeObject';

const defaultContract = {
  weight: '',
  size: '',
  typeWork: '',
  customer: '',
  date: null,
  'time-start': null,
  'time-end': null,
};
const widthSet = (
  step = 100,
  width = 800,
  stepBig = 1000,
  lastWidth = 5000
) => {
  const arraySteps = [];
  const arrayBig = [];
  for (let i = step; i <= width; i += step) {
    arraySteps.push(i);
  }
  for (let j = stepBig; j <= lastWidth; j += 500) {
    arrayBig.push(j);
  }
  return arraySteps.concat(arrayBig);
};

export const Conditions = ({
  setIsConditional,
  landlordRight,
  personal,
  landlordInfo,
}) => {
  const [date, setDate] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [minfinSet, setMinfinSet] = useState(null);
  const [hourWork, setHourWork] = useState(1);
  const [errorSize, setErrorSize] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [timeEnd, setTimeEnd] = useState(null);
  const [contract, setContract] = useState(defaultContract);
  const [disabled, setDisabled] = useState(false);
  const validationSchema = Yup.object().shape({
    weight: Yup.string()
      .max(10, 'Досягнуто ліміт символів')
      .required('Вкажіть вагу товару'),
    typeWork: Yup.string()
      .max(200, 'Досягнуто ліміт символів')
      .required('Вкажіть вид робіт'),
    customer: Yup.string()
      .max(200, 'Досягнуто ліміт символів')
      .required('Вкажіть замовника'),
    date: Yup.string().nullable().required('Вкажіть дату'),
    'time-start': Yup.string().nullable().required('Вкажіть початок часу'),
    'time-end': Yup.string().nullable().required('Вкажіть закінчення часу'),
  });
  const ErrorMessage = ({ name, isName = true, textName }) => {
    return errors[name] && touched[name] ? (
      <div className="error">{isName ? errors[name] : textName}</div>
    ) : null;
  };
  const {
    values,
    handleSubmit,
    handleChange,
    touched,
    errors,
    setValues,
    handleBlur,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: contract,
    validationSchema,
    onSubmit: async () => {
      await setFetching(true);
      setContract(values);
      const landlord = landlordInfo.landlord.filter(
        (items) => items._id === values.customer
      )[0];
      if (personal.length <= 1) {
        await docGenerated({
          ...values,
          date: moment(values.date).format('DD.MM.YYYY'),
          dateDocx: moment(values.date).format('DD-MM-YYYY'),
          customer: landlord?.userInfO.full_name,
          'time-end': moment(values['time-end']).format('HH:mm'),
          'time-start': moment(values['time-start']).format('HH:mm'),
          typeWork:
            values.typeWork === 'Завантаження' ? 'вантаження' : 'впорядкування',
          landlord: {
            ...landlord,
            rnokpp: landlord.rnokpp,
            userInfO: {
              short_name: landlord.userInfO.short_name,
              full_name: landlord.userInfO.full_name,
              ceo_name: landlord.userInfO.ceo_name,
              location: landlord.userInfO.location,
            },
          },
          typeObject: typeObject[landlordInfo.typeObject].name,
          size: Number(values.size).toFixed(2),
          personal: personal,
          object: {
            id: landlordInfo._id,
            house: landlordInfo.houseNumber,
            address: landlordInfo.address,
            place: landlordInfo.place,
          },
          city: regions[landlordInfo.region].name,
          isPersonal: false,
        });
        const res = await pdfConv({
          name: `${personal[0].lastname} ${personal[0].name} ${
            personal[0].surname
          } ${moment(values.date).format('DD-MM-YYYY')}`,
        });
        const file = await new Blob([res.data], { type: 'application/pdf' });
        let fileURL = window.URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('target', `_blank`);
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
      } else {
        await docGenerated({
          ...values,
          date: moment(values.date).format('DD.MM.YYYY'),
          'time-end': moment(values['time-end']).format('HH:mm'),
          'time-start': moment(values['time-start']).format('HH:mm'),
          dateDocx: moment(values.date).format('DD-MM-YYYY'),
          customer: landlord?.userInfO.full_name,
          size: Number(values.size).toFixed(2),
          typeObject: typeObject[landlordInfo.typeObject].name,
          typeWork:
            values.typeWork === 'Завантаження' ? 'вантаження' : 'впорядкування',
          landlord: {
            ...landlord,
            rnokpp: landlord.rnokpp,
            userInfO: {
              short_name: landlord.userInfO.short_name,
              full_name: landlord.userInfO.full_name,
              ceo_name: landlord.userInfO.ceo_name,
              location: landlord.userInfO.location,
            },
          },
          personal: personal,
          object: {
            id: landlordInfo._id,
            address: landlordInfo.address,
            house: landlordInfo.houseNumber,
            place: landlordInfo.place,
          },
          city: regions[landlordInfo.region].name,
          isPersonal: true,
        });
        const res = await pdfConv({
          name: null,
          date: moment(values.date).format('DD-MM-YYYY'),
        });
        const file = new Blob([res.data], {
          type: 'application/zip',
        });
        const fileURL = window.URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `Договори підряду від ${moment(values.date).format('DD.MM.YYYY')}.zip`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
      }
      await setFetching(false);
      await setIsConditional(null);
    },
  });

  useEffect(async () => {
    const res = await minfinSetting();
    setMinfinSet(res.data);
    if (landlordRight?.length === 1) {
      await setFieldValue('customer', landlordInfo.landlord[0]._id);
    }
  }, []);
  useEffect(() => {
    validationSchema.isValidSync(values)
      ? setDisabled(false)
      : setDisabled(true);
  }, [errors]);
  useEffect(() => {
    setValues(contract);
  }, [contract]);
  useEffect(() => {
    if (values['time-end'] !== null && values['time-start'] !== null) {
      setHourWork(
        moment
          .utc(
            moment.duration(values['time-end']) -
              moment.duration(values['time-start'])
          )
          .hour()
      );
    }
  }, [values]);
  const handleChangeSize = (e) => {
    const value = e.target.value;
    if (
      value <= minfinSet &&
      Number(((minfinSet.settings.minzp.now / 165.56) * hourWork).toFixed(2))
    ) {
      setErrorSize(true);
    } else {
      setErrorSize(false);
    }

    setFieldValue('size', e.target.value);
  };

  return (
    <div className="personal_conditions conditions">
      {isFetching && (
        <div className={'disabled__overlay'}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="conditions__header">
        <p>Умови договору підряду</p>
        <img
          src={closeIcon}
          alt=""
          className="conditions__close-icon"
          onClick={() => setIsConditional(false)}
        />
      </div>
      <div className="conditions__content">
        <div className="conditions__content-left">
          <p>Замовник</p>
          <p>Вид робіт</p>
          <p>Дата проведення робіт</p>
          <p>Час проведення робіт</p>
          <p>Вага товару</p>
          <p>Розмір винагороди</p>
        </div>
        <div className="conditions__content-right">
          <form
            className="conditions__form"
            action="#"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="conditions__row">
              {landlordRight?.length === 1 ? (
                <input
                  type="text"
                  name="customer"
                  value={landlordRight[0].userInfO.full_name}
                  disabled
                  style={{ width: '100%' }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  tabIndex="6"
                  className="conditions__text-input"
                />
              ) : (
                <select
                  className="conditions__select-client select"
                  name="customer"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="">Виберіть зі списку</option>
                  {landlordRight.map((items, i) => (
                    <option
                      key={i}
                      value={items._id}
                    >
                      {items.userInfO?.full_name}
                    </option>
                  ))}
                </select>
              )}

              <ErrorMessage name="customer" />
            </div>
            <div className="conditions__row">
              <select
                className="conditions__select-type-of-work select"
                name="typeWork"
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Виберіть зі списку</option>
                <option value="Завантаження">Завантаження</option>
                <option value="Сортування">Сортування</option>
              </select>
              <ErrorMessage name="typeWork" />
            </div>
            <div className="conditions__row conditions__calendar">
              <DatePickerFragment
                selected={date}
                onBlur={handleBlur}
                onChange={(date) => {
                  setFieldValue('date', date);
                  setDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                className="date"
                Icon={{
                  src: calendarIcon,
                  className: 'conditions__calendar-icon',
                }}
              />
              <ErrorMessage name="date" />
            </div>
            <div className="conditions__row">
              <div className="conditions__time">
                <DatePickerFragment
                  dateFormat="HH:mm"
                  name="time-start"
                  tabIndex="4"
                  placeholderText="Початок"
                  selected={timeStart}
                  onBlur={handleBlur}
                  onChange={(date) => {
                    setFieldValue('time-start', date);
                    setTimeStart(date);
                  }}
                  timeCaption="Time"
                  showTimeSelect
                  showTimeSelectOnly
                  className="conditions__time-input"
                  Icon={{
                    src: clockIcon,
                    className: 'conditions__clock-icon--1',
                  }}
                />

                <ErrorMessage name="time-start" />
              </div>
              <p> - </p>
              <div className="conditions__time">
                <DatePickerFragment
                  dateFormat="HH:mm"
                  name="time-end"
                  tabIndex="4"
                  timeCaption="Time"
                  placeholderText="Закінчення"
                  selected={timeEnd}
                  onChange={(date) => {
                    setFieldValue('time-end', date);
                    setTimeEnd(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  className="conditions__time-input"
                  placeholder="Закінчення"
                  Icon={{
                    src: clockIcon,
                    className: 'conditions__clock-icon--2',
                  }}
                />

                <ErrorMessage name="time-end" />
              </div>
            </div>
            <div className="conditions__row">
              <select
                className="conditions__select-weight select"
                name="weight"
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Виберіть зі списку</option>
                {widthSet().map((items, i) => (
                  <option
                    key={i}
                    value={items}
                  >
                    {items}
                  </option>
                ))}
              </select>
              <ErrorMessage name="weight" />
            </div>
            <div className="conditions__row conditions__reward-row">
              <input
                type="number"
                value={values.size}
                name="size"
                tabIndex="7"
                className="size"
                onBlur={handleBlur}
                onChange={handleChangeSize}
              />
              <img
                src={rewardIcon}
                alt=""
                className="conditions__reward"
              />
              {minfinSet &&
                values.size <=
                  Number(
                    (
                      (minfinSet.settings.minzp.now / 165.56) *
                      hourWork
                    ).toFixed(2)
                  ) && (
                  <div className="error">{`Не менше = ${(
                    (minfinSet && minfinSet.settings.minzp.now / 165.56) *
                    hourWork
                  ).toFixed(2)}`}</div>
                )}
            </div>
          </form>
          <div className="conditions__buttons">
            <SButtonGreenBorder onClick={() => setIsConditional(false)}>
              Скасувати
            </SButtonGreenBorder>
            <MButtonGreen
              className={
                minfinSet &&
                (values.size <=
                  Number(
                    (
                      (minfinSet.settings.minzp.now / 165.56) *
                      hourWork
                    ).toFixed(2)
                  ) ||
                  disabled) &&
                'disabled-btn'
              }
              disabled={
                minfinSet &&
                (values.size <=
                  Number(
                    (
                      (minfinSet.settings.minzp.now / 165.56) *
                      hourWork
                    ).toFixed(2)
                  ) ||
                  disabled)
              }
              onClick={() => submitForm()}
              style={{ marginRight: 0 }}
            >
              Створити
            </MButtonGreen>
          </div>
        </div>
      </div>
    </div>
  );
};
