const transformWorkerData = (data) => {
  const padZero = (num) => (num < 10 ? '0' + num : num.toString());

  const formatBirthday = (birthdayStr) => {
    if (!birthdayStr) return '';
    const parts = birthdayStr.split(/[\s\-.]+/);
    if (parts.length !== 3) {
      return birthdayStr;
    }
    let [day, month, year] = parts;
    day = day.length === 1 ? padZero(Number(day)) : day;
    month = month.length === 1 ? padZero(Number(month)) : month;
    return `${day}.${month}.${year}`;
  };

  return {
    surname: data.surname || '',
    name: data.name || '',
    patronymic: data.patronymic || '',
    mobilePhone: data.mobilePhone || '',
    resours: '',
    RNOKPP: data.RNOKPP || 0,
    apartments: data.apartments || '',
    authority: data.authority || '',
    birthday: formatBirthday(data.birthday) || '',
    city: data.city || (data.place ? data.place.street : ''),
    curatorId: data.curatorId || null,
    dateOfIssue: data.dateOfIssue || '',
    house: data.house || '',
    idCardNumber: data.idCardNumber || '',
    methodInvite: '',
    passport: data.passport || '',
    passportNumber: data.passportNumber || '',
    selectedPassport:
      data.passport && data.passport.length === 9 ? 'ID_CARD' : 'OLD_PASSPORT',
    sex: data.sex || '',
    workForm: '',
    workPhoneNumber: data.workPhoneNumber || '',
    nationality: data.nationality || 'Україна',
    street: data.street || '',
    posada: '',
    place: {
      city: '',
      city_processed: '',
      city_region: '',
      country: '',
      id: null,
      oblast: '',
      region: '',
      street: '',
      street_processed: '',
    },
  };
};

export { transformWorkerData };
