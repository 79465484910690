import { EMPLOYER_GETLIST } from '../../../constants/urls';
import { getCookie } from '../../../utilits/cookies';
import { LOAD_WORKERS } from '../../types';
import loadCurrentWorker from '../currentWorker/loadCurrentWorker';
import { loadAllCurrentWorkers } from './loadAllCurrentWorkers';

const loadWorkers = (id, alert, isSetCurrentWorker) => async (dispatch) => {
  const token = getCookie('token');
  let data;
  try {
    const response = await fetch(EMPLOYER_GETLIST, {
      method: 'POST',
      body: JSON.stringify({ _id: id }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    data = await response.json();
    console.log('data', data);

    if (response.ok) {
      const workersList = data.workers || data.employers;
      dispatch({
        type: LOAD_WORKERS,
        payload: workersList,
      });

      const workerIds = (workersList || []).map((worker) => worker._id);

      dispatch(loadAllCurrentWorkers(workerIds));

      if (isSetCurrentWorker && data.employers && data.employers.length > 0) {
        dispatch(
          loadCurrentWorker(data.employers[data.employers.length - 1]._id)
        );
      }
    } else {
      throw Error(data.message || 'Щось пішло не так');
    }
  } catch (e) {
    alert(e.message, 'error');
  }
};

export default loadWorkers;
