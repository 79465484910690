import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import App from './App';
import rootReducer from './redux/rootReducer';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import Alert from './components/alerts/Alert';
import { theme } from './styles/theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 2500,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 9999,
  },
};

const middlewares = [thunk];

if (process.env.REACT_APP_ENV === 'development') {
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <AlertProvider
          template={Alert}
          {...options}
        >
          <App />
        </AlertProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
