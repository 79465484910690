import instance from '../instance';
import {
  setAllEmployer,
  setAllWorkerEmployer,
  setCurrentEmployer,
} from './employers-actions';

const postRequest = async (url, payload, dataKey = null) => {
  const response = await instance.post(url, payload);
  if (!response.data) {
    console.warn(`Empty response from server for request: ${url}`);
  }
  return dataKey ? response.data[dataKey] : response.data;
};

export const getEmployerById = (id) => async (dispatch) => {
  try {
    const data = await postRequest('/user/getOneWorker', { id });
    dispatch(setCurrentEmployer(data));
  } catch (e) {
    console.error('getEmployerById :: e >>>', e);
  }
};

export const getSearchEmployer = (params) => async (dispatch) => {
  try {
    let payload;
    if (
      typeof params === 'object' &&
      (params.query !== undefined || params.type !== undefined)
    ) {
      payload = { query: params.query, type: params.type };
    } else {
      payload = { workerId: params };
    }
    const employers = await postRequest(
      '/employer/getsearchemplpoyer',
      payload,
      'employers'
    );
    dispatch(setAllWorkerEmployer(employers));
  } catch (e) {
    console.error('getSearchEmployer :: e >>>', e);
  }
};

export const getSearchEmployerAll = (params) => async (dispatch) => {
  try {
    let payload;
    if (
      typeof params === 'object' &&
      (params.query !== undefined || params.type !== undefined)
    ) {
      payload = { query: params.query, type: params.type };
    } else {
      payload = { id: params };
    }
    const employers = await postRequest(
      '/employer/getsearchemplpoyerall',
      payload,
      'employers'
    );
    dispatch(setAllEmployer(employers));
  } catch (e) {
    console.error('getSearchEmployerAll :: e >>>', e);
  }
};
