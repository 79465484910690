import { SET_MODAL_INFO } from './modal-types';

const initialState = {
  info: null,
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_INFO:
      return action?.info?.id && action?.info?.data
        ? { [action?.info?.id]: action?.info?.data }
        : action;
    default:
      return state;
  }
};
