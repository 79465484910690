import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment/moment';

import closeIcon from '../../../../assets/close.svg';
import searchIcon from '../../icons/search.svg';
import { getSearchEmployer } from '../../../../redux/employers/employers-thunks';
import { addDeletedWorker } from '../../../../redux/workers/workers-thunks';

export const radixSort = (arr) => {
  if (!arr?.length) return arr;
  const maxNum = Math.max(
    ...arr.map((emp) => Number(emp.RNOKPP || 0))
  ).toString()?.length;
  let buckets = Array.from({ length: 10 }, () => []);
  let divisor = 1;

  for (let i = 0; i < maxNum; i++) {
    for (let emp of arr) {
      const num = Number(emp.RNOKPP || 0);
      buckets[Math.floor((num / divisor) % 10)].push(emp);
    }
    arr = [].concat(...buckets);
    buckets = Array.from({ length: 10 }, () => []);
    divisor *= 10;
  }
  return arr;
};

export const sortBySurname = (arr, query) => {
  return arr.sort((a, b) => {
    const lowerQuery = query.toLowerCase();
    const aStartsWith = a.surname.toLowerCase().startsWith(lowerQuery);
    const bStartsWith = b.surname.toLowerCase().startsWith(lowerQuery);

    if (aStartsWith && !bStartsWith) return -1;
    if (!aStartsWith && bStartsWith) return 1;
    return a.surname.localeCompare(b.surname);
  });
};

export const formatEmployerItem = (el, query) => {
  const highlight = (text) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<b>$1</b>');
  };

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: highlight(
          `${el.surname} ${el.name} ${el.patronymic} (${
            el.RNOKPP ? el.RNOKPP : 'без рнокпп'
          }) ${
            el.postInfo.currentStatus === 'cancellation'
              ? 'скасовано'
              : el.postInfo.currentStatus === 'deleted' ||
                moment(el.postInfo.uvalLastDay, 'DD.MM.YYYY').isBefore(
                  new Date()
                )
              ? 'звільнено'
              : el.postInfo.currentStatus === 'truancy'
              ? 'прогул'
              : el.postInfo.status === 'Неоформлений'
              ? 'Неоформлений'
              : el.postInfo.status === 'deleted'
              ? 'був видалений'
              : 'працює'
          }`
        ),
      }}
    />
  );
};

export const KadriCardsSearch = ({ toggleSearch, worker, alert }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const refInput = useRef(null);

  const employerData = useSelector(
    (state) => state.employersReducer.employerData
  );

  useEffect(() => {
    refInput.current && refInput.current.focus();
    dispatch(getSearchEmployer(worker.workerid));
  }, [dispatch, worker.workerid]);

  const filteredEmployers = useMemo(() => {
    const trimmed = search.trim();
    if (trimmed?.length < 3 || !employerData) return employerData;

    const isNumeric = /^\d+$/.test(trimmed);

    let filtered = employerData.filter((el) =>
      isNumeric
        ? el.RNOKPP?.toString().includes(trimmed)
        : el.surname.toLowerCase().includes(trimmed.toLowerCase())
    );

    if (isNumeric) {
      const startsWithTrimmed = filtered.filter((el) =>
        el.RNOKPP?.toString().startsWith(trimmed)
      );
      const containsTrimmed = filtered.filter(
        (el) => !el.RNOKPP?.toString().startsWith(trimmed)
      );

      return [...radixSort(startsWithTrimmed), ...containsTrimmed];
    }

    return sortBySurname(filtered, trimmed);
  }, [search, employerData]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectList = (e, setFieldValue, id) => {
    setSearch(e.target.textContent);
    setFieldValue('id', id);
    dispatch(addDeletedWorker({ id, ...worker }, alert, toggleSearch));
  };

  return (
    <Formik initialValues={{ id: '' }}>
      {({ setFieldValue }) => (
        <div className="search__container">
          <div className="search__header">
            <span>Дані працівника</span>
            <img
              src={closeIcon}
              alt="Close"
              onClick={toggleSearch}
            />
          </div>
          <img
            src={searchIcon}
            alt="Search"
            className="staff-search-icon"
          />
          <div
            className="search__content"
            style={{ flexDirection: 'column' }}
          >
            <div className="table__value__item__input">
              <span>Прізвище або РНОКПП</span>
              <div className="table__value__item__row">
                <input
                  value={search}
                  ref={refInput}
                  onChange={handleChange}
                  style={{ paddingLeft: 8, minWidth: 400 }}
                  id="id"
                  className="staff-search"
                  placeholder="Мінімум 3 символи"
                />
              </div>
              <button className="staff-close-btn">
                <img
                  src={closeIcon}
                  alt="Close"
                  className="staff-close-icon"
                />
              </button>
              {search?.trim()?.length >= 3 && filteredEmployers?.length > 0 && (
                <ul className="search__list">
                  {filteredEmployers.map((el) => (
                    <li
                      key={el._id}
                      onClick={(e) =>
                        handleSelectList(e, setFieldValue, el._id)
                      }
                    >
                      {formatEmployerItem(el, search)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
