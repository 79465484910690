import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { MButtonGreenBorder } from '../../../styledComponents/Buttons';
import { SpaceBetween } from '../../../styledComponents/Layout';
import { TextGrey } from '../../../styledComponents/Text';
import RightArrow from '../../../assets/rightArrow.svg';

import './KadriItem.css';
import { KadriCardsPersonEdit } from './KadriCards/CardsPersonEdit/KadriCardsPersonEdit';
import { KadriCardsSettingsEdit } from './KadriCards/CardsSettingEdit/KadriCardsSettingsEdit';
import { KadriCardsHoliday } from './KadriCards/KadriCardsHoliday';
import { KadriCardsDoc } from './KadriCards/KadriCardsDoc';
import { KadriCardsInfo } from './KadriCards/KadriCardsInfo';
import { KadriCardsNep } from './KadriCards/KadriCardsNep';
import { KadriCardsWorkDoc__2 } from './KadriCards/CardsWorkDoc/KadriCardsWorkDoc__2';
import { KadriCardsWorkDoc__3 } from './KadriCards/CardsWorkDoc/KadriCardsWorkDoc__3';
import { KadriCardsWorkDoc } from './KadriCards/CardsWorkDoc/KadriCardsWorkDoc';
import { KadriCardsDocModal } from './KadriCards/CardsDocModal/KadriCardsDocModal';
import { KadriCardsJournalModal } from './KadriCards/CardsDocModal/KadriCardsJournalModal';
import { KadriCardsPosEditor } from './KadriCards/CardsDocModal/KadriCardsPosEditor';
import { KadriCardsWageModal } from './KadriCards/CardsDocModal/KadriCardsWageModal';
import { KadriCardsWorkTimeModal } from './KadriCards/CardsDocModal/KadriCardsWorkTimeModal';
import { KadriCardsTruancy } from './KadriCards/CardsTruancy/KadriCardsTruancy';
import { KadriCardsTruancy__2 } from './KadriCards/CardsTruancy/KadriCardsTruancy__2';
import { KadriCardsWorkDoc__6 } from './KadriCards/CardsWorkDoc/KadriCardsWorkDoc__6';
import { KadriCardsPersonEdit__2 } from './KadriCards/CardsPersonEdit/KadriCardsPersonEdit__2';
import { KadriCardsJksModal } from './KadriCards/CardsDocModal/KadriCardsJksModal';
import { KadriCardsSearch } from './KadriCards/KadriCardsSearch';
import { WarningPopUp } from '../../PopUps/WarningPopup';
import DropdownButton from '../../../components/dropdowns/Dropdown/DropdownButton.js/DropdownButton';
import DropdownGreenButton from '../../../components/dropdowns/DropdownGreenButton/DropdownGreenButton';
import Table from '../../../components/tables/Table';
import TableRow from '../../../components/tables/TableRow/TableRow';
import TableCell from '../../../components/tables/TableCell/TableCell';
import { useDispatch, useSelector } from 'react-redux';
import loadWorkers from '../../../redux/actions/workers/loadWorkers';
import { useAlert } from 'react-alert';
import {
  LOAD_CURRENT_WORKER,
  LOAD_WORKERS,
  REMOVE_CURRENT_WORKER,
  REMOVE_CURRENT_WORKERS,
} from '../../../redux/types';
import loadCurrentWorker from '../../../redux/actions/currentWorker/loadCurrentWorker';
import KadriRow from './KadriRow/KadriRow';
import {
  getPositions,
  getVacations,
} from '../../../redux/cadrDocuments/cadrDocuments-thunks';
import instance from '../../../redux/instance';
import { getEmployerById } from '../../../redux/employers/employers-thunks';
import EmployersInfo from '../../../components/modals/EmployersInfo/EmployersInfo';
import loadEmployers from '../../../redux/actions/employers/loadEmployers';
import EmployersCompanyInfo from '../../../components/modals/EmployersInfo/EmployersCompanyInfo';
import { setSearchedWorker } from '../../../redux/workers/worker-actions';
import {
  addDeletedWorker,
  deleteWorker,
} from '../../../redux/workers/workers-thunks';
import * as countries from 'i18n-iso-countries';
import { setCurrentEmployer } from '../../../redux/employers/employers-actions';
import { setSettings } from '../../../redux/settings/settings-actions';
import moment from 'moment';
import KadriCardsTransfer from './KadriCards/KadriCardsTransfer';
import { KadriCardsWorkDied } from './KadriCards/CardsWorkDoc/KadriCardsWorkDied';
import { setModalInfo } from '../../../redux/modalInfo/modal-actions';
import { useWorkingConditionsData } from './KadriCards/CardsPersonEdit/cadrePageConditionsWork/hooks';

countries.registerLocale(require('i18n-iso-countries/langs/uk.json'));

export const MODAL_ID = {
  EDIT: 'personEdit',
  ADD: 'personAdd',
};

export const TAB_MODAL_ID = {
  PERSONAL_DATA: 'personData',
  WORKING_CONDITIONS: 'conditionsWork',
};

const KadriItem = () => {
  const listToggle = {
    journalModal: () => {
      openModal('journalModal');
    },
    work: () => openModal('work'),
    work__3: () => openModal('work__3'),
    work__2: () => openModal('work__2'),
    work__5: () => openModal('work__5'),
    work__6: () => openModal('work__6'),
    work_died: () => openModal('work_died'),
    transfer: () => openModal('transfer'),
    holiday: () => openModal('holiday'),
  };

  const dispatch = useDispatch();
  const alert = useAlert();

  const currentEmployer = useSelector(
    (state) => state.employersReducer.currentEmployer
  );
  const searchedWorker = useSelector(
    (state) => state.workersReducer.searchedWorker
  );
  const workers = useSelector((state) => state.workers);
  const currentWorkers = useSelector((state) => state.currentWorkers);
  const vacation = useSelector((state) => state.cadrDocumentsReducer.vacations);

  const [deleteItem, setDeleteItem] = useState(null);
  const [isAddModal, setAddModal] = useState(false); // add worker modal
  const [checkedDeleted, setCheckedDeleted] = useState(false);
  const [indexTab, setIndexTab] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const id = window.location.pathname.slice(
    window.location.pathname.lastIndexOf('/') + 1
  );

  const activeWorkers = useMemo(() => {
    return workers?.filter(
      (worker) =>
        worker?.postInfo?.currentStatus !== 'deleted' &&
        worker?.postInfo?.status !== 'deleted' &&
        worker?.postInfo?.status !== 'Неоформлений'
    );
  }, [workers]);

  useEffect(() => {
    dispatch(getVacations(id, new Date().getFullYear()));
  }, [dispatch, id, workers]);

  const GRID_TEMPLATE = '30fr 205fr 170fr 120fr 100fr 100fr';

  useEffect(() => {
    return () => {
      dispatch(setCurrentEmployer(null));
      dispatch(setSettings(null));
      dispatch({ type: LOAD_WORKERS, payload: [] });
      dispatch({ type: REMOVE_CURRENT_WORKERS });
    };
  }, [dispatch]);

  const items = [
    { title: 'Персональні дані', page: 'personData' },
    { title: 'Особова картка', page: 'specialQuota' },
    { title: 'Умови праці', page: 'conditionsWork' },
    { title: 'Документи', page: 'document' },
    { title: 'Історія', page: 'history' },
  ];
  const settingItems = [
    { title: 'Загальні', page: 'one' },
    { title: 'Розрахунок ЗП', page: 'two' },
    { title: 'Реквізити рахунків(казначейство)', page: 'three' },
  ];

  const historyPush = useHistory();
  const redirectToKadri = () => {
    historyPush.push('/home/kadri');
  };

  const openNewWorkerModal = () => {
    dispatch({ type: REMOVE_CURRENT_WORKER });
    setIndexTab(0);
    openModal('personEdit');
    setAddModal(true);
  };

  const workingConditionsData = useWorkingConditionsData({
    workerId: id || null,
    activeWorkers,
  });

  useEffect(() => {
    dispatch(loadWorkers(id, alert));
    if (instance.defaults.headers.Authorization) {
      dispatch(getPositions(id));
      dispatch(getEmployerById(id));
    }
  }, [dispatch, id]);

  const openModal = (name, value) => {
    setModals((prev) => ({
      ...prev,
      [name]: prev[name] !== false ? false : value ?? true,
    }));
  };

  const [modals, setModals] = useState({
    personAdd: false,
    personEdit: false,
    personEdit__2: false,
    settingEdit: false,
    transfer: false,
    holiday: false,
    nep: false,
    work_died: false,
    work__2: false,
    work__3: false,
    work: false,
    journalModal: false,
    docModal: false,
    editor: false,
    wageModal: false,
    workTime: false,
    jksDocument: false,
    truancy: false,
    truancy__2: false,
    work__5: false,
    work__6: false,
    search: false,
    warningModal: false,
    worker_edit: false,
  });

  const alertFunc = (message, type) => {
    alert.show(message.message, { type });
  };

  useEffect(() => {
    if (modals.personEdit && workingConditionsData) {
      dispatch(
        setModalInfo({
          id: MODAL_ID.EDIT,
          data: {
            [TAB_MODAL_ID.PERSONAL_DATA]: {},
            [TAB_MODAL_ID.WORKING_CONDITIONS]: workingConditionsData,
          },
        })
      );
    }
  }, [dispatch, modals.personEdit, workingConditionsData]);

  const openEditWorkerModal = (workerId, screen) => {
    dispatch({ type: LOAD_CURRENT_WORKER, payload: currentWorkers[workerId] });
    setModals((prev) => ({ ...prev, personEdit: true }));
  };

  const closeModal = (name) => {
    setModals((prev) => ({ ...prev, [name]: false }));
    dispatch(
      setModalInfo({
        id: 'info',
        data: null,
      })
    );
  };

  const getWorkersDOM = () => {
    return workers
      .filter(
        (el) =>
          el.postInfo?.currentStatus !== 'cancellation' &&
          !moment(el.postInfo?.uvalLastDay, 'DD.MM.YYYY').isBefore(new Date())
      )
      .map((el, index) => (
        <KadriRow
          key={index}
          el={el}
          GRID_TEMPLATE={GRID_TEMPLATE}
          index={index}
          openModal={openModal}
          openEditWorkerModal={openEditWorkerModal}
          activeRowIndex={activeRowIndex}
          isObject={
            currentEmployer &&
            currentEmployer.object?.place !== null &&
            currentEmployer.object
          }
          vacation={
            vacation && vacation.filter((items) => items.indexId === el._id)
          }
          setActiveRowIndex={setActiveRowIndex}
          setDeleteItem={setDeleteItem}
          resetDocumentModal={() => {
            setTypeModal(null);
            setTypeId(null);
            setIndexTab(0);
          }}
          workerId={id}
          isWorkersTable={true}
        />
      ));
  };

  const deletedWorkersDOM = () => {
    return workers
      .filter(
        (el) =>
          el.postInfo?.currentStatus === 'cancellation' ||
          moment(el.postInfo?.uvalLastDay, 'DD.MM.YYYY').isBefore(new Date())
      )
      .map((el, index) => (
        <KadriRow
          key={el?._id || index}
          el={el}
          GRID_TEMPLATE={GRID_TEMPLATE}
          index={index}
          activeRowIndex={activeRowIndex}
          vacation={
            vacation && vacation.filter((items) => items.indexId === el._id)
          }
          setActiveRowIndex={setActiveRowIndex}
          isObject={
            currentEmployer &&
            currentEmployer.object?.place !== null &&
            currentEmployer.object
          }
          openModal={openModal}
          openEditWorkerModal={openEditWorkerModal}
          setDeleteItem={setDeleteItem}
          resetDocumentModal={() => {
            setTypeModal(null);
            setTypeId(null);
            setIndexTab(0);
          }}
          workerId={id}
          deleted={false}
          isWorkersTable={true}
        />
      ));
  };

  const statusLength = (type) => {
    if (type === 'deleted') {
      return workers.filter(
        (el) =>
          el.postInfo?.currentStatus === 'deleted' ||
          moment(el.postInfo?.uvalLastDay, 'DD.MM.YYYY').isBefore(new Date())
      ).length;
    } else if (type === 'cancellation') {
      return workers.filter(
        (el) => el.postInfo?.currentStatus === 'cancellation'
      ).length;
    } else if (type === 'vacation') {
      return workers.filter((el) => el.postInfo?.currentStatus === 'vacation')
        .length;
    } else if (type === 'truancy') {
      return workers.filter((el) => el.postInfo?.currentStatus === 'truancy')
        .length;
    } else if (type === 'full') {
      return workers.filter((el) => el.postInfo?.status !== 'Неоформлений')
        .length;
    }
  };

  const isDropDownVisible = Object.values(modals).every(
    (value) => value === false
  );

  return (
    <div>
      <SpaceBetween style={{ alignItems: 'flex-start' }}>
        <div>
          <SpaceBetween style={{ marginTop: 0 }}>
            <MButtonGreenBorder
              className="back__btn"
              onClick={redirectToKadri}
            >
              <img
                src={RightArrow}
                alt="back"
              />
              <span>Назад</span>
            </MButtonGreenBorder>
          </SpaceBetween>
          {currentEmployer && (
            <KadriCardsInfo
              onEditClick={() => openModal('worker_edit', currentEmployer.type)}
              currentEmployer={currentEmployer}
            />
          )}
          <div
            style={{ marginTop: 32 }}
            className="kadri__item__row"
          >
            <KadriCardsDoc
              toggleSettingEdit={() => openModal('settingEdit')}
              toggleDocModal={() => openModal('docModal')}
              toggleJournalModal={() => openModal('journalModal')}
              toggleEditorModal={() => openModal('editor')}
              toggleWageModal={() => openModal('wageModal')}
              toggleWorkTimeModal={() => openModal('workTime')}
              toggleJksDocumentModal={() => openModal('jksDocument')}
            />
          </div>
        </div>
        <div style={{ width: '80%' }}>
          <SpaceBetween style={{ margin: 0 }}>
            <TextGrey>Штатні працівники</TextGrey>
            {isDropDownVisible ? (
              <div style={{ position: 'relative' }}>
                {/* <GreenButton onClick={toggleSearchModal}><span>+ Додати роботодавця</span></GreenButton>
            { searchModal && (
              <AddEmployerModal
                refreshList={getWorkers}
                toggleFunc={toggleSearchModal}
              />
            )} */}
                <DropdownGreenButton buttonText="+ Додати працівника">
                  {!modals.personEdit && (
                    <DropdownButton
                      onClick={() => {
                        openNewWorkerModal();
                        setAddModal(true);
                      }}
                    >
                      Новий працівник
                    </DropdownButton>
                  )}

                  <DropdownButton
                    onClick={() => {
                      openModal('search');
                      setAddModal(false);
                    }}
                  >
                    Раніше працював
                  </DropdownButton>
                  {/* <DropdownButton
                  onClick={() => {
                    openModal('personEdit__2');
                    setAddModal(false);
                  }}
                >
                  Вже працює
                </DropdownButton> */}
                </DropdownGreenButton>
              </div>
            ) : (
              <DropdownGreenButton buttonText="+ Додати працівника" />
            )}
          </SpaceBetween>
          <div
            className="kadri__item__row"
            style={{ marginBottom: 0 }}
          >
            <Table style={{ marginBottom: 0 }}>
              <TableRow
                type="header"
                gridTemplate={GRID_TEMPLATE}
              >
                <TableCell justify="center">№</TableCell>
                <TableCell>ПІБ</TableCell>
                <TableCell>Посада</TableCell>
                <TableCell justify="center">Кадрові події</TableCell>
                <TableCell justify="center">Cтатус</TableCell>
                <TableCell justify="center">Видалити</TableCell>
              </TableRow>
              {workers && getWorkersDOM()}
            </Table>
          </div>
          {workers && (
            <Table
              style={{ marginTop: 0, position: 'relative' }}
              className="table__row__del"
            >
              <TableRow
                type="header"
                gridTemplate={GRID_TEMPLATE}
              >
                <TableCell
                  style={{ gridColumn: 'span 5', paddingLeft: '16px' }}
                >
                  Всього за період діяльності: Прийнято:
                  <span style={{ marginRight: 5 }}>{statusLength('full')}</span>
                  Звільнено:
                  <span style={{ marginRight: 5 }}>
                    {statusLength('deleted')}
                  </span>
                  Скасовано:
                  <span>{statusLength('cancellation')}</span>
                </TableCell>
                <TableCell justify="center">
                  <div className="checkbox__deletedList">
                    <input
                      type="checkbox"
                      id="checkAllDeleted"
                      value={checkedDeleted}
                      name="checkAllDeleted"
                      onChange={(e) => setCheckedDeleted(e.target.checked)}
                      style={{ marginRight: 8, width: 16, height: 16 }}
                    />
                    <label htmlFor="checkAllDeleted">Показати архів</label>
                  </div>
                </TableCell>
              </TableRow>
              {checkedDeleted && deletedWorkersDOM()}
            </Table>
          )}
        </div>
      </SpaceBetween>
      {Object.values(modals).some((isOpened) => isOpened !== false) ||
      searchedWorker !== null ||
      deleteItem !== null ? (
        <div className="bg__layer" />
      ) : null}
      <div className="modals">
        {modals.personEdit && (
          <KadriCardsPersonEdit
            typeModal={typeModal}
            typeId={typeId}
            indexTab={indexTab}
            fullName={currentEmployer?.userInfO?.full_name}
            isAddModal={isAddModal}
            setIndexTab={setIndexTab}
            setTypeId={setTypeId}
            isPersonal={{ check: false }}
            screen={modals.personEdit}
            items={items}
            closeModal={closeModal}
            id={id}
          />
        )}
        {modals.personEdit__2 && (
          <KadriCardsPersonEdit__2
            items={items}
            id={id}
            togglePersonEdit__2={() => openModal('personEdit__2')}
          />
        )}
        {modals.settingEdit && (
          <KadriCardsSettingsEdit
            settingItems={settingItems}
            toggleSettingEdit={() => openModal('settingEdit')}
          />
        )}
        {modals.docModal && (
          <KadriCardsDocModal toggleDocModal={() => openModal('docModal')} />
        )}

        {modals.journalModal && (
          <KadriCardsJournalModal
            toggleModalList={listToggle}
            id={id}
            toggleJournalModal={() => openModal('journalModal')}
          />
        )}
        {modals.editor && (
          <KadriCardsPosEditor toggleEditorModal={() => openModal('editor')} />
        )}
        {modals.wageModal && (
          <KadriCardsWageModal toggleWageModal={() => openModal('wageModal')} />
        )}
        {modals.workTime && (
          <KadriCardsWorkTimeModal
            toggleWorkTimeModal={() => openModal('workTime')}
          />
        )}
        {modals.jksDocument && (
          <KadriCardsJksModal toggleJksModal={() => openModal('jksDocument')} />
        )}
        {modals.holiday && (
          <KadriCardsHoliday
            employerId={modals.holiday}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            toggleModalList={listToggle}
            openEditWorkerModal={openEditWorkerModal}
            toggleHoliday={() => openModal('holiday')}
          />
        )}
        {modals.nep && <KadriCardsNep toggleNep={() => openModal('nep')} />}
        {modals.work && (
          <KadriCardsWorkDoc
            workerId={id}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            toggleModalList={listToggle}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.work}
            toggleWork={() => openModal('work')}
          />
        )}
        {modals.work_died && (
          <KadriCardsWorkDied
            workerId={id}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            toggleModalList={listToggle}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.work_died}
            toggleWork={() => openModal('work_died')}
          />
        )}
        {modals.work__2 && (
          <KadriCardsWorkDoc__2
            workerId={id}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            toggleModalList={listToggle}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.work__2}
            toggleWork__2={() => openModal('work__2')}
          />
        )}
        {modals.work__3 && (
          <KadriCardsWorkDoc__3
            workerId={id}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            openEditWorkerModal={openEditWorkerModal}
            toggleModalList={listToggle}
            employerId={modals.work__3}
            toggleWork__3={() => openModal('work__3')}
          />
        )}
        {modals.truancy && (
          <KadriCardsTruancy
            workerId={id}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.truancy}
            toggleTruancy={() => openModal('truancy')}
          />
        )}

        {modals.transfer && (
          <KadriCardsTransfer
            employerId={modals.transfer}
            openEditWorkerModal={openEditWorkerModal}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            workerId={id}
            toggleModalList={listToggle}
            toggleTransfer={() => openModal('transfer')}
          />
        )}
        {modals.truancy__2 && (
          <KadriCardsTruancy__2
            workerId={id}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.truancy__2}
            toggleTruancy__2={() => openModal('truancy__2')}
          />
        )}

        {modals.work__5 && (
          <KadriCardsWorkDoc__6
            isWork__5={true}
            closeModal={() => openModal('work__5')}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            toggleModalList={listToggle}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.work__5}
          />
        )}
        {modals.work__6 && (
          <KadriCardsWorkDoc__6
            closeModal={() => openModal('work__6')}
            setIndexTab={() => setIndexTab(3)}
            setTypeModal={setTypeModal}
            setTypeId={setTypeId}
            toggleModalList={listToggle}
            openEditWorkerModal={openEditWorkerModal}
            employerId={modals.work__6}
          />
        )}
        {modals.search && workers && (
          <KadriCardsSearch
            toggleSearch={() => openModal('search')}
            worker={{ workerid: currentEmployer?._id }}
            alert={alertFunc}
          />
        )}
        {modals.warningModal && (
          <WarningPopUp toggleWarningModal={() => openModal('warningModal')} />
        )}
        {modals.worker_edit === 2 && (
          <EmployersInfo
            data={currentEmployer}
            toggleFunc={() => openModal('worker_edit')}
            getWorkersFunc={() => dispatch(loadEmployers(alertFunc))}
          />
        )}
        {modals.worker_edit === 1 && (
          <EmployersCompanyInfo
            data={currentEmployer}
            toggleFunc={() => openModal('worker_edit')}
            getWorkersFunc={() => dispatch(loadEmployers(alertFunc))}
          />
        )}
        {/* {modals.jksDocument === 1 && <KadriCardsJksModal toggleFunc={() => openModal('jksDocument')} /> } */}

        {deleteItem && (
          <WarningPopUp
            onDelete={() => {
              dispatch(deleteWorker(deleteItem._id, alertFunc, id));
              setDeleteItem(null);
            }}
            toggleWarningModal={() => setDeleteItem(null)}
            name={`${deleteItem.surname} ${deleteItem.name} ${deleteItem.patronymic}`}
          />
        )}
      </div>
    </div>
  );
};

export { KadriItem };
