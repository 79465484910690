import instanceController from '../../instanceController';
import { GET_CONTROLLER_IP } from '../../../constants/urls';
import { LOAD_CONTROLLER_IP } from '../../types';
import showAlert from '../alerts/showAlert';

const loadControllerIp = () => async dispatch => {
  try {
    const response = await instanceController.post(GET_CONTROLLER_IP);
    dispatch({
      type: LOAD_CONTROLLER_IP,
      payload: response.data
    });
  } catch (e) {
    console.error('loadControllerIp :: e >>>', e);
    dispatch(showAlert(e.message, 'error'));
  }
};
export default loadControllerIp;
