import {
  EMPLOYER_CREATENEW,
  EMPLOYER_EDITMAININFO,
} from '../../../constants/urls';
import loadWorkers from './loadWorkers';
import instance from '../../instance';
import loadCurrentWorker from '../currentWorker/loadCurrentWorker';

const addWorker =
  (form, alert, employerId, openNextTab, isEdit) => async (dispatch) => {
    try {
      const response = await instance.post(
        isEdit ? EMPLOYER_EDITMAININFO : EMPLOYER_CREATENEW,
        form
      );
      console.log('addWorker :: response >>>', response);

      if (
        response.data.message === 'Успіх' ||
        response.data.message === 'Успішно'
      ) {
        alert(response.data.message, 'success');
        dispatch(loadWorkers(employerId, alert, !isEdit));
        if (isEdit) dispatch(loadCurrentWorker(form._id));
        openNextTab();
      } else {
        throw Error(response.data.message || 'Щось пішло не так');
      }
    } catch (e) {
      console.error('addWorker :: e >>>', e);

      alert(e.message, 'error');
      return 'error';
    }
  };

export default addWorker;
