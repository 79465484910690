const decodeRnokpp = (rnokpp) => {
  const startDate = new Date(1899, 11, 31);
  const daysFromStart = parseInt(rnokpp.slice(0, 5), 10);
  const birthDate = new Date(
    startDate.getTime() + daysFromStart * 24 * 60 * 60 * 1000
  );

  const genderDigit = parseInt(rnokpp.charAt(8), 10);
  const sex = genderDigit % 2 === 0 ? 'Ж' : 'Ч';

  const year = birthDate.getFullYear();
  const month = String(birthDate.getMonth() + 1).padStart(2, '0');
  const day = String(birthDate.getDate()).padStart(2, '0');
  const formattedDate = `${day}.${month}.${year}`;

  return { birthday: formattedDate, sex };
};

export { decodeRnokpp };
