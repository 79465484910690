import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as countries from 'i18n-iso-countries';

import 'i18n-iso-countries/langs/uk.json';
import 'i18n-iso-countries/langs/en.json';
import 'i18n-iso-countries/langs/ru.json';

countries.registerLocale(require('i18n-iso-countries/langs/uk.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

const getCountryList = () => {
  const countryCodes = Object.keys(countries.getAlpha2Codes());
  return countryCodes.map((code) => ({
    code,
    label: countries.getName(code, 'uk'),
    searchLabels: [
      countries.getName(code, 'uk'),
      countries.getName(code, 'en'),
      countries.getName(code, 'ru'),
    ].filter(Boolean),
  }));
};

const EnhancedCountryAutocomplete = ({
  id,
  label,
  formik,
  disabled = false,
  readOnly = false,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const countries = getCountryList();
    setCountryList(countries);

    if (formik.values[id] === undefined && !initialized) {
      const defaultCountry = countries.find(
        (country) => country.label === 'Україна'
      );
      if (defaultCountry) {
        formik.setFieldValue(id, defaultCountry.label);
      }
      setInitialized(true);
    }
  }, [formik, id, initialized]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      formik.setFieldValue(id, newValue.label);
    } else {
      formik.setFieldValue(id, '');
    }
  };

  return (
    <Autocomplete
      id={id}
      sx={{ width: 300 }}
      options={countryList}
      autoHighlight
      disabled={disabled}
      readOnly={readOnly}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      filterOptions={(options, { inputValue }) => {
        const searchTerm = inputValue.toLowerCase();
        return options.filter((option) =>
          option.searchLabels.some((label) =>
            label.toLowerCase().includes(searchTerm)
          )
        );
      }}
      value={
        countryList.find((country) => country.label === formik.values[id]) ||
        null
      }
      onChange={handleChange}
      noOptionsText="Не знайдено"
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize: '14px' }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          disabled={disabled}
          label={label}
          error={formik.touched[id] && Boolean(formik.errors[id])}
          helperText={formik.touched[id] && formik.errors[id]}
          autoComplete="false"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default EnhancedCountryAutocomplete;
