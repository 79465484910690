import React, { useEffect, useRef, useState } from "react";
import styled from "./assets/style/style.module.css";
import "./assets/style/global.css";
import Position from "./assets/svg/emoji_people.svg";
import People from "./assets/svg/emoji_people.svg";
import Cake from "./assets/svg/cake.svg";
import Diagrams from "./assets/svg/Diagrams.svg";
import Vacation from "./assets/svg/surfing.svg";
import Store from "./assets/svg/storefront.svg";
import Trainee from "./assets/svg/school.svg";
import Question from "./assets/svg/question_mark.svg";
import FOP from "./assets/svg/fop.svg";
import Paid from "./assets/svg/paid.svg";
import Directions from "./assets/svg/directions_run.svg";
import Work from "./assets/svg/work_history.svg";
import PeopleAge from "./assets/svg/people_age.svg";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  LabelList,
  YAxis,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import CustomAllSelect from "../../components/selectCustom/customAllSelect";
import regions from "../../constants/regions";
import dateRangeSubtract from "../../utilits/dateRangeSubstract";
import { useDispatch, useSelector } from "react-redux";
import { allRegion } from "../../redux/statistic/statistic-thunks";
import moment from "moment";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { sumArray, sum } from "../../utilits/sum";
import ReactTooltip from "react-tooltip";
import Skeleton from "react-loading-skeleton";
import plural from "ru-plurals";

/**
 * Компонент для горизонтального «міні-графіка» (аналогічно до Середнього стажу),
 * але для показника "Магазинів".
 */
const StoreVerticalChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>Немає даних</p>;
  }
  const maxNumber = Math.max.apply(
    null,
    data.map((el) => el.value)
  );

  return (
    <ul>
      {data
        .sort((a, b) => b.value - a.value)
        .map((el, i) => {
          const percent = (el.value / maxNumber) * 100;
          return (
            <li key={i} style={{ display: "flex" }}>
              <div style={{ width: 128 }} className={styled.experience__line}>
                <div style={{ width: `${percent}%` }}></div>
              </div>
              <div className={styled.experience__right}>
                <span
                  style={{
                    minWidth: 100,
                    marginRight: 8,
                    textAlign: "center",
                  }}
                >
                  {el.value}
                </span>
                <p style={{ wordWrap: "break-word" }}>{el.name}</p>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

const Statistic = ({ user }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false); // "true" в цьому коді означає "дані завантажені" в оригіналі
  const [indexTab, setTabIndex] = useState(0);
  const tabList = useRef(null);

  const data = useSelector((state) => state.statistic.data);
  const all = useSelector((state) => state.statistic.all);

  const [region, setRegion] = useState(
    user.userType === "hr" || user.userType === "regionalManager"
      ? typeof user.region === "string"
        ? [user.region]
        : user.region
      : Object.keys(regions)
  );
  const [name, setName] = useState("workers");

  const COLORS = ["#546BE7", "#58C9D0", "#77D058", "#A8A968", "#D05858", "#AA26CB"];

  // Кількість днів для «плинності»/«укомплектованості»
  const [days, setDays] = useState(30);

  // Для демо: дані для лінійного графіка
  const [dateArray, setDateArray] = useState(
    dateRangeSubtract("DD.MM", 2, 30 / 2).map((el, i) => {
      return {
        name: el,
        uv: 1000 + 100 * i,
      };
    })
  );

  // Опції для select (регіони + "Мережа")
  const optionsRegions = Object.keys(regions)
    .map((el) => {
      return {
        label: regions[el].name,
        value: el,
      };
    })
    .concat([
      {
        label: "Мережа",
        value: "local",
      },
    ]);

  const handleChangeRegion = (data) => {
    if (data.value === "local") {
      setRegion(Object.keys(regions));
    } else {
      setRegion([data.value]);
    }
  };

  const handleChange = (data) => {
    if (data.value === "1") {
      setDateArray(
        dateRangeSubtract("DD.MM", 2, 30 / 2).map((el, i) => {
          return {
            name: el,
            uv: 1000 + 100 * i,
          };
        })
      );
      setDays(30);
    } else if (data.value === "3") {
      setDateArray(
        dateRangeSubtract("DD.MM", 5, 90 / 5).map((el, i) => {
          return {
            name: el,
            uv: 1000 + 100 * i,
          };
        })
      );
      setDays(90);
    } else if (data.value === "6") {
      setDateArray(
        dateRangeSubtract("DD.MM", 15, 180 / 15).map((el, i) => {
          return {
            name: el,
            uv: 1000 + 100 * i,
          };
        })
      );
      setDays(180);
    } else if (data.value === "365") {
      setDateArray(
        dateRangeSubtract("DD.MM", 1, 12, "month").map((el, i) => {
          return {
            name: el,
            uv: 1000 + 100 * i,
          };
        })
      );
      setDays(365);
    }
  };

  // Кешування на 5 хвилин
  const [lastFetchTime, setLastFetchTime] = useState(0);

  useEffect(() => {
    const now = Date.now();
    // Якщо дані ще не завантажувались або пройшло більше 5 хвилин — тягнемо з бекенду
    if (!lastFetchTime || now - lastFetchTime > 5 * 60 * 1000) {
      dispatch(allRegion(region, days, indexTab, name, setLoading))
        .then(() => {
          // Якщо allRegion повертає проміс, то onFulfilled
          setLastFetchTime(Date.now());
        })
        .catch((err) => {
          // Обробка помилок
          console.error("Помилка завантаження даних:", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, region, indexTab, name]); // Додано indexTab, щоб враховувалося переключення вкладок

  const handleSelect = (index, event) => {
    setTabIndex(index);
    setName(event.target.dataset.name);
  };

  // Форматування стажу
  const getDateExperience = (data, isYear = false) => {
    const year = plural("рік", "роки", "років");
    const month = plural("місяць", "місяці", "місяців");
    const dateStart = moment().subtract(
      Math.round(sumArray(data.map((el) => el.experience)) / data.length),
      "days"
    );
    const diff = moment.duration(moment().diff(dateStart));
    const numberYears = diff.years();
    const numberMonths = diff.months();
    return isYear
      ? `${year(numberYears)}`
      : numberYears === 0
      ? `${month(numberMonths)}`
      : `${year(numberYears)} ${numberMonths === 0 ? "" : month(numberMonths)}`;
  };

  // Форматування віку
  const getDateAge = (number) => {
    const year = plural("рік", "роки", "років");
    return year(number);
  };

  // Кастомні підписі/підказки для різних графіків
  const renderCustomizedLabelTop = (props) => {
    const ref = useRef();
    const [widthText, setWidthText] = useState(0);
    const widthBackground = 18;
    const yLabel = -3;
    const { x, y, width, value } = props;

    useEffect(() => {
      setWidthText(ref.current.getBBox().width);
    }, []);

    return (
      <g transform={`translate(${x + width / 2}, ${y - 32})`}>
        {Math.sign(value) === 1 ? (
          widthText && (
            <svg
              y={yLabel}
              x={-(widthText + widthBackground) / 2}
              width={widthText + widthBackground}
              height="17"
              viewBox={`0 0 ${widthText + widthBackground} 17`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width={widthText + widthBackground}
                height="17"
                rx="6"
                fill="#E6F4EA"
              />
              <path
                d="M8.5 4.5V10.5875L11.3 7.7875L12 8.5L8 12.5L4 8.5L4.7 7.7875L7.5 10.5875L7.5 4.5H8.5Z"
                fill="#137333"
              />
            </svg>
          )
        ) : value === 0.0 ? (
          <svg
            y={yLabel}
            width={widthText + widthBackground - 10}
            x={-((widthText + widthBackground - 10) / 2)}
            height="17"
            viewBox={`0 0 ${widthText + widthBackground - 10} 17`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width={widthText + widthBackground - 10}
              height="17"
              rx="6"
              fill="#EDEDED"
            />
          </svg>
        ) : (
          <svg
            y={yLabel}
            x={-(widthText + widthBackground) / 2}
            width={widthText + widthBackground}
            height="17"
            viewBox={`0 0 ${widthText + widthBackground} 17`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width={widthText + widthBackground}
              height="17"
              rx="6"
              fill="#FCE8E6"
            />
            <path
              d="M7.5 12.5V6.4125L4.7 9.2125L4 8.5L8 4.5L12 8.5L11.3 9.2125L8.5 6.4125V12.5H7.5Z"
              fill="#C5221F"
            />
          </svg>
        )}
        <text
          ref={ref}
          x={value === 0.0 ? 0 : 6}
          y="7"
          fill={
            Math.sign(value) !== 1
              ? value === 0.0
                ? "#3C4043"
                : "#C5221F"
              : "#137333"
          }
          fontSize={12}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value.toFixed(0).replace("-", "")}%
        </text>
      </g>
    );
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 8;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fontSize={13}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {indexTab === 0 ? value : `${value}%`}
        </text>
      </g>
    );
  };

  // Підказка для графіка "Працівники"
  const CustomTooltipWorkers = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.custom__tooltip}>
          <div className={styled.custom__tooltip__grid}>
            {payload[0].payload.position.length !== 0 && (
              <div>
                <p className="label" style={{ marginBottom: 10 }}>
                  Всьго працівників: <strong>{payload[0].payload.worker}</strong>
                </p>
                <div className={styled.custom__tooltip__wrapper}>
                  <div className={styled.custom__tooltip__list}>
                    {payload[0].payload.position
                      .sort((a, b) => b.count - a.count)
                      .map((item, i) => (
                        <p className="label" key={i}>
                          <strong
                            style={{
                              minWidth: 30,
                              textAlign: "right",
                              marginRight: 4,
                            }}
                          >
                            {item.count}
                          </strong>
                          {item.position}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {payload[0].payload.positionTrainee.length !== 0 && (
              <div>
                <p className="label" style={{ marginBottom: 10 }}>
                  Всьго стажерів:{" "}
                  <strong>{payload[0].payload.trainee}</strong>
                </p>
                <div className={styled.custom__tooltip__wrapper}>
                  <div className={styled.custom__tooltip__list}>
                    {payload[0].payload.positionTrainee
                      .sort((a, b) => b.count - a.count)
                      .map((item, i) => (
                        <p className="label" key={i}>
                          <strong
                            style={{
                              minWidth: 30,
                              textAlign: "right",
                              marginRight: 4,
                            }}
                          >
                            {item.count}
                          </strong>
                          {item.position}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {
              <div>
                <p className="label" style={{ marginBottom: 10 }}>
                  Середній стаж працівників:{" "}
                  <strong>
                    {getDateExperience([
                      { experience: payload[0].payload.experience },
                    ])}
                  </strong>
                </p>
                {payload[0].payload.employerObject.length >= 1 ? (
                  <div>
                    <ul>
                      {payload[0].payload.employerObject.map((el, i) => (
                        <li
                          style={{
                            display: "flex",
                            width: "max-content",
                            gap: 8,
                          }}
                          key={i}
                        >
                          <div>
                            {el.surname} {el.name}
                          </div>
                          <div>({el.position.title})</div>
                          <div>
                            {getDateExperience([{ experience: el.days }])}{" "}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : payload[0].payload.experienceTop.length >= 1 ? (
                  <div>
                    <p className="label" style={{ marginBottom: 8 }}>
                      Топ 5:
                    </p>
                    <ul>
                      {payload[0].payload.experienceTop.map((el, i) => (
                        <li
                          style={{
                            display: "flex",
                            width: "max-content",
                            gap: 8,
                          }}
                          key={i}
                        >
                          <div>{el.code}</div>
                          <div>
                            {el.surname} {el.name}
                          </div>
                          <div> {el.position.title} </div>
                          <div>
                            {getDateExperience([{ experience: el.days }])}{" "}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            }
            {payload[0].payload.birthdayAge !== 0 && (
              <div>
                <p className="label">
                  Середній вік працівників:{" "}
                  <strong>{getDateAge(payload[0].payload.birthdayAge)}</strong>
                </p>
                <div className={styled.tooltips__widget__wrapper}>
                  <div
                    style={{
                      display: "flex",
                      height: 150,
                      width: 150,
                      marginRight: 32,
                      marginTop: 16,
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={200} height={200}>
                        <Pie
                          data={payload[0].payload.ageRange}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabelDiagrams}
                          outerRadius={75}
                          minAngle={10}
                          fill="#8884d8"
                          dataKey="percent"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <ul className={styled.tooltips__widget__list}>
                    {payload[0].payload.ageRange.map((el, i) => (
                      <li style={{ display: "flex" }} key={i}>
                        <div
                          style={{
                            width: 12,
                            height: 12,
                            borderRadius: "100%",
                            background: COLORS[i],
                          }}
                        ></div>
                        <p>{el.name}</p> <span>{el.label}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderCustomizedLabelDiagrams = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={"white"}
        fontWeight={700}
        fontSize={13}
        textAnchor={"middle"}
        dominantBaseline="middle"
      >
        {value !== 0 && `${value}%`}
      </text>
    );
  };

  // Підказка для "Прийнято/Звільнено"
  const CustomTooltip = ({ active, payload, type }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.custom__tooltip}>
          <>
            <p className="label" style={{ textAlign: "center" }}>
              За період з {moment().subtract(days, "day").format("DD.MM.YYYY")} по{" "}
              {moment().format("DD.MM.YYYY")}
            </p>
            <br />
          </>
          <div className={styled.custom__tooltip__grid}>
            {payload[0].payload[type].length !== 0 && (
              <div>
                {indexTab !== 0 && (
                  <p className="label" style={{ marginBottom: 10 }}>
                    Було звільнено: <strong>{payload[0].payload.dismissed}</strong>
                  </p>
                )}
                <div className={styled.custom__tooltip__wrapper}>
                  <div className={styled.custom__tooltip__img}>
                    <img src={Directions} alt="" />
                  </div>
                  <div className={styled.custom__tooltip__list}>
                    {payload[0].payload[type]
                      .sort((a, b) => b.count - a.count)
                      .map((item, i) => (
                        <p className="label" key={i}>
                          <strong
                            style={{
                              minWidth: 30,
                              textAlign: "right",
                              marginRight: 4,
                            }}
                          >
                            {item.count}
                          </strong>
                          {item.position}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {payload[0].payload.dismissedInfo.length !== 0 && (
              <div>
                <p className="label" style={{ marginBottom: 10 }}>
                  Причини звільнення:
                </p>
                <div className={styled.custom__tooltip__wrapper}>
                  <div className={styled.custom__tooltip__img}>
                    <img src={Question} alt="" />
                  </div>
                  <div className={styled.custom__tooltip__list}>
                    {payload[0].payload.dismissedInfo
                      .sort((a, b) => b.value - a.value)
                      .map((item, i) => (
                        <p className="label" key={i}>
                          <strong
                            style={{
                              minWidth: 30,
                              textAlign: "right",
                              marginRight: 4,
                            }}
                          >
                            {item.value}
                          </strong>
                          {item.type}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {Object.keys(payload[0].payload.positionRangeWork).length !== 0 && (
              <div>
                <p className="label" style={{ marginBottom: 10 }}>
                  Було прийнято: <strong>{payload[0].payload.accept}</strong>
                </p>
                <div className={styled.custom__tooltip__wrapper}>
                  <div className={styled.custom__tooltip__img}>
                    <img src={Position} alt="" />
                  </div>
                  <div className={styled.custom__tooltip__list}>
                    {payload[0].payload.positionRangeWork
                      .sort((a, b) => b.count - a.count)
                      .map((item, i) => (
                        <p className="label" key={i}>
                          <strong
                            style={{
                              minWidth: 30,
                              textAlign: "right",
                              marginRight: 4,
                            }}
                          >
                            {item.count}
                          </strong>
                          {item.position}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  // Підказка для "Укомплектованість"
  const CustomTooltipStaffing = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.custom__tooltip}>
          <div
            className={styled.custom__tooltip__staffing__top}
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ marginBottom: 4 }}>Укомплектованість штату</p>
            <div className={styled.custom__tooltip__staffing__wrapper}>
              <p>
                План згідно ШР:
                <span>{payload[0].payload.staffingAll}</span>
              </p>
              <p>
                Факт згідно ШР:
                <span>
                  {payload[0].payload.staffingAll -
                    payload[0].payload.dismissedInfoMinMax.max}
                </span>
              </p>
              <p>
                Факт з урахуванням перевищення:{" "}
                <span>{payload[0].payload.sumWorker}</span>
              </p>
            </div>
          </div>
          <div className={styled.custom__tooltip__staffing}>
            {payload[0].payload.different.max.length >= 1 && (
              <div className={styled.custom__tooltip__staffing__box}>
                <p className="label" style={{ marginBottom: 4 }}>
                  Відкритих вакансій{" "}
                  <strong>{payload[0].payload.dismissedInfoMinMax.max}</strong>{" "}
                  одиниць, з яких:
                </p>
                <div className={styled.custom__tooltip__list}>
                  {payload[0].payload.different.max
                    .sort((a, b) => b.quality - a.quality)
                    .map((item, i) => (
                      <p className="label" key={i}>
                        <strong style={{ width: 20, display: "flex" }}>
                          {item.quality}
                        </strong>
                        {item.position}
                      </p>
                    ))}
                </div>
              </div>
            )}
            {payload[0].payload.different.min.length >= 1 && (
              <div
                className={`${styled.custom__tooltip__staffing__box} ${styled.custom__tooltip__staffing__box__red}`}
              >
                <p className="label" style={{ marginBottom: 4 }}>
                  Перевищення плану на{" "}
                  <strong>
                    {Math.abs(payload[0].payload.dismissedInfoMinMax.min)}
                  </strong>{" "}
                  одиниць, з яких:
                </p>
                <div className={styled.custom__tooltip__list}>
                  {payload[0].payload.different.min
                    .sort((a, b) => b.quality - a.quality)
                    .map((item, i) => (
                      <p className="label" key={i}>
                        <strong style={{ width: 20, display: "flex" }}>
                          {Math.abs(item.quality)}
                        </strong>
                        {item.position}
                      </p>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  // Компонент-список для вертикальних «смуг» стажу
  const ExperienceVerticalChart = ({ data }) => {
    const maxNumber = Math.max.apply(
      null,
      data.map((el) => el.experience)
    );

    return (
      <ul>
        {data
          .sort((a, b) => b.experience - a.experience)
          .map((el, i) => {
            const percent = (el.experience / maxNumber) * 100;

            return (
              <li key={i} style={{ display: "flex" }}>
                <div style={{ width: 128 }} className={styled.experience__line}>
                  <div style={{ width: `${percent}%` }}></div>
                </div>

                <div className={styled.experience__right}>
                  <span
                    style={{
                      minWidth: 100,
                      marginRight: 8,
                      textAlign: "center",
                    }}
                  >
                    {getDateExperience([{ experience: el.experience }])}
                  </span>
                  <p style={{ wordWrap: "break-word" }}>{el.name}</p>
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  const birthdayList = ({ birthdayInfo }) => {
    return Object.keys(birthdayInfo).map((el) => (
      <li key={el}>
        <p style={{ marginBottom: 4 }}>{el}:</p>
        <ul style={{ paddingLeft: 20 }}>
          {birthdayInfo[el].map((item, idx) => (
            <li key={idx}>
              <p>
                {item.surname} {item.name} ({item.position.title}){" "}
                {region.length !== 1 && `(${item.code})`}
                <strong>
                  {" "}
                  {getDateExperience(
                    [
                      {
                        experience: moment().diff(
                          moment(item.birthday, "DD.MM.YYYY").subtract(1, "year"),
                          "days"
                        ),
                      },
                    ],
                    true
                  )}
                </strong>
              </p>
            </li>
          ))}
        </ul>
      </li>
    ));
  };

  return (
    <div className={styled.container}>
      {user?.userType !== "hr" && user?.userType !== "regionalManager" && (
        <div className={styled.container__center} style={{ marginBottom: 12 }}>
          <div
            className={styled.select}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              maxWidth: 240,
              width: "100%",
              textAlign: "center",
            }}
          >
            <CustomAllSelect
              placeholder="123"
              paddingValue={10}
              disabled={false}
              textSize={16}
              scrollHeight={320}
              defaultValue={optionsRegions[optionsRegions.length - 1]}
              changeFn={handleChangeRegion}
              isChange={false}
              options={optionsRegions}
            />
          </div>
        </div>
      )}

      <div className={styled.container__widget__grid} style={{ marginBottom: 12 }}>
        <div
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          data-tip
          data-for={`workers`}
          style={{
            gridColumn: 1,
          }}
        >
          <div className={styled.container__widget__image}>
            <img src={People} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Працівників</h4>
            {isLoading ? (
              <span>{all && all.workerAll}</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Працівників */}
        {isLoading && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`workers`}
            effect={"solid"}
            place={"right"}
            backgroundColor={"white"}
            className={`${styled.tooltips__widget} ${styled.tooltips__widget__workers}`}
          >
            <ul>
              {all &&
                all.workerPosition.map((el, i) => (
                  <li
                    key={i}
                    style={{
                      maxWidth: 400,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        width: 40,
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: 8,
                      }}
                    >
                      {el.count}
                    </span>{" "}
                    {el.position}
                  </li>
                ))}
            </ul>
          </ReactTooltip>
        )}

        <div
          data-tip
          data-for={`staffing`}
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{ gridRow: 1, gridColumn: 2 }}
        >
          <div className={styled.container__widget__image}>
            <img src={Diagrams} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Укомплектованість</h4>
            {isLoading ? (
              <span>{all && all.staffing.sumInfoStaffing.toFixed(2)}%</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Укомплектованість */}
        {isLoading && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`staffing`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={`${styled.tooltips__widget}`}
          >
            {all && (
              <>
                <div
                  className={styled.custom__tooltip__staffing__top}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <p style={{ marginBottom: 4 }}>Укомплектованість штату</p>
                  <div className={styled.custom__tooltip__staffing__wrapper}>
                    <p>
                      План згідно ШР:
                      <span>{all.staffing.plan}</span>
                    </p>
                    <p>
                      Факт згідно ШР:
                      <span>{all.staffing.fact}</span>
                    </p>
                    <p>
                      Факт з урахуванням перевищення:{" "}
                      <span>{all.staffing.sumWorker}</span>
                    </p>
                  </div>
                </div>
                <div className={styled.custom__tooltip__staffing}>
                  {all.staffing.different.max.length >= 1 && (
                    <div className={styled.custom__tooltip__staffing__box}>
                      <p className="label" style={{ marginBottom: 4 }}>
                        Відкритих вакансій{" "}
                        <strong>{all.staffing.dismissedInfoMinMax.max}</strong>{" "}
                        одиниць, з яких:
                      </p>
                      <div className={styled.custom__tooltip__list}>
                        {all.staffing.different.max
                          .sort((a, b) => b.quality - a.quality)
                          .map((item, i) => (
                            <p className="label" key={i}>
                              <strong
                                style={{
                                  width: 25,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginRight: 4,
                                }}
                              >
                                {item.quality}
                              </strong>
                              {item.position}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                  {all.staffing.different.min.length >= 1 && (
                    <div
                      className={`${styled.custom__tooltip__staffing__box} ${styled.custom__tooltip__staffing__box__red}`}
                    >
                      <p className="label" style={{ marginBottom: 4 }}>
                        Перевищення плану на{" "}
                        <strong>{all.staffing.dismissedInfoMinMax.min}</strong>{" "}
                        одиниць, з яких:
                      </p>
                      <div className={styled.custom__tooltip__list}>
                        {all.staffing.different.min
                          .sort((a, b) => b.quality - a.quality)
                          .map((item, i) => (
                            <p className="label" key={i}>
                              <strong
                                style={{
                                  width: 25,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginRight: 4,
                                }}
                              >
                                {Math.abs(item.quality)}
                              </strong>
                              {item.position}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </ReactTooltip>
        )}

        <div
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          data-tip
          data-for={`fluidity`}
          style={{ gridRow: 1, gridColumn: 3 }}
        >
          <div className={styled.container__widget__image}>
            <img src={Directions} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>
              Плинність
              <br />
              <span>({days} днів)</span>
            </h4>
            {isLoading ? (
              <span>{all && all.fluidity.toFixed(2)}%</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Плинність */}
        {isLoading && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`fluidity`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={`${styled.tooltips__widget}`}
          >
            <>
              {all && (
                <p
                  className="label"
                  style={{ textAlign: "center", maxWidth: 445, margin: "0 auto" }}
                >
                  За період з {moment().subtract(days, "day").format("DD.MM.YYYY")} по{" "}
                  {moment().format("DD.MM.YYYY")} було звільнено{" "}
                  <strong>{sum(all.dismissedInfo.array.map((el) => el.value))}</strong> працівників
                  <br />
                  з наступних причин:
                </p>
              )}
              <br />
            </>
            {all && all.dismissedInfo.array.length >= 1 && (
              <ul>
                {all.dismissedInfo.array
                  .sort((a, b) => b.value - a.value)
                  .map((el, i, array) => {
                    const maxNumber = Math.max.apply(
                      null,
                      array.map((elem) => elem.value)
                    );
                    const percent = (el.value / maxNumber) * 100;
                    return (
                      <li key={i} style={{ display: "flex" }}>
                        <div style={{ width: 128 }} className={styled.experience__line}>
                          <div style={{ width: `${percent}%` }}></div>
                        </div>

                        <div
                          className={styled.experience__right}
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            columnGap: 16,
                            alignItems: "center",
                          }}
                        >
                          <p style={{ maxWidth: 500, wordWrap: "break-word" }}>
                            {el.type}
                          </p>
                          <span style={{ width: 20, display: "flex", justifyContent: "center" }}>
                            {el.value}
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </ReactTooltip>
        )}

        <div className={`${styled.container__widget__item} ${styled.container__widget__box}`}>
          <div className={styled.container__widget__image}>
            <img src={Vacation} alt="" />
          </div>
          <div
            className={styled.container__widget__image__text}
            style={{ gridRow: 3, gridColumn: 3 }}
          >
            <h4>У відпустці</h4>
            <span>-</span>
          </div>
        </div>

        <div
          data-tip
          data-for={`birthday`}
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{ gridRow: 3, gridColumn: 4 }}
        >
          <div className={styled.container__widget__image}>
            <img src={Cake} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Іменинників</h4>
            {isLoading ? (
              <span>{all && all.birthdayAll}</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Іменинників */}
        {isLoading && all && all.birthdayAll !== 0 && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`birthday`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={`${styled.tooltips__widget}`}
          >
            <div style={{ marginBottom: 16 }}>
              <p className="label" style={{ marginBottom: 16 }}>
                Сьогодні свій день народження святкують:{" "}
                <strong>{all.birthdayAll}</strong>
              </p>
              <ul>{birthdayList(all)}</ul>
            </div>
          </ReactTooltip>
        )}

        <div
          className={`${styled.container__widget__item} ${styled.container__widget__history} ${styled.container__widget__item__column} ${styled.container__widget__box}`}
          style={{ gridColumn: "1/3", gridRow: "2/4" }}
        >
          <h4>Рух кадрів</h4>
          <table>
            <tbody>
              <tr className="deleted">
                <td style={{ fontWeight: 500, marginRight: 2 }}>26.02</td>
                <td style={{ fontWeight: 500 }}>12:52</td>
                <td>СуШев</td>
                <td>прийняття</td>
                <td>Касир торговельного залу</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 500, marginRight: 2 }}>26.02</td>
                <td style={{ fontWeight: 500 }}>12:51</td>
                <td>СуШев</td>
                <td>прийняття</td>
                <td>Касир торговельного залу</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 500, marginRight: 2 }}>26.02</td>
                <td style={{ fontWeight: 500 }}>12:50</td>
                <td>СуШев</td>
                <td>прийняття</td>
                <td>Касир торговельного залу</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 500, marginRight: 2 }}>26.02</td>
                <td style={{ fontWeight: 500 }}>12:52</td>
                <td>СуШев</td>
                <td>прийняття</td>
                <td>Касир торговельного залу</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 500, marginRight: 2 }}>26.02</td>
                <td style={{ fontWeight: 500 }}>12:51</td>
                <td>СуШев</td>
                <td>прийняття</td>
                <td>Касир торговельного залу</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 500, marginRight: 2 }}>26.02</td>
                <td style={{ fontWeight: 500 }}>12:50</td>
                <td>СуШев</td>
                <td>прийняття</td>
                <td>Касир торговельного залу</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{
            gridColumn: "5/7",
            gridRow: 1,
          }}
          data-tip
          data-for={`experience`}
        >
          <div className={styled.container__widget__image}>
            <img src={Work} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Середній стаж працівників</h4>
            {isLoading ? (
              <span>{getDateExperience(data)}</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Середній стаж */}
        {isLoading && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`experience`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={styled.tooltips__widget}
          >
            <div style={{ marginBottom: 16 }}>
              <p className="label" style={{ marginBottom: 16 }}>
                Середній стаж працівників:{" "}
                <strong>{data.length >= 1 && getDateExperience(data)}</strong>
              </p>
              <ExperienceVerticalChart data={[...data]} />
            </div>
            {all && all.experienceTop.length >= 1 && (
              <div>
                <p className="label" style={{ marginBottom: 8 }}>
                  Топ 5:
                </p>
                <ul>
                  {all.experienceTop.map((el, i) => (
                    <li
                      style={{
                        display: "flex",
                        width: "max-content",
                        gap: 8,
                      }}
                      key={i}
                    >
                      <div>{el.code}</div>
                      <div>
                        {el.surname} {el.name}
                      </div>
                      <div> {el.position.title} </div>
                      <div>{getDateExperience([{ experience: el.days }])} </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </ReactTooltip>
        )}

        <div
          data-tip
          data-for={`age`}
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{
            gridColumn: "3/5",
            gridRow: 2,
          }}
        >
          <div className={styled.container__widget__image}>
            <img src={PeopleAge} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Середній вік працівників</h4>
            {isLoading ? (
              <span>{all && getDateAge(all.birthdayAgeAverage)}</span>
            ) : (
              <Skeleton height={23} width={120} />
            )}
          </div>
        </div>
        {/* tooltip Середній вік */}
        {isLoading && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`age`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={styled.tooltips__widget}
          >
            <ul style={{ marginBottom: 16 }}>
              <li>
                Середній вік працівників:{" "}
                <span>{all && getDateAge(all.birthdayAgeAverage)}</span>
              </li>
            </ul>
            <div className={styled.tooltips__widget__wrapper}>
              <div
                style={{
                  display: "flex",
                  height: 200,
                  width: 200,
                  marginRight: 32,
                  marginBottom: 16,
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={200} height={200}>
                    {all && (
                      <Pie
                        data={all.ageRange}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabelDiagrams}
                        outerRadius={100}
                        minAngle={10}
                        fill="#8884d8"
                        dataKey="percent"
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    )}
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <ul className={styled.tooltips__widget__list}>
                {all &&
                  all.ageRange.map((el, i) => (
                    <li style={{ display: "flex" }} key={i}>
                      <div
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: "100%",
                          background: COLORS[i],
                        }}
                      ></div>
                      <p>{el.name}</p> <span>{el.label}</span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styled.tooltips__widget__maxMin__wrapper}>
              <>
                <div className={styled.tooltips__widget__maxMin}>
                  <p>Найстарші:</p>
                  {all &&
                    all.minMaxRange.max.map((el, i) => {
                      return (
                        <div key={i}>
                          <span className="age">
                            {getDateAge(
                              moment().diff(moment(el.birthday, "DD.MM.YYYY"), "years")
                            )}
                          </span>
                          {el.surname} {el.name} ({el.code ? el.code : "б/к"})
                        </div>
                      );
                    })}
                </div>
                <div className={styled.tooltips__widget__maxMin}>
                  <p>Наймолодші:</p>{" "}
                  {all &&
                    all.minMaxRange.min.map((el, i) => (
                      <div key={i}>
                        <span className="age">
                          {getDateAge(
                            moment().diff(moment(el.birthday, "DD.MM.YYYY"), "years")
                          )}
                        </span>
                        {el.surname} {el.name} ({el.code ? el.code : "б/к"})
                      </div>
                    ))}
                </div>
              </>
            </div>
          </ReactTooltip>
        )}

        <div
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{
            gridColumn: "5",
            gridRow: 2,
            cursor: region.length === 1 ? "initial" : "help",
          }}
          data-tip
          data-for={`store`}
        >
          <div className={styled.container__widget__image}>
            <img src={Store} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Магазинів</h4>
            {isLoading ? (
              <span>{all && all.shopAll}</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Магазинів (з горизонтальними лініями) */}
        {isLoading && region.length !== 1 && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`store`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={styled.tooltips__widget}
          >
            <div style={{ marginBottom: 16 }}>
              <p className="label" style={{ marginBottom: 16 }}>
                Всього магазинів: <strong>{all?.shopAll}</strong>
              </p>
              {/* Горизонтальні смужки так само, як у "Середній стаж" */}
              <StoreVerticalChart data={all?.shopNameAndNumber} />
            </div>
          </ReactTooltip>
        )}

        <div
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{
            gridColumn: "6",
            gridRow: 2,
          }}
        >
          <div className={styled.container__widget__image}>
            <img src={FOP} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>ФОПів</h4>
            {isLoading ? (
              <span>{all && all.fopAll}</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>

        <div
          data-tip
          data-for={`trainee`}
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{
            gridColumn: 4,
            gridRow: 1,
          }}
        >
          <div className={styled.container__widget__image}>
            <img src={Trainee} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Стажерів</h4>
            {isLoading ? (
              <span>{all && all.traineeAll}</span>
            ) : (
              <Skeleton height={23} width={60} />
            )}
          </div>
        </div>
        {/* tooltip Стажерів */}
        {isLoading && (
          <ReactTooltip
            delayShow={500}
            padding={"16px"}
            id={`trainee`}
            effect={"solid"}
            place={"bottom"}
            backgroundColor={"white"}
            className={styled.tooltips__widget}
          >
            <div className={styled.tooltips__widget__row}>
              <p className="label">
                Всього стажерів: <strong>{all && all.traineeAll}</strong>
              </p>
              {all && all.traineePosition.length >= 1 && (
                <div style={{ marginTop: 10 }} className={styled.custom__tooltip__list}>
                  {all.traineePosition.map((el, i) => (
                    <React.Fragment key={i}>
                      <p style={{ marginBottom: 8 }} className="label">
                        {el.position}:{" "}
                        <strong style={{ minWidth: 25, marginLeft: 4 }}>
                          {el.count}
                        </strong>
                      </p>
                      <ul style={{ paddingLeft: 25, marginBottom: 4 }}>
                        {el.employer.map((item, idx) => (
                          <li key={idx}>
                            <p>
                              {item.surname} {item.name} ({item.code})
                            </p>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </ReactTooltip>
        )}

        <div
          className={`${styled.container__widget__item} ${styled.container__widget__box}`}
          style={{
            gridColumn: "5/7",
            gridRow: 3,
          }}
        >
          <div className={styled.container__widget__image}>
            <img src={Paid} alt="" />
          </div>
          <div className={styled.container__widget__image__text}>
            <h4>Розмір фонду ЗП</h4>
            {isLoading ? (
              <span>{all && all.fundSize.toLocaleString()}</span>
            ) : (
              <Skeleton height={23} width={200} />
            )}
          </div>
        </div>
      </div>

      <div className={styled.container__wrapper}>
        <div className={styled.container__table} ref={tabList}>
          <Tabs
            selectedIndex={indexTab}
            onSelect={(index, lastIndex, event) => handleSelect(index, event)}
            className={styled.container__grafic}
          >
            <div className={styled.container__table__top}>
              <TabList className={styled.list}>
                <Tab data-name="workers">Працівники</Tab>
                <Tab data-name="staffing">Укомплектованість</Tab>
                <Tab data-name="fluidity">Плинність</Tab>
              </TabList>
              <div className={styled.wrapper}>
                {indexTab >= 2 && (
                  <div className={`${styled.wrapper} ${styled.container__table__top__wrapper}`}>
                    <span>За період:</span>
                    <div className={styled.select}>
                      <CustomAllSelect
                        placeholder="123"
                        isChange={false}
                        changeFn={handleChange}
                        paddingValue={10}
                        disabled={false}
                        defaultValue={[
                          {
                            label: "30 днів",
                            value: "1",
                          },
                        ]}
                        options={[
                          {
                            label: "30 днів",
                            value: "1",
                          },
                          {
                            label: "3 місяці",
                            value: "3",
                          },
                          {
                            label: "6 місяців",
                            value: "6",
                          },
                          {
                            label: "1 рік",
                            value: "365",
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TabPanel style={{ height: 325 }}>
              {isLoading ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={140}
                    height={50}
                    innerRadius={4}
                    outerRadius={4}
                    data={[...data].sort((a, b) => b.worker - a.worker)}
                    margin={{
                      top: 40,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      position="bottom"
                      tick={true}
                      tickFormatter={() => ""}
                      tickSize={4}
                      fontSize={12}
                      height={30}
                    />
                    <Tooltip content={<CustomTooltipWorkers />} />
                    <Bar dataKey="worker" barSize={24} radius={[4, 4, 0, 0]} fill="#6B9DFF">
                      <LabelList dataKey="difference" content={renderCustomizedLabelTop} />
                      <LabelList dataKey="worker" content={renderCustomizedLabel} />
                      <LabelList dataKey="name" position="bottom" dy={6} fontSize={12} fill="#000" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Skeleton height="100%" />
              )}
            </TabPanel>
            <TabPanel style={{ height: 325 }}>
              {isLoading ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={140}
                    height={50}
                    innerRadius={4}
                    outerRadius={4}
                    data={[...data].sort((a, b) => b.percentStaffing - a.percentStaffing)}
                    margin={{
                      top: 40,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      position="bottom"
                      tick={true}
                      tickFormatter={() => ""}
                      tickSize={4}
                      fontSize={12}
                      height={30}
                    />
                    <Tooltip content={<CustomTooltipStaffing />} />
                    <Bar dataKey="percentStaffing" stackId="a" barSize={24} fill="#6B9DFF">
                      <LabelList dataKey="name" position="bottom" dy={6} fontSize={12} fill="#000" />
                      <LabelList
                        dataKey="percentStaffing"
                        formatter={(value) => (value !== 0 ? `${value}%` : "")}
                        dy={20}
                        fontWeight={600}
                        strokeWidth=".2"
                        stroke={"#FFFFFF"}
                        position={"top"}
                        fontSize={13}
                        fill="#000"
                      />
                    </Bar>
                    <Bar
                      dataKey="percentStaffingExcess"
                      stackId="a"
                      barSize={24}
                      radius={[4, 4, 0, 0]}
                      fill="#FFAAAA"
                    >
                      <LabelList dataKey="percentStaffingExcess" content={renderCustomizedLabel} />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Skeleton height="100%" />
              )}
            </TabPanel>
            <TabPanel style={{ height: 325 }}>
              {isLoading ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={140}
                    height={50}
                    innerRadius={4}
                    outerRadius={4}
                    data={[...data].sort((a, b) => b.full - a.full)}
                    margin={{
                      top: 40,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      position="bottom"
                      tick={true}
                      tickFormatter={() => ""}
                      tickSize={4}
                      fontSize={12}
                      height={30}
                    />
                    <Tooltip content={<CustomTooltip type="positionDismissed" />} />
                    <Bar dataKey="full" barSize={24} radius={[4, 4, 0, 0]} fill="#6B9DFF">
                      <LabelList dataKey="difference" content={renderCustomizedLabelTop} />
                      <LabelList dataKey="procent" content={renderCustomizedLabel} />
                      <LabelList
                        dataKey="name"
                        position="bottom"
                        dy={6}
                        fontSize={12}
                        fill="#000"
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Skeleton height="100%" />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>

      {name === "fluidity" && (
        <div className={styled.container__liner} style={{ height: 215, marginBottom: 24 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={dateArray}
              margin={{
                top: 5,
                right: 5,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                x={0}
                y={0}
                dy={6}
                tickSize={4}
                fontSize={12}
                textAnchor="middle"
                fill="#000000"
              />
              <Tooltip />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default Statistic;
