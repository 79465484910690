import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import {
  addressSearch,
  addressStreet,
} from '../../../../../../../../api/addressSearch';

const EnhancedAddressAutocomplete = ({
  id,
  label = 'Населений пункт',
  placeholder = 'Почніть вводити назву населеного пункту',
  formik,
  disabled = false,
  readOnly = false,
}) => {
  const [inputText, setInputText] = useState('');
  const [placeOptions, setPlaceOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);
  const streetInputRef = useRef();

  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const debouncedAddressSearch = useRef(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        try {
          const results = await addressSearch(query);
          if (isMounted.current) {
            setPlaceOptions(results);
          }
        } catch (error) {
          console.error('Address search error:', error);
        }
      }
    }, 500)
  ).current;

  const debouncedStreetSearch = useRef(
    debounce(async (place, query) => {
      if (place?.id && query.trim().length > 0) {
        try {
          const streetResults = await addressStreet(place, query);
          if (isMounted.current) {
            setStreetOptions(streetResults);
          }
        } catch (error) {
          console.error('Street search error:', error);
        }
      } else if (isMounted.current) {
        setStreetOptions([]);
      }
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedAddressSearch.cancel();
      debouncedStreetSearch.cancel();
    };
  }, [debouncedAddressSearch, debouncedStreetSearch]);

  const expandAbbreviations = (text) => {
    if (!text) return text;
    const replacements = {
      'с.': 'село',
      'м.': 'місто',
      'вул.': 'вулиця',
      'р-н': 'район',
      'обл.': 'область',
    };
    return Object.entries(replacements).reduce(
      (result, [abbr, full]) => result.replace(abbr, full),
      text
    );
  };

  const buildSearchQuery = (values) => {
    const { city_processed, city, region, city_region, oblast } = values || {};
    const parts = new Set([
      expandAbbreviations(city_processed || city),
      expandAbbreviations(oblast),
      expandAbbreviations(city_region || region),
    ]);
    return Array.from(parts).filter(Boolean).join(', ');
  };

  useEffect(() => {
    const field = formik.values[id] || {};
    const newText =
      field.description !== undefined
        ? field.description
        : [
            field.city_processed,
            expandAbbreviations(field.oblast),
            field.city_region || field.region
              ? expandAbbreviations(field.city_region || field.region)
              : null,
          ]
            .filter(Boolean)
            .join(', ');
    setInputText(newText);
  }, [
    formik.values[id]?.description,
    formik.values[id]?.city_processed,
    formik.values[id]?.oblast,
    formik.values[id]?.city_region,
    formik.values[id]?.region,
    id,
  ]);

  const handlePlaceChange = (event, newValue) => {
    if (newValue) {
      const { street, street_processed } = formik.values[id] || {};
      const updatedPlace = {
        ...newValue.place,
        street: street || '',
        street_processed: street_processed || '',
      };
      if (isMounted.current) {
        setInputText(newValue.description);
        formik.setFieldValue(id, updatedPlace);
        setStreetOptions([]);
        streetInputRef.current?.focus();
      }
    } else {
      if (isMounted.current) {
        setInputText('');
        formik.setFieldValue(id, { city: '', street: '' });
        setPlaceOptions([]);
        setStreetOptions([]);
      }
    }
  };

  const handleStreetChange = (event, newValue) => {
    if (newValue) {
      if (isMounted.current) {
        formik.setFieldValue(id, {
          ...formik.values[id],
          street: newValue.description,
          street_processed: newValue.description,
        });
      }
    } else {
      if (isMounted.current) {
        formik.setFieldValue(id, { ...formik.values[id], street: '' });
      }
    }
  };

  const formatBoldPlace = (text) => {
    const words = text.split(' ');
    const firstTwoWords = words.slice(0, 2).join(' ');
    const remainingText = words.slice(2).join(' ');
    return (
      <span>
        <b>{firstTwoWords}</b> {remainingText}
      </span>
    );
  };

  const renderPlaceOption = (props, option) => (
    <Box
      component="li"
      {...props}
      sx={{
        '& > b': { fontWeight: 'bold' },
        padding: '8px 16px',
        fontSize: '14px',
      }}
    >
      {formatBoldPlace(option.description)}
    </Box>
  );

  const renderStreetOption = (props, option) => {
    const words = option.description.split(' ');
    const firstWord = words[0];
    const restWords = words.slice(1).join(' ');
    return (
      <Box
        component="li"
        {...props}
        sx={{
          padding: '8px 16px',
          fontSize: '14px',
        }}
      >
        <span>
          {firstWord} <b>{restWords}</b>
        </span>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Autocomplete
        id={`${id}.city`}
        options={placeOptions}
        disableClearable={!inputText}
        value={inputText}
        getOptionLabel={(option) => option.description || ''}
        isOptionEqualToValue={(option, value) =>
          option.description === value.description
        }
        disabled={disabled}
        readOnly={readOnly}
        inputValue={inputText}
        onInputChange={(event, value, reason) => {
          if (reason === 'input') {
            setInputText(value);
            debouncedAddressSearch(value);
          } else if (reason === 'clear') {
            setInputText('');
            setPlaceOptions([]);
            setStreetOptions([]);
            formik.setFieldValue(id, {});
            formik.setFieldValue('street', '');
          }
        }}
        freeSolo
        onChange={handlePlaceChange}
        renderOption={renderPlaceOption}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            size="small"
            sx={{ minWidth: '300px' }}
            autoComplete="false"
            disabled={disabled}
            error={formik.touched[id]?.city && Boolean(formik.errors[id]?.city)}
            helperText={
              formik.touched[id]?.city &&
              formik.errors[id]?.city && (
                <Typography
                  variant="caption"
                  color="error"
                >
                  {formik.errors[id]?.city}
                </Typography>
              )
            }
          />
        )}
      />

      <Autocomplete
        id={`${id}.street`}
        options={streetOptions}
        value={
          formik.values[id]?.street
            ? { description: formik.values[id]?.street }
            : null
        }
        getOptionLabel={(option) => option.description || ''}
        isOptionEqualToValue={(option, value) =>
          option.description === value.description
        }
        disabled={disabled}
        readOnly={readOnly}
        onInputChange={(event, value) => {
          if (formik.values[id]?.city) {
            debouncedStreetSearch(formik.values[id], value);
          }
        }}
        freeSolo
        onChange={handleStreetChange}
        renderOption={renderStreetOption}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={streetInputRef}
            label="Вулиця"
            placeholder="Знайдіть після вибору населеного пункту"
            size="small"
            sx={{ minWidth: '300px' }}
            autoComplete="false"
            disabled={disabled}
            error={
              formik.touched[id]?.street && Boolean(formik.errors[id]?.street)
            }
            helperText={
              formik.touched[id]?.street &&
              formik.errors[id]?.street && (
                <Typography
                  variant="caption"
                  color="error"
                >
                  {formik.errors[id]?.street}
                </Typography>
              )
            }
          />
        )}
      />
    </Box>
  );
};

export default EnhancedAddressAutocomplete;
