import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';

import closeGrey from '../../../../../assets/closeGrey.svg';
import printer from '../../../../../assets/printer.svg';
import datePicker from '../../../../../assets/datePicker.svg';

import { TextGrey } from '../../../../../styledComponents/Text';
import { SelectForm } from '../../../../../styledComponents/Inputs';
import {
  MButtonGreen,
  SButtonRed,
} from '../../../../../styledComponents/Buttons';

import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import DatePickerFragment from '../../../../../components/datePicker/datePicker';
import ButtonLoading from '../../../../../components/buttons/ButtonLoading';
import KadriPreviewDocument from '../CardsPersonEdit/KadriPreviewDocument';

import {
  getVacations,
  downloadFrees,
} from '../../../../../redux/cadrDocuments/cadrDocuments-thunks';
import {
  downloadDocument,
  getDocuments,
} from '../../../../../redux/workers/workers-thunks';
import { setPreviewFile } from '../../../../../redux/workers/worker-actions';
import {
  addVacationFuture,
  deletedVacationFuture,
} from '../../../../../api/kadriConditions';

import { docTypes } from '../../../../../redux/types';
import getDateAndTime from '../../../../../utilits/getDateAndTime';
import { viewFilesKadri } from '../../../../../api/kadriDoc';

const KadriCardsDocModal = ({ toggleDocModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  // Дані з Redux
  const vacations = useSelector(
    (state) => state.cadrDocumentsReducer.vacations
  );
  const workers = useSelector((state) => state.workers);
  const previewFile = useSelector((state) => state.workersReducer.previewFile);

  // Стан усередині компонента
  const [year, setYear] = useState(new Date().getUTCFullYear());
  const [date, setDate] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [isFuture, setIsFuture] = useState(false);

  // Список видів відпусток (можна винести окремо, якщо потрібно)
  const [vacationList] = useState([
    { value: 'щорічна основна відпустка', label: 'щорічна основна відпустка' },
    {
      value: "додаткова відпустка у зв'язку з навчанням",
      label: "додаткова відпустка у зв'язку з навчанням",
    },
    {
      value: "відпустка у зв'язку з вагітністю та пологами",
      label: "відпустка у зв'язку з вагітністю та пологами",
    },
    {
      value:
        'відпустка для догляду за дитиною до досягнення нею трирічного віку',
      label:
        'відпустка для догляду за дитиною до досягнення нею трирічного віку',
    },
    {
      value: 'відпустка без збереження заробітної плати',
      label: 'відпустка без збереження заробітної плати',
    },
  ]);

  // При зміні року або при монтуванні компонента – завантажуємо відпустки
  useEffect(() => {
    dispatch(getVacations(null, year));
  }, [dispatch, year]);

  // Стили для react-select
  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      paddingLeft: 12,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 140,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided) => ({
      ...provided,
      padding: '2px 12px',
      fontSize: '13px',
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: 2,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      'div:nth-child(1)': {
        display: 'flex',
        alignItems: 'center',
        padding: '0 3px',
        height: 24,
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      height: 24,
      padding: 0,
      input: {
        height: 24,
      },
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 20,
    }),
    container: (provided) => ({
      ...provided,
      position: 'relative',
      width: '100%',
      display: 'inline-block',
    }),
  };

  // Додавання "запланованої" відпустки
  const addVacation = (e) => {
    e.preventDefault();
    setIsFuture(true);
  };

  // Видалення відпустки (без виклику downloadDocument)
  const deleteVacation = async (e, id) => {
    e.preventDefault();
    try {
      const res = await deletedVacationFuture(id);
      alert.success(res.data);
      dispatch(getVacations(null, year));
    } catch (error) {
      console.error('deleteVacation :: error >>>', error);
      alert.error('Не вдалося видалити відпустку');
    }
  };

  // Форма для додавання нової відпустки
  const VacationForm = () => {
    // Фільтруємо лише актуальних працівників (не звільнених, тощо)
    const currentWorker = workers.filter(
      (el) =>
        !moment(el.postInfo.uvalLastDay, 'DD.MM.YYYY').isBefore(new Date()) &&
        el.postInfo?.currentStatus !== 'cancellation' &&
        el.postInfo?.currentStatus !== 'deleted' &&
        el.postInfo.status !== 'Неоформленный'
    );

    const handleSubmit = (data) => {
      addVacationFuture(data)
        .then((res) => {
          dispatch(getVacations(null, year));
          setIsFuture(false);
        })
        .catch((err) => {
          console.error('handleSubmit :: addVacationFuture :: error >>>', err);
          alert.error('Помилка при додаванні відпустки');
        });
    };

    return (
      <Formik
        initialValues={{
          startDate: null,
          id: null,
          finishDate: null,
          posada: '-',
          vacation: '',
          fullName: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, submitForm }) => (
          <tr className="table-row">
            <td>-</td>
            <td>{values.posada}</td>
            <td>
              <Select
                placeholder="Виберіть зі списку"
                onChange={(selectedOption) => {
                  const workerSelected = currentWorker.find(
                    (el) => el._id === selectedOption.value
                  );
                  if (workerSelected) {
                    setFieldValue('id', workerSelected._id);
                    setFieldValue('posada', workerSelected.postInfo.status);
                    setFieldValue('fullName', selectedOption.label);
                  }
                }}
                components={{ IndicatorSeparator: false }}
                styles={customStyles}
                options={currentWorker.map((el) => ({
                  value: el._id,
                  label: `${el.surname} ${el.name} ${el.patronymic}`,
                }))}
              />
            </td>
            <td style={{ width: 200 }}>
              <Select
                onChange={(val) => setFieldValue('vacation', val.label)}
                components={{ IndicatorSeparator: false }}
                styles={customStyles}
                placeholder="Виберіть зі списку"
                options={vacationList}
              />
            </td>
            <td
              className="table__icon"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
              }}
            >
              <div
                className="vacation__date"
                style={{ maxWidth: 85, width: 100 }}
              >
                <DatePickerFragment
                  dateFormat="dd.MM.yyyy"
                  selected={values.startDate}
                  onChange={(newDate) =>
                    setFieldValue('startDate', new Date(newDate).setHours(12))
                  }
                />
              </div>
              <span style={{ marginLeft: 10, marginRight: -6 }}>-</span>
              <div
                className="vacation__date"
                style={{ maxWidth: 85 }}
              >
                <DatePickerFragment
                  dateFormat="dd.MM.yyyy"
                  selected={values.finishDate}
                  onChange={(newDate) =>
                    setFieldValue('finishDate', new Date(newDate).setHours(12))
                  }
                />
              </div>
            </td>
            <td className="table__icon">-</td>
            <td
              className="table__icon"
              style={{ color: '#2F80ED' }}
            >
              <MButtonGreen
                onClick={submitForm}
                type="submit"
                style={{
                  padding: '6px 8px',
                  height: 'inherit',
                  width: 'inherit',
                }}
              >
                Зберегти
              </MButtonGreen>
            </td>
          </tr>
        )}
      </Formik>
    );
  };

  return (
    <>
      <div className="doc__modal__container">
        <div className="doc__modal__header">
          <div className="modal__header__title">
            <div />
            <TextGrey style={{ textAlign: 'center', marginBottom: '16px' }}>
              Графік відпусток працівників
            </TextGrey>
            <button
              onClick={toggleDocModal}
              className="modal__close"
            >
              <img
                src={closeGrey}
                alt="close"
              />
            </button>
          </div>
          <div className="modal__header__select">
            <SelectForm
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
                dispatch(getVacations(null, e.target.value));
              }}
            >
              <option value={moment().subtract(1, 'years').year()}>
                {moment().subtract(1, 'years').year()} рік
              </option>
              <option value={moment().year()}>{moment().year()} рік</option>
              <option value={moment().add(1, 'years').year()}>
                {moment().add(1, 'years').year()} рік
              </option>
              <option value={moment().add(2, 'years').year()}>
                {moment().add(2, 'years').year()} рік
              </option>
            </SelectForm>
          </div>
        </div>

        <button
          className="buttonVacation"
          onClick={addVacation}
        >
          +
        </button>

        <div className="doc__modal__table">
          <table
            className="table"
            style={{ fontSize: '13px' }}
          >
            <thead>
              <tr className="table-row">
                <th>№</th>
                <th>Посада</th>
                <th>ПІБ</th>
                <th>Вид відпустки</th>
                <th className="table__icon">Період відпустки</th>
                <th>Тривалість відпустки</th>
                <th style={{ textAlign: 'center' }}>
                  Наказ <br /> (розпорядження)
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Рядок форми для додавання відпустки */}
              {isFuture && <VacationForm />}

              {/* Блок із відпустками, де nomer !== 0 (вже оформлені) */}
              {vacations
                .filter((item) => item.info.nomer !== 0)
                .map((vacation, index) => {
                  const currentWorker = workers.find(
                    (w) => w._id === vacation.indexId
                  );
                  if (!currentWorker) return null;

                  const { exp } = vacation.info;
                  const timeFree = exp?.timeFree || '';
                  const lastNumb = String(timeFree).slice(-1);

                  return (
                    <tr
                      className="table-row"
                      key={vacation._id}
                    >
                      <td>{index + 1}</td>
                      <td>{currentWorker.postInfo.status}</td>
                      <td>
                        {currentWorker.surname} {currentWorker.name}{' '}
                        {currentWorker.patronymic}
                      </td>
                      <td>{vacation.info.typeText}</td>
                      <td className="table__icon">
                        {exp?.start} - {exp?.end}
                      </td>
                      <td className="table__icon">
                        {timeFree + 1}{' '}
                        {lastNumb === '1'
                          ? 'день'
                          : ['2', '3', '4'].includes(lastNumb)
                          ? 'дні'
                          : 'днів'}
                      </td>
                      <td
                        className="table__icon"
                        style={{ color: '#2F80ED', cursor: 'pointer' }}
                        onClick={async () => {
                          const resDoc = await viewFilesKadri(
                            vacation._id,
                            vacation.path,
                            vacation.filename
                          );

                          window.open(resDoc.generateUrl, '_blank');
                        }}
                      >
                        {vacation.info.nomer === 0
                          ? '-'
                          : `№ ${vacation.info.nomer} від ${getDateAndTime(
                              new Date(vacation.createdAt),
                              true
                            )}`}
                      </td>
                    </tr>
                  );
                })}

              {/* Блок "Заплановані відпустки", де nomer === 0 */}
              <tr style={{ background: '#dddddd', borderTop: '1px #ededed' }}>
                <td
                  colSpan={8}
                  style={{
                    textAlign: 'center',
                    color: 'black',
                    padding: '4px 0',
                  }}
                >
                  Заплановані відпустки
                </td>
              </tr>
              {vacations
                .filter((item) => item.info.nomer === 0)
                .map((vacation) => {
                  const currentWorker = workers.find(
                    (w) => w._id === vacation.indexId
                  );
                  if (!currentWorker) return null;

                  const { exp } = vacation.info;
                  const timeFree = exp?.timeFree || '';
                  const lastNumb = String(timeFree).slice(-1);

                  return (
                    <tr
                      className="table-row"
                      key={vacation._id}
                    >
                      <td>-</td>
                      <td>{currentWorker.postInfo.status}</td>
                      <td>
                        {currentWorker.surname} {currentWorker.name}{' '}
                        {currentWorker.patronymic}
                      </td>
                      <td>{vacation.info.typeText}</td>
                      <td className="table__icon">
                        {exp?.start} - {exp?.end}
                      </td>
                      <td className="table__icon">
                        {timeFree + 1}{' '}
                        {lastNumb === '1'
                          ? 'день'
                          : ['2', '3', '4'].includes(lastNumb)
                          ? 'дні'
                          : 'днів'}
                      </td>
                      <td
                        className="table__icon"
                        style={{ color: '#2F80ED' }}
                      >
                        {vacation.info.nomer === 0 ? (
                          <SButtonRed
                            onClick={(e) => deleteVacation(e, vacation._id)}
                            style={{ padding: '4px 6px', height: 'inherit' }}
                          >
                            Видалити
                          </SButtonRed>
                        ) : (
                          `№ ${vacation.info.nomer} від ${getDateAndTime(
                            new Date(vacation.createdAt),
                            true
                          )}`
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {/* Кнопки внизу */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="doc__modal__footer__btn"
            style={{ padding: 0 }}
          >
            <div
              className="conditions__calendar"
              style={{ marginRight: 8 }}
            >
              <DatePickerFragment
                selected={date}
                dateFormat="dd.MM.yyyy"
                placeholderText="Введіть дату"
                IconGray={
                  <div
                    className="item__input__check"
                    type="button"
                    style={{ paddingTop: '8px', marginBottom: '2px' }}
                  >
                    <span>
                      <img
                        src={datePicker}
                        alt="datePicker"
                      />
                    </span>
                  </div>
                }
                onChange={(newDate) => setDate(newDate.setHours(12))}
              />
            </div>
            <div
              data-tip
              data-for="vacationButton"
            >
              <ButtonLoading
                color="black"
                scale={0.4}
                isActive={isLoader}
              >
                <button
                  onClick={() => dispatch(downloadFrees(setLoader, year, date))}
                  disabled={date === null}
                >
                  <img
                    src={printer}
                    alt="printer"
                    style={{ filter: date == null ? 'invert(70%)' : 'inherit' }}
                  />
                  <p>
                    Відкрити у <br /> PDF форматі
                  </p>
                </button>
              </ButtonLoading>
            </div>
            {date == null && (
              <ReactTooltip
                id="vacationButton"
                effect="solid"
                backgroundColor="#52616E"
              >
                Виберіть дату документа
              </ReactTooltip>
            )}
          </div>
        </div>
      </div>

      {/* Попередній перегляд документа (якщо тип у docTypes) */}
      {docTypes.indexOf(previewFile?.fileType) !== -1 && (
        <KadriPreviewDocument
          file={previewFile}
          closeModal={() => dispatch(setPreviewFile(null))}
        />
      )}
    </>
  );
};

export { KadriCardsDocModal };
