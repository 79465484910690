import React from 'react';
import { InputForm } from '../../../../../../styledComponents/Inputs';

import PlacesAutocomplete from '../../../../../../components/googlePlaceInput/googlePlaceInput';

const AddressData = ({
  street,
  setFieldValue,
  house,
  place,
  apartments,
  handleChange,
}) => {
  const handleChangeSelect = (value) => {
    setFieldValue('city', value);
    setFieldValue('street', value);
  };

  return (
    <div
      className="table__row"
      style={{ marginBottom: '10px' }}
    >
      <div className="table__place">
        <span>
          Адреса місця реєстрації <br /> або проживання
        </span>
      </div>
      <div className="table__value">
        <div className="table__value__row">
          <div
            style={{ width: '608px', marginRight: 6 }}
            className="table__value__item"
          >
            <PlacesAutocomplete
              name="street"
              place={place}
              handleChangeSelect={handleChangeSelect}
              setFieldValue={setFieldValue}
              placeholder="Введіть назву вулиці"
              valueAddress={street}
            />
          </div>
          <div
            className="table__value__item"
            style={{ marginRight: 6 }}
          >
            <span>Будинок</span>
            <InputForm
              id="house"
              name="house"
              type="text"
              onChange={handleChange}
              value={house}
              style={{ width: '50px', padding: '0 4px', textAlign: 'center' }}
            />
          </div>
          <div className="table__value__item">
            <span>Квартира</span>
            <InputForm
              id="apartments"
              name="apartments"
              type="text"
              onChange={handleChange}
              value={apartments}
              style={{ width: '50px', padding: '0 4px', textAlign: 'center' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddressData);
