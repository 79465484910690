const addressPlace = (
  { oblast, city, region, region_up, street, street_number, postal_code },
  house,
  apartments = undefined
) => {
  return [
    postal_code && `${postal_code}`,
    oblast,
    city && city,
    region === '' ? region_up : region,
    street,
    (house || street_number) && `${'будинок ' + (house ?? street_number)}`,
    apartments && `${'квартира ' + apartments}`
  ].filter(el => !!el);
};
export default addressPlace;
