import React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { resourceOptions, methodInviteOptionsMap } from '../lib/constants';

const CombinedSelect = ({
  label,
  formik,
  disabled = false,
  readOnly = false,
}) => {
  const { values, setFieldValue, errors, touched } = formik;

  const handleSelectionChange = (event, newValue) => {
    const selectedResource = newValue.find((item) =>
      resourceOptions.some((resource) => resource.value === item.value)
    );

    if (
      selectedResource &&
      (!values.resours || selectedResource.value !== values.resours)
    ) {
      setFieldValue('resours', selectedResource.value);
      setFieldValue('methodInvite', '');
    }

    if (!selectedResource) {
      setFieldValue('resours', '');
      setFieldValue('methodInvite', '');
    }

    const selectedMethods = newValue.filter((item) =>
      methodInviteOptionsMap[values.resours]?.some(
        (method) => method.value === item.value
      )
    );

    setFieldValue(
      'methodInvite',
      selectedMethods.length > 0 ? selectedMethods[0].value : ''
    );
  };

  const selectedResource = resourceOptions.find(
    (option) => option.value === values.resours
  );

  const availableMethods = selectedResource
    ? methodInviteOptionsMap[selectedResource.value] || []
    : [];

  const selectedItems = [
    selectedResource
      ? { label: selectedResource.label, value: selectedResource.value }
      : null,
    ...availableMethods.filter(
      (method) => values.methodInvite === method.value
    ),
  ].filter(Boolean);

  const isMaxSelected = selectedResource && values.methodInvite;

  const options = selectedResource ? availableMethods : resourceOptions;

  const getErrorMessage = () => {
    if (touched.resours && !values.resours) return 'Оберіть джерело інформації';
    if (touched.methodInvite && !values.methodInvite)
      return 'Метод є обов’язковим';
    return '';
  };

  const isError = Boolean(
    (touched.resours && errors.resours) ||
      (touched.methodInvite && errors.methodInvite)
  );

  return (
    <Stack sx={{ width: 1 }}>
      <Autocomplete
        multiple
        disabled={disabled}
        readOnly={readOnly}
        disableCloseOnSelect
        options={!isMaxSelected ? options : []}
        getOptionLabel={(option) => option.label}
        value={selectedItems}
        onChange={handleSelectionChange}
        filterSelectedOptions
        popupIcon={!isMaxSelected ? undefined : null}
        ListboxProps={{
          style: {
            fontSize: '14px',
          },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              disabled={disabled}
              key={index}
              label={option.label}
              {...getTagProps({ index })}
              size="small"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '110px',
                height: '20px',
                display: 'flex',
                justifyContent: 'space-around',
                fontSize: '12px',
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={
              isMaxSelected
                ? ''
                : !selectedResource
                ? 'Оберіть ресурс'
                : 'Оберіть метод'
            }
            size="small"
            InputProps={{
              ...params.InputProps,
              readOnly: isMaxSelected,
            }}
            autoComplete="false"
            error={isError || getErrorMessage()}
            helperText={getErrorMessage()}
            disabled={disabled}
            sx={{
              '.MuiInputBase-root': {
                display: 'inline-flex',
                flexWrap: 'nowrap',
                maxHeight: '36px',
              },
            }}
          />
        )}
      />
    </Stack>
  );
};

export default CombinedSelect;
