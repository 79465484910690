import {
  SET_ALL_DOCUMENTS,
  SET_POSITIONS,
  SET_VACATIONS,
  SET_WORK_DAYS,
  SET_ZP_INFO,
} from './cadrDocuments-types';

const initialState = {
  positions: [],
  workDays: [],
  vacations: [],
  allDocuments: [],
  zpInfo: [],
};

export const cadrDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITIONS:
      return { ...state, positions: action.positions };
    case SET_WORK_DAYS:
      return { ...state, workDays: action.workDays };
    case SET_VACATIONS:
      return { ...state, vacations: action.vacations };
    case SET_ALL_DOCUMENTS:
      return { ...state, allDocuments: action.allDocuments };
    case SET_ZP_INFO:
      return { ...state, zpInfo: action.zpInfo };
    default:
      return state;
  }
};
