import React, { useState } from 'react';

import close from '../../../../../assets/close.svg';

import '../KadriCards.css';
import { KadriPageSpecialQuota } from './KadriPageSpecialQuota';
import { KadriPageDocument } from './KadriPageDocument';
import { KadriPageHistory } from './KadriPageHistory';
import CadrePagePersonal from './DRAFT/PersonalData/organisms/CadrePagePersonal';
import { KadriPagePersonal__2 } from './KadriPagePersonal__2';

export const KadriCardsPersonEdit__2 = ({
  items,
  togglePersonEdit__2,
  id,
  currentEmployer,
}) => {
  const [active, setActive] = React.useState(0);
  const openTab = (e) => setActive(+e.target.dataset.index);
  const [isPassport, setIsPassport] = useState(false);
  return (
    <div
      style={{ height: 'auto' }}
      className={
        active || isPassport ? 'tab__modal' : 'tab__modal modalPassport'
      }
    >
      <div className="tab">
        <div className="tab__btn">
          <button className={`tablinks active`}>Персональні дані</button>
        </div>
        <div
          className="tab__close"
          onClick={togglePersonEdit__2}
        >
          <img src={close} />
        </div>
      </div>
      <KadriPagePersonal__2
        currentEmployer={currentEmployer}
        workerId={id}
        setIsPassport={setIsPassport}
        togglePersonEdit__2={togglePersonEdit__2}
      />
    </div>
  );
};
