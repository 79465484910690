import moment from 'moment';
import plural from 'ru-plurals';

const parseDate = (dateValue) => {
  if (!dateValue) return null;

  let m = moment(dateValue, moment.ISO_8601, true);
  if (!m.isValid()) {
    m = moment(dateValue, 'DD.MM.YYYY', true);
  }
  if (!m.isValid() && /^\d+$/.test(dateValue)) {
    m = moment(Number(dateValue));
  }
  return m.isValid() ? m : null;
};

const extractStartDate = (doc) => {
  if (!doc?.postInfo) return null;
  const candidates = [
    doc.postInfo.dateAccept,
    doc.postInfo.more?.startWorkDay,
    doc.postInfo.workForm,
  ];
  for (let c of candidates) {
    const parsed = parseDate(c);
    if (parsed) return parsed;
  }
  return null;
};

const extractEndDate = (doc) => {
  if (!doc?.postInfo) return null;
  return parseDate(doc.postInfo.uvalLastDay);
};

const isActive = (doc) => {
  if (!doc?.postInfo) return false;
  if (doc.postInfo.currentStatus === 'work') return true;

  if (!doc.postInfo.uvalLastDay) return true;
  return false;
};

const formatDuration = (start, end) => {
  const duration = moment.duration(end.diff(start));
  if (duration.asMilliseconds() < 0) return null;

  const year = plural('рік', 'роки', 'років');
  const month = plural('місяць', 'місяці', 'місяців');
  const day = plural('день', 'дні', 'днів');

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  const parts = [];
  if (years) parts.push(year(years));
  if (months) parts.push(month(months));
  parts.push(day(days));

  return parts.join(' ').trim();
};

const mergeIntervals = (intervals) => {
  if (!intervals.length) return [];

  intervals.sort((a, b) => a.start.valueOf() - b.start.valueOf());

  const merged = [];
  let current = intervals[0];

  for (let i = 1; i < intervals.length; i++) {
    const next = intervals[i];

    if (
      current.status === next.status &&
      next.start.valueOf() <= current.end.valueOf()
    ) {
      if (next.end.isAfter(current.end)) {
        current.end = next.end;
      }
    } else if (
      current.status === next.status &&
      next.start.diff(current.end, 'days') === 0
    ) {
      current.end = next.end.isAfter(current.end) ? next.end : current.end;
    } else {
      merged.push(current);
      current = next;
    }
  }
  merged.push(current);

  return merged;
};

const getExperienceHelper = (docs) => {
  if (!Array.isArray(docs) || !docs.length) {
    return { all_exp: null, posada_exp: null };
  }

  const { surname, name, birthday } = docs[0];
  const samePersonDocs = docs.filter(
    (d) => d.surname === surname && d.name === name && d.birthday === birthday
  );

  if (!samePersonDocs.length) {
    return { all_exp: null, posada_exp: null };
  }

  const intervals = [];
  let anyActive = false;

  samePersonDocs.forEach((doc) => {
    const start = extractStartDate(doc);
    if (!start) return;

    let end = extractEndDate(doc);
    const status = doc.postInfo?.status || '';
    const active = isActive(doc);

    if (active) {
      anyActive = true;
      end = moment();
    } else if (!end || !end.isValid()) {
      end = moment(start);
    }

    intervals.push({ start, end, status, active });
  });

  if (!intervals.length) {
    return { all_exp: null, posada_exp: null };
  }

  const earliestStart = intervals.reduce(
    (min, cur) => (cur.start.isBefore(min) ? cur.start : min),
    intervals[0].start
  );

  let overallEnd;
  if (anyActive) {
    overallEnd = moment();
  } else {
    overallEnd = intervals.reduce(
      (max, cur) => (cur.end.isAfter(max) ? cur.end : max),
      intervals[0].end
    );
  }
  const all_exp = formatDuration(earliestStart, overallEnd);

  let currentPosition = '';
  if (anyActive) {
    const activeIntervals = intervals.filter((iv) => iv.active);
    if (activeIntervals.length) {
      const latestActive = activeIntervals.reduce(
        (max, cur) => (cur.start.isAfter(max.start) ? cur : max),
        activeIntervals[0]
      );
      currentPosition = latestActive.status;
    }
  } else {
    const latestDoc = intervals.reduce(
      (max, cur) => (cur.end.isAfter(max.end) ? cur : max),
      intervals[0]
    );
    currentPosition = latestDoc.status;
  }

  const positionIntervals = intervals.filter(
    (iv) => iv.status === currentPosition
  );
  if (!positionIntervals.length) {
    return { all_exp, posada_exp: null };
  }

  const merged = mergeIntervals(positionIntervals);

  const earliestPosadaStart = merged.reduce(
    (min, cur) => (cur.start.isBefore(min) ? cur.start : min),
    merged[0].start
  );
  const latestPosadaEnd = merged.reduce(
    (max, cur) => (cur.end.isAfter(max) ? cur.end : max),
    merged[0].end
  );

  const posada_exp = formatDuration(earliestPosadaStart, latestPosadaEnd);

  return { all_exp, posada_exp };
};

const getExperienceFromWorkerDocs = (docs) => {
  if (!Array.isArray(docs) || docs.length === 0) {
    return [];
  }

  const grouped = docs.reduce((acc, doc) => {
    const { surname, name, birthday } = doc;
    const key = `${surname}|${name}|${birthday}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(doc);
    return acc;
  }, {});

  const results = Object.keys(grouped).map((key) => {
    const groupDocs = grouped[key];
    const { all_exp, posada_exp } = getExperienceHelper(groupDocs);

    const { surname, name, birthday } = groupDocs[0];
    return {
      surname,
      name,
      birthday,
      all_exp,
      posada_exp,
    };
  });

  return results;
};

export { getExperienceFromWorkerDocs };
